import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import { customTransformResponseEmail } from "../utils/customTransformResponseEmail";
import qs from "qs";

export const mailboxApi = createApi({
  reducerPath: "mailboxApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Mailbox"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getMailbox: build.query({
      query: (q) =>
        `api/mailbox/messages?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Mailbox"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    messageRead: build.mutation({
      query: (hash) => ({
        url: `api/mailbox/messages/${hash}/read`,
        method: "POST",
      }),
      invalidatesTags: ["Mailbox"],
    }),
    markAsRead: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/markasread/batch`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),
    markAsUnread: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/markasunread/batch`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),
    deleteMessage: build.mutation({
      query: (body) => ({
        url: `/api/mailbox/messages/delete/batch`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),
    lockMessage: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/lock/batch`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),
    unlockMessage: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/unlock/batch`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),
    archiveMessage: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/archivewithoutanswer/batch`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),
    composeMessage: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/compose`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Mailbox"],
    }),

    editMessage: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/mailbox/messages/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Mailbox", id }],
    }),

    getMailboxFields: build.query({
      query: (id) => `api/mailbox/fields`,
      providesTags: (id) => [{ type: "Mailbox", id }],
      lazy: true,
    }),
    downloadMessage: build.mutation({
      query: (messageId) => ({
        url: `api/mailbox/messages/download?messageId=${messageId}`,
        method: "POST",
        responseType: "blob",
      }),
      responseHandler: "blob",
    }),

    //FOLDERS
    //GET FOLDERS
    getFolderMailbox: build.query({
      query: (q) => `/api/mailbox/folders?limit=`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),

    //ADD FOLDER
    addFolderMailbox: build.mutation({
      query: (body) => ({
        url: `api/mailbox/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderMailbox: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/mailbox/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Mailbox", id }],
    }),
    //DELETE FOLDER
    deleteFolderMailbox: build.mutation({
      query: (id) => ({
        url: `api/mailbox/folders/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Orders", id }],
    }),
    moveToFolder: build.mutation({
      query: (body) => ({
        url: `api/mailbox/messages/movetofolder/batch`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetMailboxQuery,
  useMessageReadMutation,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
  useDeleteMessageMutation,
  useLockMessageMutation,
  useUnlockMessageMutation,
  useArchiveMessageMutation,
  useMoveToFolderMutation,
  useComposeMessageMutation,
  useEditMessageMutation,
  useGetMailboxFieldsQuery,
  useDownloadMessageMutation,

  // //FOLDERS
  useGetFolderMailboxQuery,
  useAddFolderMailboxMutation,
  useUpdateFolderMailboxMutation,
  useDeleteFolderMailboxMutation,
} = mailboxApi;
