import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { TbSend, TbPlayerPlayFilled } from "react-icons/tb";
import { FaBan, FaTrashAlt } from "react-icons/fa";
import { IoRemoveCircle } from "react-icons/io5";
import { IoAddCircle } from "react-icons/io5";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { BiLinkExternal } from "react-icons/bi";
import { useDownloadLeadLetterMutation, useGetSerialLettersOptionsQuery } from "../../../redux/api/serialLettersApi";
import { useRunnerCommandMutation } from "../../../redux/api/contactsApi";
import { FaList } from "react-icons/fa6";

export const ActionButtons = ({
  edit = true,
  clone = true,
  _delete = true,
  preview = false,
  previewForm = false,
  link,
  deleteItem,
  refetch,
  openModal,
  sendEmail = false,
  clickOnEmailFunction,
  setDoNotContact = false,
  onClickIDFunction,
  showRun = false,
  rebuildComponent,
  removeItemFromFolder,
  folderId,
  showRemove = false,
  name,
  setAlertMessage,
  setShowAlert,
  showRemoveCompany = false,
  removeItem,
  replaceCompanies,
  companiesFields,
  setShow,
  setMessage,
  showSendSegmentEmail = false,
  sendSegmentEmail,
  stopSegmentEmail,
  setBackgroundToast,
  addContactSegment = false,
  removeContactSegment = false,
  addSegment,
  removeSegment,
  serialLetters = false,
  runCommand = false,
  command = "",
  removePurpose,
  showRemovePurpose = false,
  createBrochureOrder = false,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data, isLoading } = useGetSerialLettersOptionsQuery();

  const handleDelete = async (data) => {
    const name =
      link === "tags"
        ? data?.tag
        : link === "companies"
        ? data?.fields?.core?.companyname?.value
        : data?.fields?.all?.firstname ||
          data?.name ||
          data?.title ||
          data?.label ||
          data?.username ||
          data?.text ||
          data?.originalFile ||
          data?.fileOriginalName;

    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: name })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    });

    if (result.isConfirmed) {
      let response;
      if (link === "contacts/fields") {
        response = await deleteItem({ object: "contacts", objectId: data.id });
      } else {
        response = await deleteItem(data.id);
        if (response.error && link == "segments") {
          setShowAlert(true);
          setAlertMessage(response?.error.data.error[0]?.message);
          setTimeout(() => {
            setShowAlert(false);
            setAlertMessage("");
          }, 5000);
        }
      }

      refetch();
    }
  };
  const handleRebuild = async (data) => {
    try {
      const response = await rebuildComponent(
        link === "campaigns"
          ? data?.id
          : {
              id: data.id,
            }
      ).unwrap();
      setAlertMessage(t("caymland.core.rebuild"));
      setShowAlert(true);
      setTimeout(() => {
        refetch();
      }, 1000);
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 7000);
    } catch (error) {
      console.error("Update error:", error);
    }
  };
  const handleRemoveItem = async (data) => {
    try {
      await removeItemFromFolder({
        id: folderId,
        itemId: data.id,
      }).unwrap();

      refetch();
    } catch (error) {
      console.error("An error occurred while moving the campaign:", error);
    }
  };
  const handleRemove = async (id) => {
    try {
      const response = await removeItem({
        contactId: link === "viewCompanies" ? id : folderId,
        companyId: link === "viewCompanies" ? folderId : id,
      }).unwrap();
      if (!response.error) {
        const updatedCompaniesFields = companiesFields.filter((company) => company.id !== id);
        replaceCompanies(updatedCompaniesFields);

        setShow(true);
        setMessage(t("caymland.core.removed"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      console.error("An error occurred while moving the campaign:", error);
    }
  };

  const handleRemovePurpose = async (id) => {
    console.log(id);
    try {
      const response = await removePurpose({
        purposeId: id,
        contactId: folderId,
      }).unwrap();

      refetch();
    } catch (error) {
      console.error("An error occurred while moving the campaign:", error);
    }
  };

  const handleBrochure = async (id) => {
    const brochurePath = `/orders/new?id=${id}`;
    window.open(`${window.location.origin}${brochurePath}`, "_blank");
  };

  const handleContactToSegment = async (data, param) => {
    if (param == "add") {
      try {
        const response = addSegment({ segmentsId: folderId, contactId: data });
        if (!response.error) {
          setTimeout(() => {
            refetch();
          }, 2000);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const response = removeSegment({ segmentsId: folderId, contactId: data });
        if (!response.error) {
          setTimeout(() => {
            refetch();
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [runCronCommand] = useRunnerCommandMutation();

  const handleCronCommand = async (id) => {
    const commandBody = {
      command: command,
      arguments:
        link === "campaigns" ? { "campaign-id": id?.id } : link === "segments" ? { "list-id": id?.id } : { id: id }, // Dynamically set arguments based on link value
    };
    const res = await runCronCommand(commandBody);
    if (!res.errors) {
      refetch();
    }
  };

  const [downloadLeadLetter] = useDownloadLeadLetterMutation();

  const handleDownlaodLetter = async (data, item) => {
    try {
      const response = await downloadLeadLetter({ objectId: item.value, leadId: data }).unwrap();
      const blob = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;
      link.download = `${item.label}-${data}`; // You can set the desired filename here
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Clean up by removing the link
      URL.revokeObjectURL(url); // Free up memory
    } catch (error) {
      console.error("Error downloading lead letter:", error);
    }
  };

  // Create an array of dynamic buttons from the data
  const dynamicButtons =
    !isLoading && data
      ? data.map((item) => ({
          name: item.label,
          icon: <BiLinkExternal />, // Replace with your desired icon
          show: serialLetters, // Assuming you want to show all dynamic buttons
          onClick: (data) => handleDownlaodLetter(data, item),
        }))
      : [];

  const actionButtons = [
    {
      name: t("caymland.core.permissions.edit"),
      icon: <EditIcon size={15} />,
      show: edit,
      onClick: (id) => {
        link === "categories"
          ? openModal("Edit", id)
          : link === "tags" || link === "notes" || link == "purposes"
          ? openModal({ isOpen: true, type: "edit", id: id })
          : navigate(`/${link}/edit/${id}`, { state: id });
      },
    },
    {
      name: t("caymland.core.form.clone"),
      icon: <FileCopyIcon size={15} />,
      show: clone,
      onClick: (id) => navigate(`/${link}/clone`, { state: { id } }),
    },
    {
      name: t("caymland.asset.asset.preview"),
      icon: <VisibilityIcon />,
      show: preview,
      onClick: async (id) => openModal(id),
    },
    {
      name: t("caymland.core.preview"),
      icon: <VisibilityIcon />,
      show: previewForm,
      onClick: async (id) => {
        const url = `/form/preview/${id}`;
        window.open(url, "_blank");
      },
    },

    {
      name: t("caymland.mailbox.message.send"),
      icon: <TbSend color="#f86b4f" size={17} />,
      show: sendEmail,
      onClick: async (data) => {
        clickOnEmailFunction(data);
      },
    },
    {
      name: t("caymland.email.send"),
      icon: <TbSend color="#f86b4f" size={17} />,
      show: showSendSegmentEmail,
      onClick: async (id) => {
        sendSegmentEmail(id);
      },
    },
    {
      name: t("caymland.email.stat.stop"),
      icon: <FaBan size={15} color="#f86b4f" />,
      show: showSendSegmentEmail,
      onClick: async (id) => {
        stopSegmentEmail(id);
      },
    },
    // {
    //   name: t("caymland.email.send"),
    //   icon: <TbSend size={15} color="#f86b4f" />,
    //   show: showSendSegmentEmail,
    //   onClick: async (id) => {
    //     sendSegmentEmail(id);
    //   },
    // },
    {
      name: t("caymland.lead.batch.dnc"),
      icon: <FaBan size={15} color="#f86b4f" />,
      show: setDoNotContact,
      onClick: async (id, doNotContact) => {
        onClickIDFunction(id, doNotContact);
      },
    },
    {
      name: t("plugin.dncevent.campaign.removeDnc.label"),
      icon: <FaBan size={15} color="#f86b4f" />,
      show: setDoNotContact,
      onClick: async (id, doNotContact) => {
        onClickIDFunction(id, doNotContact);
      },
    },
    {
      name: t("caymland.brochures.create.order"),
      icon: <FaList size={15} />,
      show: createBrochureOrder,
      onClick: handleBrochure,
    },
    {
      name: t("caymland.cron.active"),
      show: showRun,
      icon: <TbPlayerPlayFilled />,
      onClick: handleRebuild,
    },
    {
      name: t("caymland.core.remove"),
      show: showRemove,
      icon: <IoRemoveCircle />,
      onClick: handleRemoveItem,
    },
    {
      name: t("caymland.core.remove"),
      show: showRemovePurpose,
      icon: <IoRemoveCircle />,
      onClick: handleRemovePurpose,
    },

    {
      name: t("caymland.core.remove"),
      show: removeContactSegment,
      icon: <IoRemoveCircle />,
      onClick: (data) => handleContactToSegment(data, "remove"),
    },
    {
      name: t("caymland.campaign.form.addtocampaigns") + " " + t("caymland.badge.plugin.list"),
      show: addContactSegment,
      icon: <IoAddCircle />,
      onClick: (data) => handleContactToSegment(data, "add"),
    },
    {
      name: t("caymland.core.remove"),
      show: showRemoveCompany,
      icon: <IoRemoveCircle />,
      onClick: handleRemove,
    },
    {
      name: t("caymland.cron.active"),
      show: runCommand,
      icon: <TbPlayerPlayFilled />,
      onClick: handleCronCommand,
    },
    {
      name: t("caymland.mailbox.list.action.deleteMessage"),
      icon: <FaTrashAlt size={16} />,
      show: _delete,
      onClick: async (id) => {
        handleDelete(id);
      },
    },
    ...dynamicButtons,
  ];

  return actionButtons.filter((button) => button.show);
};
