import React, { useEffect, useState } from "react";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import { Checkbox, DatePicker, Label, Select, Text, TextArea } from "../../../reusableComponents/Inputs";
import Dropzone from "react-dropzone-uploader";
import { retrieveCategories } from "../../../reusableComponents/retriveCategoriesByBundle";
import { languages } from "../../../reusableComponents/data";
import Button from "../../../reusableComponents/Buttons/Button";
import { useTranslation } from "react-i18next";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../redux/api/categoriesApi";
import ModalGenerator from "../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../reusableComponents/ModalGenerator/ModalGeneratorData";

const Sidebar = ({
  isFetching,
  modifiedData,
  setModifiedData,
  handleSelectChange,
  handleFormChange,
  handleToggleChange,
  assetOptions,
  ClonePath,
  isNew,
  getUploadParams,
  handleChangeStatus,
  modalOptions,
  setModalOptions,
  setNewItem,
  query,
  setQuery,
  errors,
  handleInputChange,
}) => {
  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const { t } = useTranslation();

  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />

      <Label
        name="type"
        required={true}
        label={t("caymland.asset.asset.form.storageLocation")}
        // tooltip=""
      />

      <div className="flex items-center justify-center w-full" style={{ marginBottom: "25px" }}>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "local",
                title: t("caymland.asset.asset.form.storageLocation.local"),
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    storageLocation: "local",
                  });
                },
              },
            ]}
            variant={modifiedData?.storageLocation === "local" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "remote",
                title: t("caymland.asset.asset.form.storageLocation.remote"),

                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    storageLocation: "remote",
                  });
                },
              },
            ]}
            variant={modifiedData?.storageLocation === "remote" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
      </div>
      <div className="p-no-margin mt-20px">
        <div className="flex flex-col gap-10 w-full">
          <Text
            name="title"
            errors={errors}
            value={modifiedData?.title}
            onChange={handleInputChange}
            label={t("caymland.core.title")}
            required
            type="text"
            wFull="w-full"
          />
          <Text
            name="alias"
            value={modifiedData?.alias}
            onChange={handleInputChange}
            label="Alias"
            required
            tooltip={t("caymland.asset.asset.help.alias")}
            type="text"
            wFull="w-full"
          />
        </div>

        <TextArea
          value={modifiedData?.description ?? ""}
          onChange={(data) => setModifiedData((prev) => ({ ...prev, description: data }))}
        />
      </div>
      <Select
        name="category"
        label={t("caymland.page.tracking.form.category")}
        bundle="asset"
        options={retrieveCategories}
        value={modifiedData?.category ?? null}
        placeholder={t("caymland.core.select")}
        onChange={handleSelectChange}
        trigger={triggerCategories}
        isClearable
        isCreatable
      />
      <Select
        name="language"
        label={t("caymland.core.language")}
        options={languages.map((lang) => ({ label: t(lang.labelKey), value: lang.value }))}
        value={modifiedData?.language}
        placeholder={t("caymland.core.select")}
        onChange={(selectedOption) => handleFormChange("language", selectedOption?.value)}
        tooltip={t("caymland.asset.asset.form.language.help")}
      />
      <div className="switch-holder pb-20">
        <div className="switch-toggle">
          <Checkbox
            name={"isPublished"}
            value={modifiedData?.isPublished}
            onChange={(e) => handleToggleChange(e)}
            label={t("caymland.core.form.published")}
          />
        </div>
      </div>
      <DatePicker
        label={t("caymland.core.form.publishup")}
        name={"Publish at (date/time)"}
        value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
        maxDate={modifiedData?.publishDown}
      />
      <DatePicker
        label={t("caymland.core.form.publishdown")}
        name={"Unpublish at (date/time)"}
        value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
        minDate={modifiedData?.publishUp}
      />
      <div className="switch-holder">
        <Checkbox
          name="disallow"
          value={modifiedData?.disallow}
          onChange={(e) => handleToggleChange(e)}
          label={t("caymland.asset.asset.form.disallow.crawlers")}
          tooltip={t("caymland.asset.asset.form.disallow.crawlers.tooltip")}
        />
      </div>
      <div className="switch-holder">
        <Checkbox
          name="subscribe"
          value={modifiedData && modifiedData?.subscribe}
          onChange={(e) => handleToggleChange(e)}
          label={t("caymland.asset.download.subscribe")}
        />
      </div>

      {modifiedData.subscribe && assetOptions && (
        <>
          <Select
            name="choiceForm"
            label={t("caymland.asset.download.form")}
            isSearchable
            isClearable
            options={assetOptions?.formChoices}
            value={assetOptions?.formChoices.find((option) => option.value === modifiedData?.form)}
            placeholder={t("caymland.core.select")}
            onChange={(selectedOption) => handleFormChange("form", selectedOption?.value)}
          />
          <Select
            name="choiceEmail"
            label={t("caymland.asset.download.email")}
            isSearchable
            isClearable
            options={assetOptions?.emailChoices}
            value={assetOptions?.emailChoices?.find((option) => option.value === modifiedData?.email)}
            placeholder={t("caymland.core.select")}
            onChange={(selectedOption) => handleFormChange("email", selectedOption?.value)}
          />
          <Select
            name="sentInterval"
            label={t("caymland.asset.download.interval")}
            isSearchable
            isClearable
            options={assetOptions?.intervalChoices}
            value={assetOptions?.intervalChoices?.find((option) => option.value === modifiedData?.interval)}
            placeholder={t("caymland.core.select")}
            onChange={(selectedOption) => handleFormChange("interval", selectedOption?.value)}
            tooltip={t("caymland.asset.download.interval.tooltip")}
          />
        </>
      )}
      {/* <p>Upload a file (max filesize allowed = 1 MB)</p>
      <form className="dropzone digits mt-10px" id="singleFileUpload" action="/upload.php">
        <div className="dz-message needsclick h-200-dropzone">
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            canCancel={false}
            inputContent="Drop a file or click to select a picture"
            styles={{
              dropzoneActive: { borderColor: "green" },
            }}
          />
        </div>
      </form> */}
    </>
  );
};

export default Sidebar;
