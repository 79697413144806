import React, { useState, useEffect } from "react";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import { ClipLoader } from "react-spinners";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormControl } from "react-bootstrap";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Success } from "../../reusableComponents/Alerts";
import { useNavigate, useLocation } from "react-router-dom";
import { Select, Text } from "../../reusableComponents/Inputs";
import Button from "../Buttons/Button";
import { useGetContactFieldsQuery } from "../../../redux/api/fieldsApi";
import { useExportContactMutation, useRunnerCommandMutation } from "../../../redux/api/contactsApi";
import { t } from "i18next";

const ExportModal = ({ isOpen, setIsOpen, modalTitle, icon, closeLink, query }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [leftColumnValue, setLeftColumnValue] = useState([]);
  const [leftColumnSearchValue, setLeftColumnSearchValue] = useState("");
  const [rightValue, setRightValue] = useState([]);
  const [rightColumnSearchValue, setRightColumnSearchValue] = useState("");
  const [applyLoading, setApplyLoading] = useState(false);

  const [modifiedData, setModifiedData] = useState({
    exportfields: [{ format: "csv", fields: [] }],
    ...query,
  });

  const fileFormatOptions = [
    { value: "csv", label: "CSV" },
    { value: "xlsx", label: "Excel" },
  ];
  const { data } = useGetContactFieldsQuery(undefined, { skip: !isOpen });
  const [exportContact] = useExportContactMutation();

  useEffect(() => {
    if (data?.data) {
      retrieveFields();
    }
  }, [data]);
  const retrieveFields = async () => {
    let orderFields = data.data || [];

    orderFields = orderFields.filter((field) => field.isPublished !== false);

    const rightFields = orderFields.filter((field) =>
      ["firstname", "lastname", "email", "address1", "address2", "country", "city"].includes(field.alias)
    );

    const leftFields = orderFields.filter(
      (field) => !["firstname", "lastname", "email", "address1", "address2", "country", "city"].includes(field.alias)
    );

    setLeftColumnValue(leftFields);
    setRightValue(rightFields);
  };

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLeftColumnSearchChange = (e) => {
    setLeftColumnSearchValue(e.target.value);
  };

  const handleRightColumnSearchChange = (e) => {
    setRightColumnSearchValue(e.target.value);
  };

  const handleSelectAllColumns = () => {
    setRightValue([...rightValue, ...leftColumnValue]);
    setLeftColumnValue([]);
  };

  const handleDeselectAllColumns = () => {
    setLeftColumnValue([...leftColumnValue, ...rightValue]);
    setRightValue([]);
  };

  const moveItem = (item, direction) => {
    if (direction === "right") {
      setLeftColumnValue(leftColumnValue.filter((option) => option !== item));
      setRightValue([...rightValue, item]);
    } else {
      setRightValue(rightValue.filter((option) => option !== item));
      setLeftColumnValue([...leftColumnValue, item]);
    }
  };
  const [runnerCommand] = useRunnerCommandMutation();
  const handleExportAction = async () => {
    setApplyLoading(true);
    const selectedFields = rightValue.map((option) => option.alias);

    // Update the modifiedData with the selected fields
    const exportData = {
      ...query,
      exportfields: {
        format: modifiedData.exportfields.format || "csv",
        fields: selectedFields,
      },
    };

    try {
      const response = await exportContact(exportData);
      if (!response.error) {
        Success(`Export process was successfully created.`);

        // Extract the ID from the response
        const exportId = response.data.export.id;

        // Prepare the command body
        const commandBody = {
          command: "caymland:export",
          arguments: {
            id: exportId,
          },
        };

        // Call runnerCommand with the appropriate body
        await runnerCommand(commandBody);

        setApplyLoading(false);
        setIsOpen(false);
        navigate("/contacts/export/custom");
      }
    } catch (e) {
      setApplyLoading(false);
      console.error(e);
    }
    setApplyLoading(false);
    setIsOpen(false);
  };

  return (
    <Modal backdrop="static" keyboard={true} isOpen={isOpen} toggle={toggle} size="lg">
      <form>
        <ModalHeader toggle={closeLink ?? toggle}>
          <div className="d-flex items-center gap-5px p-no-margin">
            <p style={{ fontWeight: "600", fontSize: "16px" }}>{modalTitle}</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <>
            <div className="form-row w-full">
              <div className="col-12 mb-3 px-3">
                <Select
                  name="validationDefault03"
                  label={`${t("caymland.core.select")} ${t("caymland.core.export.format")}`}
                  required={true}
                  options={fileFormatOptions}
                  value={modifiedData.exportfields.format ? modifiedData.exportfields.format : "csv"}
                  onChange={(selectedOption) => {
                    setModifiedData((prevData) => ({
                      ...prevData,
                      exportfields: {
                        ...prevData.exportfields,
                        format: selectedOption.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="col-12 px-3" style={{ marginRight: "30px" }}>
                <h4 className="mt-10px required">
                  <strong>{t("caymland.core.fields.export.label")}</strong>
                </h4>
                <div className="d-flex">
                  <div className="w-50">
                    <div className="flex flex-col gap-10 w-100">
                      <div class="input-container ic1 mb-10px">
                        <Text
                          name={t("caymland.core.fields.export.label")}
                          value={leftColumnSearchValue}
                          onChange={handleLeftColumnSearchChange}
                          placeholder={t("caymland.core.multiselect.search")}
                        />
                      </div>
                    </div>

                    <FormControl
                      as="select"
                      multiple
                      // value={sortOptionsByIndex(filterOptionsBySearch(leftColumns, leftColumnSearchValue)).map(option => option.value)}
                      // onChange={handleSelectChangeColumns}
                      style={{ height: "200px" }}
                    >
                      {leftColumnValue
                        .filter((option) => option.label.toLowerCase().includes(leftColumnSearchValue.toLowerCase()))
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.alias}
                            style={{ fontSize: "14px" }}
                            onClick={() => moveItem(option, "right")}
                          >
                            {option.label}
                          </option>
                        ))}
                    </FormControl>

                    <div className="float-right">
                      <Link className="cursor-pointer" onClick={handleSelectAllColumns}>
                        {t("caymland.core.select.all")}
                      </Link>
                    </div>
                  </div>
                  <RiArrowLeftRightFill size={35} className="align-self-center" />
                  <div className="w-50" style={{ marginLeft: "10px" }}>
                    <div className="flex flex-col gap-10 w-100">
                      <div class="input-container ic1 mb-10px">
                        <Text
                          name="Select fields"
                          value={rightColumnSearchValue}
                          onChange={handleRightColumnSearchChange}
                          placeholder={t("caymland.core.multiselect.search")}
                        />
                      </div>
                    </div>
                    <FormControl
                      as="select"
                      multiple
                      // value={sortOptionsByIndex(filterOptionsBySearch(leftColumns, leftColumnSearchValue)).map(option => option.value)}
                      // onChange={handleSelectChangeColumns}
                      style={{ height: "200px" }}
                    >
                      {rightValue
                        .filter((option) => option.label.toLowerCase().includes(rightColumnSearchValue.toLowerCase()))
                        .map((option) => (
                          <option
                            style={{ fontSize: "14px" }}
                            key={option.id}
                            value={option.alias}
                            onClick={() => moveItem(option, "left")}
                          >
                            {option.label}
                          </option>
                        ))}
                    </FormControl>

                    <Link className="cursor-pointer" onClick={handleDeselectAllColumns}>
                      {t("caymland.core.deselect.all")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            buttons={[
              {
                name: "close",
                title: `${t("caymland.core.close")}`,
                icon: <ImCancelCircle size={15} />,
                onClick: () => toggle(),
              },
              {
                name: "Save",
                title: `${t("caymland.core.form.save")}`,
                icon: <TbDeviceFloppy size={15} />,
                onClick: () => handleExportAction(),
              },
            ]}
            loading={applyLoading}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ExportModal;
