import React, { useState, useRef, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";

const MenuItemWithSubMenu = ({ data, handleSubMenuClick }) => {
  const { key, subMenu } = data || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);
  const [parentDimensions, setParentDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (menuRef.current) {
      const { offsetWidth, offsetHeight } = menuRef.current;
      setParentDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  const handleSubMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        ref={menuRef}
        onClick={handleSubMenuOpen}
        style={{ color: "rgb(117, 117, 117)", fontSize: "13px", padding: "6px", display: "flex", gap: "3px" }}
      >
        <AutoDeleteOutlinedIcon style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "12px" }} />
        {key}
        {subMenu.length > 0 && <ArrowRightIcon  />}
      </MenuItem>
      {subMenu.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleSubMenuClose}
          MenuListProps={{
            "aria-labelledby": key,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          style={{
            marginLeft: 0,
            marginTop: 0,
          }}
          sx={{
            "& .MuiMenu-list": {
              minWidth: "120px",
              padding: '0'
            },
          }}
        >
          {subMenu.map((subMenuItem, index) => (
            <MenuItem
              key={index}
              onClick={() => handleSubMenuClick(subMenuItem.key)}
              style={{ color: "rgb(117, 117, 117)", fontSize: "13px", padding: "6px", display: "flex", gap: "3px" }}
            >
              {subMenuItem.key}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default MenuItemWithSubMenu;
