import React, { useEffect, useState } from "react";

import { Text, Label, TextArea, Select, Checkbox, DatePicker } from "../../../../reusableComponents/Inputs";
import Button from "../../../../reusableComponents/Buttons/Button";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../../redux/api/categoriesApi";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import CustomEditor from "../../../../reusableComponents/Editor/CustomEditor";
import CustomizedAccordions from "../../../../reusableComponents/Accordion/Accordion";

const Sidebar = ({ modifiedData, setModifiedData, errors, setErrors }) => {
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "form",
  });
  const [newItem, setNewItem] = useState();
  const [isCustomCSSEnabled, setIsCustomCSSEnabled] = useState(false);
  const themes = [{ label: t("caymland.core.slot.label.codemode"), value: "caymland_code_mode" }];
  const [addCategory] = useAddCategoryMutation();

  const postActions = [
    { value: "return", label: t("caymland.form.form.postaction.return") },
    { value: "redirect", label: t("caymland.form.form.postaction.redirect") },
    { value: "message", label: t("caymland.form.form.postaction.message") },
  ];

  const location = useLocation();
  const isClone = location.pathname.includes("clone");
  const isEdit = location.pathname.includes("edit");

  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const handleInputChange = (e, type) => {
    const isCheckbox = type === "checkbox";
    const { name, value, checked } = e.target;

    if (name === "name") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }

    setModifiedData((prev) => ({
      ...prev,
      [name]: isCheckbox ? checked : value,
    }));
  };

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const handleSelectChange = (selectedOption, name) => {
    if (name === "category") {
      if (selectedOption && selectedOption.value === "create_new") {
        setModalOptions((prev) => ({
          ...prev,
          isOpen: true,
        }));
        return;
      }
      setModifiedData({ ...modifiedData, category: selectedOption });
    } else {
      setModifiedData((prev) => ({
        ...prev,
        [name]: selectedOption?.value ?? null,
      }));
    }
  };

  const checkDate = (date) => {
    if (typeof date === "string") {
      return new Date(date);
    } else {
      return date;
    }
  };

  const accordionsDesc = [
    {
      name: t("caymland.core.additional") + " " + "CSS",
      component: (
        <CustomEditor
          value={
            (
              modifiedData?.fields?.find((field) => field.type === "freehtml" && field?.label === "additionalCSS")
                ?.properties?.text || ""
            ).replace(/<\/?style>/g, "") // Strip <style> tags
          }
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              fields: modifiedData.fields.map((field) =>
                field.label === "additionalCSS"
                  ? {
                      ...field,
                      properties: {
                        ...field.properties,
                        text: e, // Save the CSS text without <style> tags
                      },
                    }
                  : field
              ),
            })
          }
          defLanguage="css"
        />
      ),
    },
  ];

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Label
        name="type"
        required={true}
        label={t("caymland.form.type.header")}
        tooltip={t("caymland.form.type.campaign.description")}
      />
      <div className="flex items-center justify-center w-full" style={{ marginBottom: "25px" }}>
        <div className="w-50 parent-100">
          <Button
            isDisabled={modifiedData?.formType === "standalone" && (isClone || isEdit)}
            buttons={[
              {
                name: "campaign",
                title: t("caymland.form.type.campaign"),
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    formType: "campaign",
                  });
                },
              },
            ]}
            variant={modifiedData?.formType === "campaign" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
        <div className="w-50 parent-100">
          <Button
            isDisabled={modifiedData.formType === "campaign" && (isClone || isEdit)}
            buttons={[
              {
                name: "standaloneForm",
                title: t("caymland.form.type.standalone"),
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    formType: "standalone",
                  });
                },
              },
            ]}
            variant={modifiedData?.formType === "standalone" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
      </div>
      <Text
        name="name"
        errors={errors?.name}
        required={true}
        value={modifiedData?.name}
        onChange={(e) => handleInputChange(e)}
        label={t("caymland.install.form.email.from_name")}
      />
      <div className="small-editor">
        <TextArea
          label={t("caymland.lead.field.companydescription")}
          value={modifiedData?.description ?? ""}
          onChange={(data) => {
            setModifiedData((prev) => ({
              ...prev,
              description: data,
            }));
          }}
        />
      </div>
      <Select
        name="postAction"
        label={t("caymland.form.form.postaction")}
        isClearable
        options={postActions}
        value={postActions.find((e) => e?.value === modifiedData?.postAction) ?? modifiedData?.postAction}
        onChange={(e) => handleSelectChange(e, "postAction")}
      />
      {modifiedData.postAction === "redirect" && (
        <Text
          label={t("caymland.form.form.postaction.redirect")}
          required={true}
          type="url"
          value={modifiedData?.postActionProperty || ""}
          onChange={(e) => {
            setModifiedData({
              ...modifiedData,
              postActionProperty: e.target.value,
            });
          }}
        />
      )}
      {modifiedData.postAction === "message" && (
        <div className="small-editor">
          <TextArea
            label={t("caymland.form.form.postactionproperty")}
            required={modifiedData?.postAction === "redirect" || modifiedData?.postAction === "message" ? true : false}
            errors={errors?.alias}
            value={modifiedData?.postActionProperty}
            onChange={(data) => {
              setModifiedData({
                ...modifiedData,
                postActionProperty: data,
              });
            }}
          />
        </div>
      )}
      <Select
        name="category"
        label={t("caymland.page.tracking.form.category")}
        value={modifiedData?.category ?? null}
        onChange={(e) => handleSelectChange(e, "category")}
        options={retrieveCategories}
        trigger={triggerCategories}
        bundle="form"
        isClearable
        isCreatable
      />
      <Checkbox
        name="isPublished"
        value={modifiedData?.isPublished}
        onChange={(e) => handleInputChange(e, "checkbox")}
        label={t("caymland.core.form.published")}
      />
      <DatePicker
        label={t("caymland.core.form.publishup")}
        name={"Publish at (date/time)"}
        value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
        maxDate={modifiedData?.publishDown}
      />
      <DatePicker
        label={t("caymland.core.form.publishdown")}
        name={"Unpublish at (date/time)"}
        value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
        minDate={modifiedData?.publishUp}
      />
      <Select
        name="theme"
        label={t("caymland.core.form.theme")}
        options={themes}
        value={themes.find((e) => e.value === modifiedData?.template) ?? modifiedData?.template}
        onChange={(e) => handleSelectChange(e, "template")}
      />
      <Checkbox
        name="noIndex"
        value={modifiedData?.noIndex}
        onChange={(e) => handleInputChange(e, "checkbox")}
        label={t("caymland.form.form.no_index")}
      />
      <Checkbox
        name="inKioskMode"
        value={modifiedData?.inKioskMode}
        onChange={(e) => handleInputChange(e, "checkbox")}
        label={t("caymland.form.form.kioskmode")}
        tooltip={t("caymland.form.form.kioskmode.tooltip")}
      />
      <Checkbox
        name="renderStyle"
        value={modifiedData?.renderStyle}
        onChange={(e) => handleInputChange(e, "checkbox")}
        label={t("caymland.form.form.renderstyle")}
        tooltip={t("caymland.form.form.renderstyle.tooltip")}
      />
      <Checkbox
        name="hashcodeMode"
        value={modifiedData?.hashcodeMode}
        onChange={(e) => handleInputChange(e, "checkbox")}
        label={t("caymland.form.form.hashcode_mode")}
        tooltip={t("caymland.form.form.hashcode_mode.tooltip")}
      />
      <CustomizedAccordions accordions={accordionsDesc} />
    </>
  );
};

export default Sidebar;
