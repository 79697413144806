import React, { useRef, useState, useEffect } from "react";
import PagesEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetBuilderConfigQuery } from "../../../../../redux/api/usersApi";

const Builder = ({
  editorData: { projectId, mergeTags },
  setModifiedData,
  modifiedData,
  apply,
  setApplyAndCloseTemplateRef,
}) => {
  const { t } = useTranslation();
  const pagesEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const { data } = useGetBuilderConfigQuery("web");

  const applyAndCloseTemplate = (params) => {
    pagesEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      const encodedHtml = Buffer.from(html).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      pagesEditorRef.current.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setApplyBuilder("savebuilder");
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    if (setApplyAndCloseTemplateRef) {
      setApplyAndCloseTemplateRef(() => applyAndCloseTemplate);
    }
  }, [setApplyAndCloseTemplateRef]);

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [shouldApply]);

  const onReady = () => {
    if (data) {
      if (modifiedData?.customJson) {
        try {
          const design = JSON.parse(modifiedData.customJson);

          // Check if the parsed design is empty
          const isEmptyDesign =
            (Array.isArray(design) && design.length === 0) ||
            (typeof design === "object" && Object.keys(design).length === 0);

          if (isEmptyDesign) {
            console.log("customJson is empty, loading default design.");
            // Load an empty or default design
            pagesEditorRef?.current?.editor?.loadDesign({
              body: {
                rows: [],
                values: {},
              },
            });
          } else {
            pagesEditorRef?.current?.editor?.loadDesign(design);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Load an empty or default design in case of a parsing error
          pagesEditorRef?.current?.editor?.loadDesign({
            body: {
              rows: [],
              values: {},
            },
          });
        }
      } else {
        console.log("No custom JSON data to load, loading default design.");
        // Load an empty or default design if no custom JSON is available
        pagesEditorRef?.current?.editor?.loadDesign({
          body: {
            rows: [],
            values: {},
          },
        });
      }
    }
  };

  useEffect(() => {
    if (modifiedData?.customJson) {
      onReady();
    }
  }, [modifiedData?.customJson, data]);

  return (
    <>
      {data && (
        <PagesEditor ref={pagesEditorRef} onReady={onReady} minHeight="100%" projectId={projectId} options={data} />
      )}
    </>
  );
};

export default Builder;
