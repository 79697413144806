import React from "react";
import { Skeleton, Box, Card, CardContent } from "@mui/material";

const DetailsSkeleton = ({ applyPadding = false, company = false, contact = false }) => {
  return (
    <Card
      style={{
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
        height: "auto",
      }}
    >
      <CardContent
        style={{
          padding: company ? "20px 30px" : "16px",
        }}
      >
        {" "}
        {contact ? (
          <Box sx={{ p: applyPadding ? 2 : 0, mt: 0 }}>
            {/* Avatar, Name, and Do Not Contact Tag */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Skeleton variant="circular" width={100} height={100} />
                <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                  <Skeleton variant="text" width={300} height={50} />
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 8, paddingRight: "80px" }}>
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="circular" width={50} height={50} />
              </Box>
            </Box>

            {/* Categories and Tags */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px",
                borderTop: "1px solid gainsboro",
                marginTop: "20px",
              }}
            >
              <Box sx={{ display: "flex", gap: 1, width: "100%", paddingTop: "10px" }}>
                <Skeleton variant="rectangular" width="50%" height={35} />
              </Box>
              <Box sx={{ display: "flex", gap: 1, width: "100%", paddingTop: "10px" }}>
                <Skeleton variant="rectangular" width="50%" height={35} />
              </Box>
            </Box>
          </Box>
        ) : company ? (
          <Box sx={{ p: applyPadding ? 2 : 0, mt: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, height: "100%" }}>
              <Skeleton variant="circular" width={100} height={100} sx={{ alignSelf: "center" }} />
              <Box sx={{ display: "flex", flexDirection: "column", ml: 2, justifyContent: "center" }}>
                <Skeleton variant="text" width={300} height={50} />
                <Skeleton variant="text" width={200} height={30} />
                <Skeleton variant="text" width={200} height={30} />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ p: applyPadding ? 2 : 0, mt: 0 }}>
            <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
              <Skeleton variant="text" width="50%" height={60} />
              <Skeleton variant="text" width="50%" height={60} />
            </Box>
            <Skeleton variant="rectangular" width="100%" height={120} />
            <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
              <Skeleton variant="text" width="100%" height={60} />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DetailsSkeleton;
