import moment from "moment";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../../reusableComponents/loading";
import { FiClock } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import { t } from "i18next";
import { useUpdateDwcMutation } from "../../../../../redux/api/dwcApi";

function Translations({ extras, refetch }) {
  const { id } = useParams();
  const [updateDwc] = useUpdateDwcMutation();

  const handleTogglePublished = async (data) => {
    // Toggle isPublished immediately
    const newIsPublished = !data.isPublished;

    // Update the UI immediately by changing the state of the isPublished property
    let obj = { ...data, isPublished: newIsPublished };

    // Update properties as before
    if (obj.translationParent) {
      obj.translationParent = data.translationParent.id;
    }

    if (obj.translationChildren) {
      obj.translationChildren = data.translationChildren.map((x) => x.id);
    }

    if (obj.unsubscribeForm) {
      obj.unsubscribeForm = data.unsubscribeForm.id;
    }

    if (obj.lists) {
      obj.lists = data.lists.map((x) => x.id);
    }

    try {
      // Make API call to update the DWC item
      await updateDwc({ id: obj.id, isPublished: newIsPublished }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to update:", error);
      // Revert the isPublished state if the update fails
      obj.isPublished = !newIsPublished;
    } finally {
    }
  };

  return (
    <div className="p-30">
      {extras?.translations?.parent && (
        <div>
          <div
            className="flex items-center justify-between cardShadow"
            style={{ padding: "15px", background: "#fbfbfb", borderRadius: "8px" }}
          >
            <div className="flex items-center gap-5px">
              {moment(extras?.translations?.parent?.publishUp).format("DD.MM.YYYY HH:mm") <
                moment(new Date()).format("DD.MM.YYYY HH:mm") && extras?.translations?.parent?.isPublished ? (
                <FiClock
                  title={t("caymland.core.form.expired")}
                  color="#f86b4f"
                  size={20}
                  style={{ marginRight: "25px" }}
                />
              ) : (
                <label className="switch-published" style={{ marginBottom: "0", marginRight: "25px" }}>
                  <input
                    type="checkbox"
                    checked={extras?.translations?.parent?.isPublished}
                    onChange={() => handleTogglePublished(extras?.translations?.parent)}
                  />
                  <span className="slider-published round-published"></span>
                </label>
              )}
              <Link to={`/dwc/view/${extras?.translations?.parent?.id}`} style={{ color: "#1a1a1a" }}>
                {extras?.translations?.parent?.name}
              </Link>
              <button
                style={{
                  border: "1px solid #ffbb33",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  color: "#ffbb33",
                  padding: "4px",
                }}
              >
                {t("caymland.core.parent")}
              </button>
              {extras?.translations?.parent?.id == id && (
                <button
                  style={{
                    border: "1px solid #04B76B",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    color: "#04B76B",
                    padding: "4px",
                  }}
                >
                  {t("caymland.core.current")}
                </button>
              )}
            </div>
            <em style={{ color: "#b8b8b8" }}>{extras?.translations?.parent?.language}</em>
          </div>
        </div>
      )}

      {extras?.translations?.children ? (
        <div>
          {Object.entries(extras?.translations?.children).map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between cardShadow mt-20"
              style={{ padding: "15px", background: "#fbfbfb", borderRadius: "8px" }}
            >
              <div className="flex items-center gap-5px">
                {moment(item[1]?.publishUp).format("DD.MM.YYYY HH:mm") <
                  moment(new Date()).format("DD.MM.YYYY HH:mm") && item[1]?.isPublished ? (
                  <FiClock
                    title={t("caymland.core.form.expired")}
                    color="#f86b4f"
                    size={20}
                    style={{ marginRight: "25px" }}
                  />
                ) : (
                  <label className="switch-published" style={{ marginBottom: "0", marginRight: "25px" }}>
                    <input
                      type="checkbox"
                      checked={item[1].isPublished}
                      onChange={() => handleTogglePublished(item[1])}
                    />
                    <span className="slider-published round-published"></span>
                  </label>
                )}
                <Link to={`/dwc/view/${item[1]?.id}`} style={{ color: "#1a1a1a" }}>
                  {item[1]?.name}
                </Link>

                {item[1]?.id == id && (
                  <button
                    style={{
                      border: "1px solid #04B76B",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      color: "#04B76B",
                      padding: "4px",
                    }}
                  >
                    {t("caymland.core.current")}
                  </button>
                )}
              </div>
              <em style={{ color: "#b8b8b8" }}>{item[1]?.language}</em>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Translations;
