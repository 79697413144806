import React, { useState, useEffect, useRef } from "react";
import { Select } from "../../../reusableComponents/Inputs";
import { FaCheck, FaRegTimesCircle, FaTimes } from "react-icons/fa";
import { stagesApi } from "../../../../redux/api";
import { retrieveStages } from "../../../reusableComponents/retrieveStages/retrieveStages";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";
import { Success } from "../../../reusableComponents/Alerts";
import { Controller } from "react-hook-form";
import { AiOutlineSave } from "react-icons/ai";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Stage = ({ data, control, setMessage, setShow, setBackgroundToast }) => {
  const { t } = useTranslation();
  const [stage, setStage] = useState(data?.contact?.stage);
  const [edit, setEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const editRef = useRef(null);

  useEffect(() => {
    setStage(data?.contact?.stage);
  }, [data?.contact?.stage]);

  const [triggerStage] = stagesApi.useLazyGetStagesQuery();
  const [updateContact] = useUpdateContactsMutation();

  const handleStageChange = (selectedOption) => {
    setStage(selectedOption?.value);
  };

  const updateStage = async () => {
    try {
      let updatedStage = stage;
      console.log(updatedStage);
      if (!updatedStage) {
        updatedStage = null;
      }

      const response = await updateContact({ id: data.contact.id, stage: updatedStage?.id ?? updatedStage });
      if (!response.error) {
        setStage(response.data.contact.stage);
        setEdit(false);
        setShow(true);
        setMessage(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.error.message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      console.error("Error updating stage:", error);
      // Optionally handle reversion of optimistic UI update here
    }
  };

  const isWhite = (hexColor) => {
    if (!hexColor) {
      return;
    }
    const whiteLimit = 200;
    if (hexColor.startsWith("#")) hexColor = hexColor.slice(1); // Remove "#" if present
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    return r > whiteLimit && g > whiteLimit && b > whiteLimit;
  };

  console.log(stage);
  return (
    <>
      <div
        ref={editRef}
        style={{ minWidth: "300px", justifyContent: "flex-start", width: "100%" }}
        className={` right-info cursor-pointer w-full !tw-flex-row tw-gap-2 tw-mt-4 bl-1`}
        onClick={() => setEdit(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div style={{ padding: "0", display: "flex", gap: "5px", marginLeft: "10px" }}>
          <span>{t("caymland.lead.stage.label")}:</span>
        </div>

        {edit ? (
          <div className="flex items-center gap-10 " style={{ minWidth: "200px" }}>
            <div className="w-full tw-mt-3">
              <Controller
                name="stage"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <Select
                    id={name}
                    isSearchable
                    isClearable
                    options={retrieveStages}
                    trigger={triggerStage}
                    value={
                      stage?.id
                        ? {
                            label: stage.name,
                            value: stage.id,
                          }
                        : stage
                    }
                    onChange={handleStageChange}
                  />
                )}
              />
            </div>

            <group className="d-flex gap-5px mb-20px">
              {" "}
              <button
                className="normal-btn"
                onClick={(e) => {
                  e.stopPropagation(), setEdit(false), setStage(data.contact.stage);
                }}
              >
                <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
              </button>
              <button className="normal-btn" onClick={updateStage}>
                <AiOutlineSave color="#135F95" size={20} title="Save" />
              </button>
            </group>
          </div>
        ) : (
          <>
            <div>
              <span
                className="cardShadow"
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                  fontSize: "0.85rem",
                  fontWeight: "500",
                  // border: `1px solid ${isWhite(stage?.color || "#2f3061") ? "black" : `#${stage?.color}`}`,
                  color: isWhite(stage?.color) ? "black" : `#${stage?.color}`,
                  borderRadius: "16px",
                  overflow: "hidden",
                  cursor: "pointer",
                  height: "30px",
                  lineHeight: "1.5rem",
                  padding: "0",
                  margin: 0,
                }}
              >
                <span
                  style={{
                    backgroundColor: `#${stage?.color}`,
                    color: isWhite(stage?.color) ? "black" : "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 0.8rem",
                    width: "40px",
                    height: "100%",
                    borderTopLeftRadius: "16px",
                    borderBottomLeftRadius: "16px",
                  }}
                >
                  <i className={stage?.icon || "fa-solid fa-x"} style={{ fontSize: "1rem" }}></i>
                </span>
                <span
                  style={{
                    padding: "0 1rem",
                    background: "#e0e0e0",
                    color: "#333333",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    borderTopRightRadius: "16px",
                    borderBottomRightRadius: "16px",
                  }}
                >
                  {stage?.name}
                </span>
              </span>
            </div>
          </>
        )}
        {hover && !edit && <Edit fontSize="small" style={{ fontSize: "16px" }} />}
      </div>
    </>
  );
};

export default Stage;
