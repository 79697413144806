import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";
import moment from "moment";

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Assets"],
  endpoints: (build) => ({
    getAssets: build.query({
      query: customBaseQuery((q) => `/api/assets?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Assets"],
    }),
    getFolderAssets: build.query({
      query: (q) => `/api/folders?limit=300&where[0][col]=source&where[0][expr]=eq&where[0][val]=assets`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderAssets: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderAssets: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Assets"],
    }),
    //DELETE FOLDER
    deleteFolderAssets: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateAssetsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/assets/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeAssetsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/assets/${itemId}`,
        method: "DELETE",
      }),
    }),
    getAsset: build.query({
      query: (id) => `/api/assets/${id}`,
      keepUnusedDataFor: false,
    }),
    updateAssets: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/assets/${id}/edit`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Assets"],
    }),
    addNewAsset: build.mutation({
      query: (body) => ({
        url: `/api/assets/new`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "Assets", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    addNewAssetFile: build.mutation({
      query: (body) => ({
        url: `/api/files/assets/new`,
        method: "POST",
        body,
      }),
      responseHandler: (response) => response.text(),
    }),
    getAssetsExtras: build.query({
      query: ({ id, dateFrom, dateTo }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        });
        return `api/assets/${id}/extras?${params}`;
      },
      providesTags: ["Assets"],
    }),
    deleteAssets: build.mutation({
      query: (id) => ({
        url: `/api/assets/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          assetsApi.util.updateQueryData("getAssets", undefined, (draft) => {
            draft.data = draft.data.filter((assets) => assets.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (id) => [{ type: "Assets", id }],
    }),
    getAssetPreview: build.query({
      query: (id) => `/api/assets/${id}/preview`,
      providesTags: (id) => [{ type: "Assets", id }],
    }),
    getAssetOptions: build.query({
      query: () => `/api/assets/registration/options`,
      providesTags: [{ type: "Assets" }],
      transformResponse: (response) => ({
        emailChoices: mapDataToOptions(response.emailChoices),
        formChoices: mapDataToOptions(response.formChoices),
        intervalChoices: mapDataToOptions(response.intervalChoices),
      }),
    }),
  }),
});

function mapDataToOptions(data) {
  return Object.entries(data).map(([key, value, index]) => ({
    label: value,
    value: isNaN(parseInt(key)) ? key : parseInt(key),
    key: index,
  }));
}

export const {
  useGetAssetsQuery,
  useDeleteAssetsMutation,
  useUpdateAssetsMutation,
  useGetAssetQuery,
  useGetAssetPreviewQuery,
  useGetAssetOptionsQuery,
  useGetAssetsExtrasQuery,
  useAddNewAssetMutation,
  //FOLDERS
  useGetFolderAssetsQuery,
  useUpdateFolderAssetsMutation,
  useAddFolderAssetsMutation,
  useRemoveAssetsToFoldersMutation,
  useUpdateAssetsToFoldersMutation,
  useDeleteFolderAssetsMutation,
  useAddNewAssetFileMutation,
} = assetsApi;
