import React, { useCallback, useEffect, useState } from "react";
import { Tabs, TabsContent } from "../../../components/ui/tabs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  useMessageReadMutation,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
  useDeleteMessageMutation,
  useLockMessageMutation,
  useUnlockMessageMutation,
  useArchiveMessageMutation,
} from "../../../../src/redux/api/mailboxApi";
import { Button } from "../../../components/ui/button";
import { Search, MoreVertical, MailPlus } from "lucide-react";
import { Separator } from "../../../components/ui/separator";
import { MailList } from "./MailList";
import { Input } from "../../../components/ui/input";
import PostalMime from "postal-mime";
import { t } from "i18next";
import { SlideAlert } from "../../reusableComponents/Alerts";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  MarkEmailUnreadOutlined as MarkEmailUnreadOutlinedIcon,
  MarkEmailReadOutlined as MarkEmailReadOutlinedIcon,
  MailLockOutlined as MailLockOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
} from "@mui/icons-material";
import { ResizablePanel } from "../../../components/ui/resizable";
import { useSelector } from "react-redux";

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

const InboxSection = ({
  items,
  setQuery,
  query,
  parsedEmail,
  activeFolder,
  isLoading,
  setParsedEmail,
  setActiveMail,
  addTab,
  setActiveTab,
  mailIDs,
  setMailIDs,
  setLockStatus,
  refetch,
}) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");
  const [activeMailId, setActiveMailId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [markAsRead] = useMarkAsReadMutation();
  const [markAsUnread] = useMarkAsUnreadMutation();
  const [messageRead] = useMessageReadMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [lockMessage] = useLockMessageMutation();
  const [unlockMessage] = useUnlockMessageMutation();
  const [archiveMessage] = useArchiveMessageMutation();

  const currentUser = useSelector((state) => state?.auth?.user);

  const handleDragStart = (e, taskId) => {
    if (mailIDs.length > 0) {
      e.dataTransfer.setData("text/plain", JSON.stringify(mailIDs));
    } else {
      e.dataTransfer.setData("text/plain", JSON.stringify(taskId));
    }
  };

  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setQuery((prev) => ({
        ...prev,
        search: value,
        // where: prev.where,
      }));
    }, 200),
    []
  );

  const lockEmail = async (id) => {
    try {
      const response = await lockMessage({ ids: [id] });
    } catch (error) {
      console.error("Error locking email:", error);
    }
  };

  const unlockEmail = async (id) => {
    try {
      const response = await unlockMessage({ ids: [id] });
    } catch (error) {
      console.error("Error unlocking email:", error);
    }
  };

  const sendReadRequest = async (sendHash, item) => {
    const currentUserName = `${currentUser.firstName} ${currentUser.lastName}`;

    if (item.user !== null && item.user !== currentUserName) {
      setShow(true);
      setMessage(`${item.user} arbeitet auch an dieser Nachricht`);

      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 5000);
      return;
    }

    try {
      let response = await messageRead(sendHash);
      setActiveMail(item);
      setActiveMailId(item.id);
      await lockEmail(item.id);

      let rawData = response?.data || (response?.error && response?.error?.data);

      if (rawData) {
        const parser = new PostalMime();
        try {
          const parsedEmailData = await parser.parse(rawData);
          setParsedEmail(parsedEmailData);
          setActiveTab("inbox");
        } catch (parseError) {
          console.error("Error parsing email data:", parseError);
        }
      } else {
        console.error("No valid data in the response");
      }
    } catch (error) {
      console.error("Failed to mark message as read", error);
    }
  };

  const batchHandler = async (actionType) => {
    if (mailIDs.length === 0) {
      return;
    }

    try {
      let response;
      if (actionType === "read") {
        response = await markAsRead({ ids: mailIDs });
      } else if (actionType === "unread") {
        response = await markAsUnread({ ids: mailIDs });
      } else if (actionType === "delete") {
        response = await deleteMessage({ ids: mailIDs });
      } else if (actionType === "lock") {
        response = await lockMessage({ ids: mailIDs });
        setLockStatus(true);
      } else if (actionType === "unlock") {
        response = await unlockMessage({ ids: mailIDs });
        setLockStatus(false);
      } else if (actionType === "archive") {
        response = await archiveMessage({ ids: mailIDs });
      }

      if (response && response.data) {
        console.log("response.data.message", response.data.message);
        setShow(true);
        setMessage(response.data.message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 5000);
        setMailIDs([]);
      } else {
        console.error("Failed to mark emails:", response.error);
      }
    } catch (error) {
      console.error("Error in marking emails:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (activeMailId) {
        unlockEmail(activeMailId);
      }
    };
  }, [activeMailId]);

  return (
    <ResizablePanel defaultSize={85} minSize={30}>
      <SlideAlert background={backgorundToast} show={show} message={message} setShowAlert={setShow} />
      <Tabs defaultValue="all">
        <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-h-[52px]">
          <p className="mb-0 tw-font-bold tw-text-[#135f85]" style={{ fontSize: "18px" }}>
            {activeFolder}
          </p>
          <div className="tw-flex tw-justify-center tw-items-center tw-gap-1">
            <Button variant="outline" onClick={() => addTab("Compose")} className="tw-h-[32px]">
              <MailPlus className="tw-mr-2 tw-h-3.5 tw-w-3.5 tw-text-[#135f95]" />
              <span className="tw-text-[#135f95]">{t("caymland.mailbox.list.action.compose")}</span>
            </Button>
            {mailIDs.length > 0 && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon" className="tw-h-5 tw-w-5">
                    <MoreVertical className="tw-h-5 tw-w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={() => batchHandler("read")}>
                    <MarkEmailReadOutlinedIcon
                      style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }}
                    />
                    {t("caymland.mailbox.list.action.setAsRead")}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => batchHandler("unread")}>
                    <MarkEmailUnreadOutlinedIcon
                      style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }}
                    />
                    {t("caymland.mailbox.list.action.setAsUnread")}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => batchHandler("delete")}>
                    <DeleteOutlineOutlinedIcon
                      style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }}
                    />
                    {t("caymland.mailbox.list.action.deleteMessage")}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => batchHandler("lock")}>
                    <MailLockOutlinedIcon
                      style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }}
                    />
                    {t("caymland.mailbox.list.action.lockMessage")}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => batchHandler("unlock")}>
                    <LockOpenOutlinedIcon
                      style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }}
                    />
                    {t("caymland.mailbox.list.action.unlock")}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => batchHandler("archive")}>
                    <ArchiveOutlinedIcon
                      style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }}
                    />
                    {t("caymland.mailbox.list.action.archive")}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
        <Separator />
        <div className="tw-bg-background/95 tw-p-4 tw-backdro p-blur tw-supports-[backdrop-filter]:tw-bg-background/60">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="tw-relative">
              <Search className="tw-absolute tw-left-2 tw-top-[10px] tw-h-3 tw-w-3 tw-text-muted-foreground" />
              <Input
                placeholder="Search"
                className="tw-pl-8 tw-max-h-[30px]"
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </div>
          </form>
        </div>
        <TabsContent value="all" className="tw-m-0">
          <MailList
            items={items || []}
            sendReadRequest={sendReadRequest}
            setQuery={setQuery}
            query={query}
            parsedEmail={parsedEmail}
            isFetching={isLoading}
            setMailIDs={setMailIDs}
            mailIDs={mailIDs}
            setShow={setShow}
            setMessage={setMessage}
            setBackgroundToast={setBackgroundToast}
            addTab={addTab}
            handleDragStart={handleDragStart}
            setLockStatus={setLockStatus}
            searchTerm={searchTerm}
          />
        </TabsContent>
      </Tabs>
    </ResizablePanel>
  );
};

export default InboxSection;
