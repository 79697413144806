import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Contacts from "./viewDwc/Contacts";
import ClickCounts from "./viewDwc/ClickCounts";
import Details from "./viewDwc/Details";
import Sidebar from "./viewDwc/Sidebar";
import DwcStats from "./viewDwc/DwcStats";
import Translations from "./viewDwc/Translations";
import Header from "../../../reusableComponents/Header/Header";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import { useDeleteDwcMutation, useGetDwcContentQuery, useGetDwcExtrasQuery } from "../../../../redux/api/dwcApi";
import Swal from "sweetalert2";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import Description from "../../../reusableComponents/Description/Description";
import { t } from "i18next";

function ViewDWC() {
  const [primarycolorTab, setprimarycolorTab] = useState(1);

  const [dwc, setDWC] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [contactsLength, setContactsLength] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const { id } = useParams();
  const navigate = useNavigate();

  const { data } = useGetDwcContentQuery(id);
  const {
    data: extras,
    refetch,
    isFetching,
    isLoading,
  } = useGetDwcExtrasQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
    limit: rowsPerPage,
    page: page,
  });
  const [deleteDwc] = useDeleteDwcMutation();

  const changeDynamicTab = (index) => {
    setprimarycolorTab(index);
  };

  useEffect(() => {
    setDWC(data?.dynamicContent);
  }, [data]);

  useEffect(() => {
    setExtraInfo(extras);
    setContactsLength(extras?.data?.contacts?.count);
  }, [extras]);

  async function handlePageChange(page) {
    setPage(page);
  }

  async function handlePerRowsChange(newPerPage, page) {
    setRowsPerPage(newPerPage);
    setPage(page);
  }

  const handleDateChange = () => {
    const fromDate = localStorage.getItem("fromDate");
    const toDate = localStorage.getItem("toDate");
    refetch({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: dwc?.name })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteDwc(id);
        if (!response?.error) {
          navigate("/dwc");
        }
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/dwc"),
    },
    {
      name: "edit",
      title: t("caymland.core.permissions.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/dwc/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.mailbox.list.action.deleteMessage"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} dwc={dwc} />,
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={dwc} />,
    },
  ];

  return (
    <Fragment>
      <>
        {isLoading ? (
          <HeaderSkeleton isView={true} />
        ) : (
          <Header buttons={buttons} title={dwc?.name} loading={isFetching} data={dwc} showIsPublished={true} />
        )}
        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton />
            ) : (
              <>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <DwcStats
                      extras={extraInfo}
                      setExtras={setExtraInfo}
                      handleDateChange={handleDateChange}
                      loading={isFetching}
                    />
                  </CardBody>
                </Card>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <Nav className="nav-primary w-full" tabs>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 1 ? "active" : ""}
                          onClick={() => changeDynamicTab(1)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.trackable.click_counts")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 2 ? "active" : ""}
                          onClick={() => changeDynamicTab(2)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.contact.segments.fields.contacts")}
                        </NavLink>
                      </NavItem>
                      {dwc?.translationChildren?.length || dwc?.translationParent ? (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={primarycolorTab === 3 ? "active" : ""}
                            onClick={() => changeDynamicTab(3)}
                            style={{
                              color: "#313131",
                              textTransform: "capitalize",
                            }}
                          >
                            {t("caymland.core.translations")}
                          </NavLink>
                        </NavItem>
                      ) : (
                        <></>
                      )}
                    </Nav>
                    <TabContent activeTab={primarycolorTab}>
                      <TabPane tabId={1}>
                        <ClickCounts extras={extraInfo} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <Contacts
                          handlePageChange={handlePageChange}
                          handlePerRowsChange={handlePerRowsChange}
                          extras={extras}
                          contactsLength={contactsLength}
                          tableLoading={isFetching}
                          rowsPerPage={rowsPerPage}
                          page={page}
                        />
                      </TabPane>
                      <TabPane tabId={3}>
                        <Translations dwc={dwc} extras={extraInfo} refetch={refetch} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwoNoMargin"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton applyPadding={true} />
            ) : (
              <>
                <CustomizedAccordions accordions={accordions} />
                {dwc && dwc?.content ? <CustomizedAccordions accordions={accordionsDesc} isOpen={true} /> : null}

                <Sidebar dwc={dwc} extras={extraInfo} />
              </>
            )
          }
        />
      </>
    </Fragment>
  );
}

export default ViewDWC;
