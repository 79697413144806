import React from "react";
import { Text, TextArea, Select } from "../../../reusableComponents/Inputs";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import { Controller } from "react-hook-form";
import { t } from "i18next";

const MainContent = ({ isFetching, control, errors }) => {

  const optionsData = [
    { label: "Incoming", value: "incoming" },
    { label: "Outgoing", value: "outgoing" },
  ];


  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <div style={{ display: "flex", width: "100%", gap: "10px", flexDirection: "column" }}>
      <div className="flex tw-gap-2.5">
        <Controller
          control={control}
          name="name"
          render={({ field: { name, onChange, value } }) => (
            <Text
              label="Name"
              name={name}
              type="text"
              value={value}
              marginBottom={true}
              required={true}
              onChange={onChange}
              errors={errors && errors[name]}
              wFull="tw-w-full"
            />
          )}
        />

        <Controller
          control={control}
          name="alias"
          render={({ field: { name, onChange, value } }) => (
            <Text
              label="Alias"
              name={name}
              type="text"
              value={value}
              marginBottom={true}
              required={true}
              onChange={onChange}
              errors={errors && errors[name]}
              wFull="tw-w-full"
            />
          )}
        />
      </div>
      <div className="flex tw-gap-2.5">
        <Controller
          control={control}
          name="type"
          render={({ field: { name, onChange, value } }) => (
            <Select
              name="Type"
              label={t("caymland.multiple.status")}
              options={optionsData}
              required
              isClearable={false}
              value={value}
              onChange={onChange}
              errors={errors && errors[name]}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { name, onChange, value } }) => (
            <TextArea
              label={"Description"}
              value={value}
              marginBottom={true}
              onChange={onChange}
              errors={errors && errors[name]}
              required={true}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MainContent;
