import React, { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import {
  useDeleteTriggerMutation,
  useGetTriggersQuery,
  useUpdateTriggerMutation,
} from "../../../../redux/api/triggersApi";
import { t } from "i18next";
import { CompanySearchHelper } from "../../../reusableComponents/HelperTexts/SearchHelper";

function ManageTriggers(props) {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const name = "ManageTriggers";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  const { data, isFetching, refetch } = useGetTriggersQuery(query);
  const [updateTrigger] = useUpdateTriggerMutation();
  const [deleteTrigger] = useDeleteTriggerMutation();

  const handleTogglePublished = async (data) => {
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
      category: data.category?.id,
    };
    try {
      await updateTrigger({ id: data.id, ...updatedData });
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const ColorComponent = (props) => {
    return props?.data?.color ? (
      <div className="flex items-center p-no-margin">
        <span
          className="p-10"
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: `#${props?.data?.color}`,
            boxShadow: "0 0 10px rgb(0 0 0 / 20%)",
          }}
        ></span>
      </div>
    ) : (
      <></>
    );
  };

  const columns = useMemo(() => [
    {
      accessorKey: "color",
      accessorFn: (row) => row.color,
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => <ColorComponent data={cell.row.original} />,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.dashboard.widget.form.name"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="triggers"
        />
      ),
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.page.tracking.form.category"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "points",
      accessorFn: (row) => row.points,
      header: t("caymland.point.trigger.thead.points"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.points,
    },
  ]);

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/triggers/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "triggers",
    deleteItem: deleteTrigger,
    refetch: refetch,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "points/triggers",
    refetch: refetch,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.point.trigger.menu.index")} />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate="edit"
        isFetching={isFetching}
        searchHelperBody={<CompanySearchHelper />}
      />
    </Fragment>
  );
}

export default ManageTriggers;
