import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Button from "../../../../reusableComponents/Buttons/Button";
import html2canvas from "html2canvas";
import { t } from "i18next";

function ThemeCards({ selectedTheme, onThemeChange, themesArray }) {
  const [themeImages, setThemeImages] = useState({});

  useEffect(() => {
    themesArray.forEach((theme) => {
      if (!theme.thumbnail && theme.html && !themeImages[theme.id]) {
        const element = document.createElement("div");
        element.innerHTML = theme.html;
        element.style.width = "2000px"; // Set a suitable width for rendering
        element.style.position = "absolute";
        element.style.top = "-9999px";
        document.body.appendChild(element);

        html2canvas(element, { allowTaint: true, useCORS: true }).then((canvas) => {
          const dataUrl = canvas.toDataURL("image/png");
          setThemeImages((prevImages) => ({
            ...prevImages,
            [theme.id]: dataUrl,
          }));
          document.body.removeChild(element); // Clean up the element
        });
      }
    });
  }, [themesArray]);

  return (
    <div className="card-grid">
      {themesArray
        .sort((a, b) => a.id - b.id)
        .map((theme, index) => (
          <div
            className={`custom-card ${selectedTheme === theme.id ? "selected" : ""}`}
            key={index}
            onClick={() => onThemeChange(theme)}
            style={{
              border: selectedTheme === theme.id ? "2px solid #135f95" : "none",
              cursor: "pointer",
            }}
          >
            <div className="custom-card-header">
              <span>{theme.name}</span>
              {theme.id > 0 && (
                <span style={{ marginBottom: "4px" }}>
                  <Button
                    height={"20px"}
                    buttons={[
                      {
                        name: false && "title",
                        icon: (
                          <Tooltip title="View Template" arrow>
                            <LaunchIcon />
                          </Tooltip>
                        ),
                        onClick: () => window.open(`/emails/edit/${theme.id}`, "_blank"),
                      },
                    ]}
                  />
                </span>
              )}
            </div>
            <div className="custom-card-body">
              {theme.thumbnail ? (
                <img
                  title={t("caymland.core.preview")}
                  className="theme-frame"
                  src={theme.thumbnail}
                  alt="Theme Preview"
                />
              ) : themeImages[theme.id] ? (
                <img
                  title={t("caymland.core.preview")}
                  className="theme-frame"
                  src={themeImages[theme.id]}
                  alt="Theme Preview"
                  crossOrigin="anonymous"
                />
              ) : (
                <span>
                  {t("caymland.core.slot.label.image") + " " + t("caymland.caymlandb2b.opportunity.error.notfound")}
                </span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

export default ThemeCards;
