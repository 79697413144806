import React, { useEffect, useRef, useCallback } from "react";
import debounce from "lodash.debounce";
import { Button } from "../../ui/button";
import { t } from "i18next";

import { useState } from "react";
import "animate.css";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Controller } from "react-hook-form";
import { Text, TextArea, Select } from "../../reusableComponents/Inputs";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetUsersQuery } from "../../../../src/redux/api/usersApi";
import { tempApi, useGetTempsQuery, useRenderTempMutation } from "../../../../src/redux/api/tempApi";
import { classificationApi } from "../../../../src/redux/api";
import { contactsApi } from "../../../../src/redux/api/contactsApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Success, Error } from "../../reusableComponents/Alerts";
import { useComposeMessageMutation } from "../../../../src/redux/api/mailboxApi";
import CKEditor4 from "./CKEditor4";

const Compose = ({ initialData, setActiveTab, closeTab, activeTab }) => {
  const token = useSelector((state) => state?.auth?.token);
  const [contacts, setContacts] = useState([]);
  const editorRef = useRef(null);
  const [attachments, setAttachments] = useState({});
  const [selectedTemp, setSelectedTemp] = useState();
  const [render, setRender] = useState(false);
  const [isEditorReady, setIsEditorReady] = useState(false);

  const [query, setQuery] = useState({
    pageSize: null,
    where: [
      {
        expr: "eq",
        col: "language",
        val: "de",
      },
    ],
  });

  const curentUser = useSelector((state) => state?.auth.user);
  const locale = useSelector((state) => state?.settings?.data?.locales);

  const { data: tempData } = useGetTempsQuery(query);
  const { data } = useGetUsersQuery({ limit: 1000 });
  const [triggerTempsQuery] = tempApi.useLazyGetTempsQuery();
  const [triggerClassificationsQuery] = classificationApi.useLazyGetClassificationsQuery();
  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();
  const [composeMessage] = useComposeMessageMutation();
  const [renderTemp] = useRenderTempMutation();

  const formSchema = yup.object({
    from: yup.mixed().required("From is required"),
    language: yup.mixed().required(),
    subject: yup.mixed().required(),
    to: yup.array().min(1, "At least one recipient is required").required("To field is required"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const toValue = watch("to");
  const ccValue = watch("cc");
  const languageData = watch("language");
  const mailValue = watch("to");

  useEffect(() => {
    const fetchTemplateData = async () => {
      if (initialData) {
        setValue("to", initialData.to ? [{ label: initialData.to, value: initialData.to }] : []);
        setValue("cc", initialData.cc ? [{ label: initialData.cc, value: initialData.cc }] : []);
        setValue("subject", initialData.subject || "");

        let formattedBody = initialData.body || "";
        if (initialData.subject) {
          try {
            const response = await triggerTempsQuery({
              pageSize: null,
              where: [{ expr: "eq", col: "id", val: curentUser.template }],
            }).unwrap();
            let formattedBodyTemp = response?.data[0]?.content || "";
            formattedBodyTemp = await replaceUserFields(formattedBodyTemp);
            formattedBody = `<p><br/><br/></p>________________________________________________________________ ${formattedBodyTemp} <p><br/><br/></p> ${formattedBody}`;
          } catch (error) {
            console.error("Error fetching template:", error);
          }
        }
        setValue("body", formattedBody);
        setIsEditorReady(true);
      }
    };

    fetchTemplateData();
  }, [initialData, setValue, curentUser.template, triggerTempsQuery]);

  useEffect(() => {
    const matchingUser = data?.data.find((user) => user.email === curentUser.defaultAddress);

    if (matchingUser) {
      const formattedCurrentUser = {
        label: `${matchingUser.firstName} ${matchingUser.lastName} - <${matchingUser.email}>`,
        value: matchingUser.email,
      };
      setValue("from", formattedCurrentUser);
    } else {
      const formattedCurrentUser = {
        label: `${curentUser.firstName} ${curentUser.lastName} - <${curentUser.defaultAddress}>`,
        value: curentUser.email,
      };
      setValue("from", formattedCurrentUser);
    }

    const userLocale = curentUser.locale.split("_")[0];
    const languageOption = locale.find((lang) => lang.value === userLocale);

    if (languageOption) {
      setValue("language", { label: languageOption.label, value: languageOption.value });

      setQuery((prevQuery) => ({
        ...prevQuery,
        where: [
          {
            expr: "eq",
            col: "language",
            val: languageOption.value,
          },
        ],
      }));
    }
  }, [curentUser, locale, data]);

  useEffect(() => {
    if (languageData) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        where: [
          {
            expr: "eq",
            col: "language",
            val: languageData.value,
          },
        ],
      }));
    }
  }, [languageData]);

  const replaceUserFields = (template) => {
    template = template.replace(/\{program=\d+\},?/g, "");
    template = template.replace(/\{userfield=(\w+)\}/g, (match, p1) => curentUser[p1] || "");

    return template;
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      if (curentUser.template && !initialData) {
        const queryTemp = {
          pageSize: null,
          where: [
            {
              expr: "eq",
              col: "id",
              val: curentUser.template,
            },
          ],
        };
        const response = await triggerTempsQuery(queryTemp).unwrap();

        const spaceAndLine = "<p><br/><br/></p>________________________________________________________________";
        let formattedBody = response?.data[0]?.content || "";

        formattedBody = replaceUserFields(formattedBody);

        formattedBody = `${spaceAndLine}${formattedBody}`;
        setValue("body", formattedBody);
        setRender((prev) => !prev);
        setIsEditorReady(true);
      }
    };

    fetchTemplate();
  }, [curentUser.template, triggerTempsQuery, setValue, curentUser, initialData]);

  const preferredLanguages = [
    { value: "de", label: "German" },
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
    { value: "es", label: "Spanish" },
    { value: "nl", label: "Dutch" },
  ];

  const prioritizeLanguages = (options) => {
    const preferredOptions = preferredLanguages
      .map((lang) => options.find((option) => option.value === lang.value))
      .filter(Boolean);

    const otherOptions = options.filter((option) => !preferredLanguages.some((lang) => lang.value === option.value));

    otherOptions.sort((a, b) => a.label.localeCompare(b.label));

    if (otherOptions.length > 0) {
      preferredOptions.push({ value: "separator", label: "________________" });
    }

    return [...preferredOptions, ...otherOptions];
  };

  const sortedLocales = prioritizeLanguages([...locale]);

  const handleAddEditProduct = async (formData) => {
    const payload = {
      mailbox_message: {
        from: formData.from ? formData.from.value : null,
        to: Array.isArray(formData.to) ? formData.to.map((item) => item.value) : null,
        cc: Array.isArray(formData.cc) ? formData.cc.map((item) => item.value) : null,
        bcc: Array.isArray(formData.bcc) ? formData.bcc.map((item) => item.value) : null,
        subject: formData.subject || "",
        body: formData.body || "",
        language: formData.language ? formData.language.value : null,
        classification: formData.classification ? formData.classification.id : null,
        template: null,
        attachments: attachments,
      },
    };

    const response = await composeMessage({ ...payload });

    if (response?.data?.status) {
      Success(`${t("caymland.webhook.label.success")} `).then((result) => {
        if (result.isConfirmed) {
          if (activeTab !== "inbox") {
            closeTab(activeTab);
            setActiveTab("inbox");
            reset({});
          }
        }
      });
    } else {
      Error(t("caymland.message.error"));
    }
  };

  const fetchContacts = async (inputValue) => {
    const response = await triggerContactsQuery({ search: inputValue });
    if (response.error) {
      return [];
    }
    return response?.data?.data?.map((contact) => ({
      label: `${contact?.fields?.all?.firstname} ${contact?.fields?.all?.lastname} <${contact?.fields?.all?.email}>`,
      value: contact?.fields?.all?.email,
    }));
  };

  const debouncedFetchContacts = useCallback(
    debounce(async (inputValue, callback) => {
      const newContacts = await fetchContacts(inputValue);
      callback(newContacts);
    }, 500),
    []
  );

  const handleInputChange = (event, newInputValue, reason) => {
    // if (reason === "input") {
    debouncedFetchContacts(event.target.value, setContacts);
    // }
  };

  const retrieveClassifications = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerClassificationsQuery) resolve([]);

      const queryClass = {
        pageSize: null,
        where: [
          {
            expr: "eq",
            col: "type",
            val: "outgoing",
          },
        ],
      };

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerClassificationsQuery(queryClass).unwrap();
      const data = response?.data;
      resolve(data);
    });

  const CustomPreview = ({ fileWithMeta, meta }) => {
    return (
      <div className="tw-w-full tw-border-collapse">
        <div className="tw-flex tw-border-b tw-border-gray-300">
          <div className="tw-flex-grow tw-p-2 tw-flex tw-items-center tw-justify-between">{meta.name}</div>
          <div className="tw-p-2">
            {meta.status === "done" ? (
              <span className="tw-text-green-500 tw-font-bold">
                {t("caymland.plugin.badge.generator.form.uploaded")}
              </span>
            ) : (
              meta.status
            )}
          </div>
          <div className="tw-p-2 tw-text-red-500 tw-cursor-pointer" onClick={fileWithMeta.remove}>
            {t("caymland.core.remove")}
          </div>
        </div>
      </div>
    );
  };

  const getUploadParams = async ({ file, meta }) => {
    const body = new FormData();
    body.append("attachments[]", file);

    return {
      url: `${process.env.REACT_APP_DEV_URL}/api/mailbox/messages/upload/attachments`,
      method: "POST",
      body,
      headers: { Authorization: `JWT ${token}` },
    };
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      try {
        let response = JSON.parse(xhr.response);
        if (response?.attachments) {
          setAttachments((prev) => ({ ...prev, ...response.attachments }));
        }
      } catch (e) {
        console.error("Error processing the file", e);
      }
    } else if (status === "removed") {
      setAttachments((prev) => {
        const updated = { ...prev };
        Object.keys(prev).forEach((key) => {
          if (key === file.name) {
            delete updated[key];
          }
        });
        return updated;
      });
    }
  };

  const handleEditorInstance = (editor) => {
    editorRef.current = editor;
  };

  if (!isEditorReady) return null;

  const handleTemplateChange = async (e) => {
    try {
      const editor = editorRef.current;
      let contentWithUserData;

      const payload = {
        templateId: e.id,
        leadEmail: mailValue ? mailValue[0]?.value : "",
      };
      const response = await renderTemp(payload);
      contentWithUserData = replaceUserFields(response?.data?.body);

      editor.focus();
      editor.insertHtml(contentWithUserData, "unfiltered_html");
      setSelectedTemp(e);
      setValue("body", editor.getData());
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };

  return (
    <div className="tw-w-full flex flex-col tw-p-4" style={{ zoom: 0.8 }}>
      <div className="tw-flex tw-w-full tw-gap-5 tw-mb-5">
        <div className="tw-w-1/2 tw-h-auto">
          <Controller
            control={control}
            name="from"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name="status"
                label={t("caymland.mailbox.compose.label.from")}
                options={data?.data.map((user) => ({
                  label: `${user.firstName} ${user.lastName} - <${user.email}>`,
                  value: user.email,
                }))}
                required
                isClearable={false}
                value={value}
                onChange={onChange}
                errors={errors && errors[name]}
              />
            )}
          />

          <Controller
            control={control}
            name="to"
            render={({ field: { name, onChange, value }, fieldState: { error } }) => (
              <>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id={name}
                  key={toValue}
                  options={contacts}
                  getOptionLabel={(option) => option.label}
                  // open={open}
                  // onOpen={() => {
                  //   if (contacts.length > 0) {
                  //     setOpen(true);
                  //   }
                  // }}
                  // onClose={() => {
                  //   setOpen(false);
                  // }}

                  freeSolo
                  onInputChange={handleInputChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, onDelete, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          label={option.label || option}
                          key={key}
                          onDelete={() => {
                            onDelete();
                            const newValue = value.filter((v, i) => i !== index);
                            setValue(name, newValue); // Update the form field
                          }}
                          {...tagProps}
                          variant="outlined"
                          size="small"
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("caymland.mailbox.compose.label.to")}
                      placeholder={t("caymland.mailbox.compose.label.email.add.placeholder")}
                      fullWidth
                      error={!!error}
                      InputProps={{
                        ...params.InputProps,
                        style: { height: "auto", padding: "0 14px", alignItems: "center", marginBottom: "20px" },
                      }}
                      InputLabelProps={{
                        style: { top: "-8px", color: "#6C757D" },
                      }}
                    />
                  )}
                  onChange={(event, newValue, reason) => {
                    const formattedValue = newValue.map((item) => {
                      if (typeof item === "string") {
                        return { label: item, value: item };
                      } else {
                        return item;
                      }
                    });
                    onChange(formattedValue);
                  }}
                  value={value}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="cc"
            render={({ field: { name, onChange, value }, fieldState: { error } }) => (
              <>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id={name}
                  key={ccValue}
                  options={contacts}
                  getOptionLabel={(option) => option.label}
                  freeSolo
                  onInputChange={handleInputChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip label={option.label || option} key={key} {...tagProps} variant="outlined" size="small" />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("caymland.mailbox.compose.label.cc")}
                      placeholder={t("caymland.mailbox.compose.label.email.add.placeholder")}
                      fullWidth
                      error={!!error}
                      InputProps={{
                        ...params.InputProps,
                        style: { height: "auto", padding: "0 14px", alignItems: "center", marginBottom: "20px" },
                      }}
                      InputLabelProps={{
                        style: { top: "-8px", color: "#6C757D" },
                      }}
                    />
                  )}
                  onChange={(event, newValue, reason) => {
                    const formattedValue = newValue.map((item) => {
                      if (typeof item === "string") {
                        return { label: item, value: item };
                      } else {
                        return item;
                      }
                    });
                    onChange(formattedValue);
                  }}
                  value={value}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="bcc"
            render={({ field: { name, onChange, value }, fieldState: { error } }) => (
              <>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id={name}
                  options={contacts}
                  getOptionLabel={(option) => option.label}
                  freeSolo
                  onInputChange={handleInputChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip label={option.label || option} key={key} {...tagProps} variant="outlined" size="small" />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("caymland.mailbox.compose.label.bcc")}
                      placeholder={t("caymland.mailbox.compose.label.email.add.placeholder")}
                      fullWidth
                      error={!!error}
                      InputProps={{
                        ...params.InputProps,
                        style: { height: "auto", padding: "0 14px", alignItems: "center" },
                      }}
                      InputLabelProps={{
                        style: { top: "-8px", color: "#6C757D" },
                      }}
                    />
                  )}
                  onChange={(event, newValue, reason) => {
                    const formattedValue = newValue.map((item) => {
                      if (typeof item === "string") {
                        return { label: item, value: item };
                      } else {
                        return item;
                      }
                    });
                    onChange(formattedValue);
                  }}
                  value={value}
                />
              </>
            )}
          />
        </div>

        <div className="tw-w-1/2">
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            PreviewComponent={CustomPreview}
            inputContent={"Dateien hier ablegen"}
            styles={{
              dropzone: { width: "100%", height: "100%", border: "none" },
              dropzoneActive: { borderColor: "green" },
            }}
          />
        </div>
      </div>

      <div className="tw-flex tw-w-full tw-gap-5 tw-mb-5">
        <div className="tw-w-1/2">
          <Controller
            control={control}
            name="subject"
            render={({ field: { name, onChange, value } }) => (
              <Text
                label={t("caymland.mailbox.compose.label.subject")}
                placeholder={t("caymland.mailbox.compose.label.subject")}
                name={name}
                labelHidden
                type="text"
                value={value}
                onChange={onChange}
                errors={errors && errors[name]}
              />
            )}
          />
          <Controller
            control={control}
            name="language"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={t("caymland.core.language")}
                options={sortedLocales}
                required
                isClearable={false}
                value={value}
                onChange={(event) => {
                  if (event.value === "separator") {
                    return;
                  }
                  onChange(event);
                }}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
        <div className="tw-w-1/2">
          <Controller
            control={control}
            name="classification"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={t("caymland.mailbox.compose.label.classification")}
                options={retrieveClassifications}
                isClearable={true}
                labelField="name"
                valueField="id"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {/* <Controller
            control={control}
            name="template"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={t("caymland.mailbox.compose.label.template")}
                labelField="name"
                valueField="id"
                options={tempData?.data || []}
                isClearable={false}
                value={value || selectedTemp}
                // onChange={(e) => {
                //   if (editorRef.current && e?.content) {
                //     const editor = editorRef.current;
                //     const currentData = editor.getData();
                //     const insertPosition = editor.model.document.selection.getFirstPosition();

                //     editor.model.change((writer) => {
                //       const viewFragment = editor.data.processor.toView(e.content);
                //       const modelFragment = editor.data.toModel(viewFragment);
                //       writer.insert(modelFragment, insertPosition);
                //     });

                //     const contentWithUserData = replaceUserFields(editor.getData());

                //     setSelectedTemp(e.content);
                //     setValue("body", contentWithUserData);
                //   }
                // }}

                onChange={(e) => {
                  if (editorRef.current && e?.content) {
                    const editor = editorRef.current;
                    const contentWithUserData = replaceUserFields(e.content);
                    console.log("contentWithUserData", contentWithUserData)

                    // editor.model.change((writer) => {
                    //   const selection = editor.model.document.selection;
                    //   let insertPosition = selection.getFirstPosition();
                    //   console.log("insertPosition", insertPosition);

                    //   const root = editor.model.document.getRoot();

                    //   if (!insertPosition || !root.hasChildren) {
                    //     insertPosition = writer.createPositionAt(root, 0);
                    //   } else if (insertPosition.isAfter(root.end)) {
                    //     insertPosition = writer.createPositionAt(root, "end");
                    //   }

                    //   const viewFragment = editor.data.processor.toView(contentWithUserData);
                    //   const modelFragment = editor.data.toModel(viewFragment);

                    //   writer.insert(modelFragment, insertPosition);

                    //   try {
                    //     const endPosition = writer.createPositionAfter(modelFragment.getLastChild() || modelFragment);
                    //     writer.setSelection(endPosition);
                    //   } catch (error) {
                    //     writer.setSelection(modelFragment, "on");
                    //   }
                    // });

                    setSelectedTemp(contentWithUserData);
                    setValue("body", contentWithUserData);

                    // editor.editing.view.focus();
                  }
                }}
              />
            )}
          /> */}
          <Controller
            control={control}
            name="template"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={t("caymland.mailbox.compose.label.template")}
                labelField="name"
                valueField="id"
                options={tempData?.data || []}
                isClearable={false}
                value={value || selectedTemp}
                onChange={handleTemplateChange}
              />
            )}
          />
        </div>
      </div>

      {/* <div style={{ flex: 1, overflow: "auto", minHeight: 0 }}>
        <Controller
          control={control}
          name="body"
          render={({ field: { name, onChange, value } }) => (
            <TextArea
              name={name}
              value={value}
              onChange={onChange}
              onEditorReady={handleEditorInstance}
              height="50vh"
              showSmallEditor={false}
            />
          )}
        />

      </div> */}

      <div style={{ flex: 1, overflow: "auto", minHeight: 0 }} key={render}>
        <Controller
          control={control}
          key={render}
          name="body"
          render={({ field: { name, onChange, value } }) => (
            <CKEditor4
              value={value}
              onChange={onChange}
              name={name}
              onEditorReady={handleEditorInstance}
              render={render}
            />
          )}
        />
      </div>

      <div className="tw-flex tw-items-center">
        <Button
          variant="outline"
          className="tw-ml-auto tw-mt-3"
          style={{ zoom: 1.3 }}
          onClick={handleSubmit((data) => handleAddEditProduct(data))}
        >
          <ForwardToInboxIcon
            sx={{
              fontSize: "16px",
            }}
            className="tw-mr-2  tw-text-[#135f95]"
          />
          <span className="tw-text-[#135f95]">{t("caymland.mailbox.compose.label.send")}</span>
        </Button>
      </div>
    </div>
  );
};

export default Compose;
