import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import Loading from "../../../reusableComponents/loading";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import {
  useAddFolderFormsMutation,
  useDeleteFolderFormsMutation,
  useDeleteFormMutation,
  useGetFolderFormsQuery,
  useGetFormsQuery,
  useRemoveFormsToFoldersMutation,
  useUpdateFolderFormsMutation,
  useUpdateFormsMutation,
  useUpdateFormsPatchMutation,
  useUpdateFormsToFoldersMutation,
} from "../../../../redux/api/formsApi";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { t } from "i18next";
import { FormSearchHelper } from "../../../reusableComponents/HelperTexts/SearchHelper";

function Forms(props) {
  const navigate = useNavigate();
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const [localLoading, setLocalLoading] = useState(-1);
  const name = "Forms";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem("orderByForms") ? sessionStorage.getItem("orderByForms") : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });
  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "forms",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "forms",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });
  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { isPublished: !data.isPublished };
    await updateFormsPatch({ id: data.id, ...updatedData });
    await refetchTableData();
    setLocalLoading(-1);
  };
  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.lead.field.title"),
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="forms"
          view={true}
        />
      ),
      enableColumnFilters: false,
    },
    {
      accessorKey: "category",
      accessorFn: (row) => (row.category?.title ? row.category?.title : "Uncategorized"),
      header: `${t("caymland.lead.lead.category")}`,

      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "results",
      header: t("caymland.form.form.results"),
      enableColumnFilters: false,
      Cell: ({ cell }) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation(),
                navigate(`/forms/results/${cell?.row?.original?.id}`, {
                  state: { search: `form:${cell?.row?.original?.name}` },
                });
            }}
            className="result-component"
          >
            {cell?.row?.original?.submissionCount
              ? `${t("caymland.core.permissions.view")} ${cell?.row?.original?.submissionCount} ${
                  cell?.row?.original?.submissionCount > 1 ? `${t("caymland.form.form.results")}` : "Result"
                }`
              : t("caymland.core.noresults.header")}
          </div>
        );
      },
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "name",
      header: `${t("caymland.mailbox.rules.folder")} ${t("caymland.lottery.table.row.name")}`,
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);
  const body = [<CampaignForm control={control} />];

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/forms/new"),
    },
  ];

  // const successMessage = "Campaign updated successfully!";
  // const failMessage = "There was an error updating the campaign!";
  // const showMessage = showAlert
  //   ? alertMessage
  //   : success
  //   ? successMessage
  //   : fail
  //   ? failMessage
  //   : "";

  const { data: formsData, isFetching: isFetching, refetch: refetchTableData } = useGetFormsQuery(query);

  const { data: folderFormsData, refetch } = useGetFolderFormsQuery();

  const [updateForm] = useUpdateFormsMutation();
  const [updateFormsPatch] = useUpdateFormsPatchMutation();
  const [deleteForm] = useDeleteFormMutation();
  const [addFolder] = useAddFolderFormsMutation();
  const [updateFolder] = useUpdateFolderFormsMutation();
  const [deleteFolder] = useDeleteFolderFormsMutation();
  const [updateFormsToFolder] = useUpdateFormsToFoldersMutation();
  const [removeFormsFromFolder] = useRemoveFormsToFoldersMutation();

  const actionButtons = ActionButtons({
    link: "forms",
    deleteItem: deleteForm,
    refetch: refetchTableData,
    removeItemFromFolder: removeFormsFromFolder,
    previewForm: true,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
  });
  const actionBatchButtons = ActionBatchButtons({
    link: "forms",
    refetch: refetchTableData,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.form.forms")} />
      <DragAndDropTable
        data={formsData}
        folderData={folderFormsData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateFormsToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        searchHelperBody={<FormSearchHelper />}
      />
    </Fragment>
  );
}

export default Forms;
