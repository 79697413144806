import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { FaTrashAlt } from "react-icons/fa";
import { t } from "i18next";
// import { Tooltip, IconButton, Zoom } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MenuItemWithSubMenu from "./MenuItemWithSubMenu";
import { useUpdateFolderMailboxMutation } from "../../../redux/api/mailboxApi";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";
import { Button } from "../../ui/button";

const deleteAfterOptions = [
  { key: "Never" },
  { key: "2-Week" },
  { key: "1-Month" },
  { key: "2-Month" },
  { key: "3-Month" },
  { key: "6-Month" },
  { key: "1-Year" },
  { key: "2-Year" },
];

export default function AddEditDeleteFolder({
  handleCRUDFolder,
  node,
  setFolderName,
  setShowAlert,
  setAlertMessage,
  refetch,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [updateFolder] = useUpdateFolderMailboxMutation();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVisible = async () => {
    const data = { isVisible: false };
    try {
      const response = await updateFolder({ id: node.id, ...data });
      if (!response?.error) {
        setAlertMessage("Visible status of the folder changed.");
        setShowAlert(true);
        refetch();
      } else {
        setAlertMessage("Failed to change folder visibility due to server error.");
        setShowAlert(true);
      }
    } catch (e) {
      console.log(e);
      setAlertMessage("An error occurred while updating folder visibility.");
      setShowAlert(true);
    } finally {
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 7000);
    }
  };

  const handleSubMenuClick = async (option) => {
    const data = { autoClean: option === "Never" ? null : option.toLowerCase() };

    try {
      const response = await updateFolder({ id: node.id, ...data });
      console.log("response", response);
      if (!response?.error) {
        setAlertMessage(`The folder will be deleted after ${option}`);
        setShowAlert(true);
        refetch();
      } else {
        setAlertMessage("Failed to change folder visibility due to server error.");
        setShowAlert(true);
      }
    } catch (e) {
      console.log(e);
      setAlertMessage("An error occurred while updating folder visibility.");
      setShowAlert(true);
    } finally {
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 7000);
    }
  };

  const handleMenuItemClick = (option) => {
    if (option === t("caymland.core.permissions.edit")) {
      setFolderName(node.name);
      handleCRUDFolder(option, node);
    } else if (option === t("caymland.mailbox.list.action.visible")) {
      handleVisible();
    } else if (option === t("caymland.mailbox.list.action.deleteAfter")) {
      console.log("clicked");
    } else {
      setFolderName("");
      handleCRUDFolder(option, node);
      handleClose();
    }
  };

  const menuOptions = [t("caymland.core.add.folder")];

  if (node.parentId !== null) {
    menuOptions.push(t("caymland.core.permissions.edit"));
    menuOptions.push(t("caymland.core.permissions.delete"));
    menuOptions.push(t("caymland.mailbox.list.action.visible"));
    menuOptions.push(t("caymland.mailbox.list.action.deleteAfter"));
  }

  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-h-[30px] tw-w-[30px]">
      <Tooltip>
        <TooltipTrigger
          asChild
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Button variant="ghost" size="icon" className="hover:tw-bg-[#b01a1a21]">
            <MoreVertIcon style={{ color: "rgb(117, 117, 117)", fontSize: "20px" }} />
            <span className="sr-only">{t("caymland.core.tabs.more")}</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t("caymland.core.tabs.more")}</TooltipContent>
      </Tooltip>

      {/* <Tooltip
        arrow
        placement="right"
        TransitionComponent={Zoom}
        title={"Menu"}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <IconButton>
          <MoreVertIcon sx={{ opacity: 0.8 }} />
        </IconButton>
      </Tooltip> */}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper ul": {
            padding: 0,
          },
        }}
      >
        {menuOptions.map((option) =>
          option === t("caymland.mailbox.list.action.deleteAfter") ? (
            <MenuItemWithSubMenu
              key={option}
              className="tw-text-[12px]"
              data={{ key: option, subMenu: deleteAfterOptions }}
              handleSubMenuClick={handleSubMenuClick}
            />
          ) : (
            <MenuItem
              key={option}
              onClick={() => handleMenuItemClick(option)}
              style={{ color: "rgb(117, 117, 117)", fontSize: "13px", padding: "6px", display: "flex", gap: "3px" }}
            >
              {option === t("caymland.core.permissions.edit") ? (
                <EditIcon style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "12px" }} />
              ) : option === t("caymland.core.permissions.delete") ? (
                <FaTrashAlt style={{ color: "rgba(255, 0, 0, 0.7)", marginRight: "5px", fontSize: "12px" }} />
              ) : option === t("caymland.core.add.folder") ? (
                <AddCircleOutlineIcon style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "12px" }} />
              ) : option === t("caymland.mailbox.list.action.visible") ? (
                <VisibilityOffIcon style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "12px" }} />
              ) : null}
              {option}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}
