import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../reusableComponents/loading";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Contacts from "./viewCampaign/Contacts";
import Details from "./viewCampaign/Details";
import Sidebar from "./viewCampaign/Sidebar";
import CampaignStats from "./viewCampaign/CampaignStats";
import EventContent from "./viewCampaign/EventContent";
import Header from "../../reusableComponents/Header/Header";
import { FaRegTimesCircle, FaEdit, FaTrashAlt } from "react-icons/fa";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../reusableComponents/Accordion/Accordion";
import Swal from "sweetalert2";
import {
  campaignsApi,
  useDeleteCampaignMutation,
  useGetCampaignStatsMutation,
  useGetCampaignsContactsQuery,
  useGetRunCampaignsMutation,
} from "../../../redux/api/campaignsApi";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import Preview from "./viewCampaign/Preview";
import Description from "../../reusableComponents/Description/Description";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useRunnerCommandMutation } from "../../../redux/api/contactsApi";

function ViewCampaign() {
  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [campaign, setCampaign] = useState(null);
  const [recentActivity, setRecentActivity] = useState([]);
  const [campaignStats, setCampaignStats] = useState(null);
  const [event, setEvents] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const id = location?.state?.id || location?.state || useParams().id;
  const navigate = useNavigate();

  const [stats, { isFetching: applyLoading }] = useGetCampaignStatsMutation(id, { skip: !id });
  const [deleteCampaign, { isFetching: deleteLoading }] = useDeleteCampaignMutation();
  const [data, isLoading, isFetching] = campaignsApi.useLazyGetViewApiQuery();
  const [run, { isFetching: triggerLoading }] = useGetRunCampaignsMutation(id, { skip: !id });

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
  });

  const { data: contactData, refetch } = useGetCampaignsContactsQuery({ id, query });

  const changeDynamicTab = (index) => {
    setprimarycolorTab(index);
  };

  useEffect(() => {
    fetchCampaign();
    getStats();
  }, [id]);

  const handleDateChange = async () => {
    getStats();
    fetchCampaign();
  };

  const fetchCampaign = async () => {
    const viewData = await data(id);
    setEvents(viewData?.data?.events?.campaign[id]);
    setRecentActivity(viewData?.data?.recent);
    setCampaign(viewData?.data?.campaign?.campaign);
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const getStats = async () => {
    const fromDate = getFormattedDate(localStorage.getItem("fromDate"));
    const toDate = getFormattedDate(localStorage.getItem("toDate"));

    const _stats = await stats({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
    setCampaignStats(_stats?.data);
  };

  const navBack = () => {
    navigate("/campaigns");
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: campaign?.name })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteCampaign(id);
        if (!response?.error) {
          navigate("/campaigns", {
            state: { deletedCampaign: true, name: campaign?.name },
          });
        }
      }
    });
  };
  const [runnerCommand] = useRunnerCommandMutation();
  const handleTrigger = async () => {
    try {
      const commandBody = {
        command: ["caymland:campaigns:rebuild ", "caymland:campaigns:trigger"],
        arguments: {
          "campaign-id": campaign?.id,
        },
      };
      const response = await runnerCommand(commandBody);

      if (!response?.error) {
        setAlertMessage(
          `${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`
        );
        setShowAlert(true);
        setTimeout(() => {
          setAlertMessage("");
          setShowAlert(false);
          refetch();
        }, 5000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navBack(),
    },
    {
      name: "triggerCampaign",
      title: t("plugin.extendee.cron.tester.trigger.campaign"),
      icon: <PlayArrowIcon size={15} />,
      onClick: () => handleTrigger(),
    },
    {
      name: "edit",
      title: t("caymland.core.form.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/campaigns/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.core.form.delete"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} campaign={campaign} />,
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={campaign} />,
    },
  ];

  return (
    <Fragment>
      {isLoading?.isLoading ? (
        <HeaderSkeleton isView={true} viewBigButton={true} />
      ) : (
        <Header
          buttons={buttons}
          title={campaign?.name}
          loading={triggerLoading}
          showIsPublished={true}
          isPublished={campaign?.isPublished}
          data={campaign}
          alertMessage={alertMessage}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          isLoading?.isLoading ? (
            <DetailsSkeleton applyPadding={true} />
          ) : (
            <>
              <Card style={{ boxShadow: "unset", height: "auto" }}>
                <CardBody style={{ padding: "0" }}>
                  <CampaignStats
                    campaignStats={campaignStats}
                    handleDateChange={handleDateChange}
                    loading={applyLoading}
                  />
                </CardBody>
              </Card>
              <Card style={{ boxShadow: "unset", height: "auto" }}>
                <CardBody style={{ padding: "0" }}>
                  <Nav className="nav-primary w-full" tabs>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 1 ? "active" : ""}
                        onClick={() => changeDynamicTab(1)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.core.preview")}
                      </NavLink>
                    </NavItem>
                    {event?.decision?.length && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 2 ? "active" : ""}
                          onClick={() => changeDynamicTab(2)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.campaign.event.decisions.header")}
                        </NavLink>
                      </NavItem>
                    )}

                    {event?.action?.length && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 3 ? "active" : ""}
                          onClick={() => changeDynamicTab(3)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.campaign.event.actions.header")}
                        </NavLink>
                      </NavItem>
                    )}
                    {event?.condition?.length && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 4 ? "active" : ""}
                          onClick={() => changeDynamicTab(4)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.campaign.event.conditions.header")}
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 5 ? "active" : ""}
                        onClick={() => changeDynamicTab(5)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.lead.leads")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={primarycolorTab}>
                    <TabPane tabId={1}>
                      <Preview data={campaign} />
                    </TabPane>
                    <TabPane tabId={2}>
                      <EventContent data={event?.decision} />
                    </TabPane>
                    <TabPane tabId={3}>
                      <EventContent data={event?.action} type="action" />
                    </TabPane>
                    <TabPane tabId={4}>
                      <EventContent data={event?.condition} />
                    </TabPane>
                    <TabPane tabId={5}>
                      <Contacts contactData={contactData} query={query} setQuery={setQuery} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwoNoMargin"}
        childrenTwo={
          isLoading?.isLoading ? (
            <SidebarSkeleton applyPadding={true} />
          ) : (
            <>
              <CustomizedAccordions accordions={accordions} />
              {campaign && campaign?.description ? (
                <CustomizedAccordions accordions={accordionsDesc} isOpen={true} />
              ) : null}

              <Sidebar campaign={campaign} recentActivity={recentActivity} />
            </>
          )
        }
      />
    </Fragment>
  );
}

export default ViewCampaign;
