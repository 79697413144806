import React, { Fragment, useState, useEffect, useCallback } from "react";
import { languages } from "../../../../reusableComponents/data";
import { useParams } from "react-router-dom";
import { Text, Select, Checkbox } from "../../../../reusableComponents/Inputs";
import { dwcApi } from "../../../../../redux/api/dwcApi";
import { categoriesApi, useAddCategoryMutation } from "../../../../../redux/api/categoriesApi";
import { t } from "i18next";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";

function Sidebar({ modifiedData, setModifiedData, errors, setErrors, setPrimaryColorTab }) {
  let { id } = useParams();
  const currentId = id;

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "global",
  });
  const [newItem, setNewItem] = useState();
  const [translationOptions, setTranslationOptions] = useState([]);
  const [addCategory] = useAddCategoryMutation();
  const [triggerDwcQuery] = dwcApi.useLazyGetdwcQuery();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  // Update modifiedData with the new item when added
  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  // Function to retrieve DWC with language exclusion
  const retrieveDWC = useCallback(async () => {
    const query = {};

    const response = await triggerDwcQuery(query).unwrap();
    const data = response?.data;
    const groupList = [];

    // Filter to exclude items with the current language
    Object.values(data || {}).forEach((x) => {
      if (x.language == modifiedData.language) return;

      const index = groupList.findIndex((gl) => gl.label === x.language);
      if (index !== -1 && !x.variantChildren.includes(currentId) && x.id !== Number(currentId)) {
        groupList[index].options.push({ label: x.name, value: x.id });
      } else if (!x.variantChildren.includes(currentId) && x.id !== Number(currentId)) {
        groupList.push({
          label: x.language,
          options: [x],
        });
      }
    });

    setTranslationOptions(groupList);
  }, [triggerDwcQuery, modifiedData.language, currentId]);
  console.log(translationOptions);
  // Run retrieveDWC whenever the language changes
  useEffect(() => {
    setTranslationOptions([]); // Reset options immediately on language change
    retrieveDWC(); // Fetch new translations
  }, [modifiedData.language, retrieveDWC]);

  // Event Handlers
  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSelectChange = (e, name) => {
    setModifiedData((prev) => ({ ...prev, [name]: e || (name === "language" ? null : "") }));
  };

  const handleTranslationOfChange = (e) => {
    setModifiedData((prev) => ({ ...prev, translationParent: e || null }));
  };

  const handleUtmChange = (e) => {
    const { name, value } = e.target;
    setModifiedData((prev) => ({
      ...prev,
      utmTags: {
        ...prev.utmTags,
        [name]: value,
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "slotName" && !value.length) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
    setModifiedData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectCategory = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({ ...prev, isOpen: true }));
    } else {
      setModifiedData((prev) => ({ ...prev, category: selectedOption }));
    }
  };

  return (
    <Fragment>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Select
        name="category"
        label={t("caymland.page.tracking.form.category")}
        options={retrieveCategories}
        value={modifiedData?.category ?? null}
        onChange={handleSelectCategory}
        trigger={triggerCategories}
        isCreatable
        isClearable
      />
      <Select
        name="language"
        label={t("caymland.core.language")}
        options={languages.map((lang) => ({ label: t(lang.labelKey), value: lang.value }))}
        value={modifiedData?.language}
        onChange={(e) => handleSelectChange(e.value, "language")}
      />
      <Select
        name="translation"
        label={t("caymland.core.form.translation_parent")}
        valueField="id"
        labelField="name"
        options={translationOptions}
        value={modifiedData?.translationParent}
        onChange={handleTranslationOfChange}
        tooltip={t("caymland.core.form.translation_parent.help")}
      />
      <Checkbox
        name="isPublished"
        value={modifiedData?.isPublished}
        onChange={handleToggleChange}
        label={t("caymland.core.form.published")}
      />
      <Checkbox
        name="isCampaignBased"
        value={modifiedData?.isCampaignBased}
        onChange={(e) => {
          handleToggleChange(e);
          if (e.target.checked) setPrimaryColorTab(1);
        }}
        label={t("caymland.dwc.form.is_campaign_based")}
        tooltip={t("caymland.dwc.form.is_campaign_based.tooltip")}
      />
      {modifiedData?.isCampaignBased === false && (
        <div className="mt-20">
          <Text
            name="slotName"
            errors={errors?.slotName}
            value={modifiedData?.slotName}
            required
            onChange={handleInputChange}
            label={t("caymland.dynamicContent.send.slot_name")}
            tooltip={t("caymland.dynamicContent.send.slot_name.tooltip")}
          />
        </div>
      )}
      <div style={{ borderTop: "1px solid #d7d7d7", paddingTop: "20px", marginTop: "20px" }}>
        <h3 className="mb-20px" style={{ fontSize: "20px" }}>
          {t("caymland.email.utm_tags")}
        </h3>
        <Text
          name="utmSource"
          value={modifiedData?.utmTags?.utmSource}
          onChange={handleUtmChange}
          label={t("caymland.email.campaign_source")}
        />
        <Text
          name="utmMedium"
          value={modifiedData?.utmTags?.utmMedium}
          onChange={handleUtmChange}
          label={t("caymland.email.campaign_medium")}
        />
        <Text
          name="utmCampaign"
          value={modifiedData?.utmTags?.utmCampaign}
          onChange={handleUtmChange}
          label={t("caymland.email.campaign_name")}
        />
        <Text
          name="utmContent"
          value={modifiedData?.utmTags?.utmContent}
          onChange={handleUtmChange}
          label={t("caymland.email.campaign_content")}
        />
      </div>
    </Fragment>
  );
}

export default Sidebar;
