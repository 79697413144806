import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import {
  useAddFolderPagesMutation,
  useDeleteFolderPagesMutation,
  useDeletePageMutation,
  useGetFolderPagesQuery,
  useGetPagesQuery,
  useRemovePagesToFoldersMutation,
  useUpdateFolderPagesMutation,
  useUpdatePageMutation,
  useUpdatePagesToFoldersMutation,
} from "../../../../redux/api/pagesApi";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MRT_CustomRowsActionFolder } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { useTranslation } from "react-i18next";
import he from "he";
import { WebHookSearchHelper } from "../../../reusableComponents/HelperTexts/SearchHelper";

function LandingPages(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeRowId, setActiveRowId] = useState(null);
  const [editingFolder, setEditingFolder] = useState();
  const name = "LandingPages";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "pages",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "pages",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const { data: pagesData, isFetching: pagesIsFetching, refetch: refetchTableData } = useGetPagesQuery(query);
  const { data: folderPagesData, refetch } = useGetFolderPagesQuery();
  const [updatePages] = useUpdatePageMutation();
  const [deletePages] = useDeletePageMutation();
  const [addFolder] = useAddFolderPagesMutation();
  const [updateFolder] = useUpdateFolderPagesMutation();
  const [deleteFolder] = useDeleteFolderPagesMutation();
  const [updatePagesToFolder] = useUpdatePagesToFoldersMutation();
  const [removePagesFromFolder] = useRemovePagesToFoldersMutation();

  const handleTogglePublished = async (data) => {
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
    };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updatePages(updatedData);
    await refetchTableData();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "title",
      accessorFn: (row) => he.decode(row.title),
      header: t("caymland.core.title"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          handleTogglePublished={handleTogglePublished}
          link="pages"
          view={true}
        />
      ),
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.page.tracking.form.category"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "hits",
      accessorFn: (row) => row.hits,
      header: t("caymland.page.thead.hits"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.hits,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const body = [<CampaignForm control={control} />];

  const actionButtons = ActionButtons({
    link: "pages",
    removeItemFromFolder: removePagesFromFolder,
    deleteItem: deletePages,
    refetch: refetchTableData,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "pages",
    refetch: refetchTableData,
  });

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/pages/new"),
    },
  ];

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.page.page")} />
      <DragAndDropTable
        data={pagesData}
        folderData={folderPagesData}
        columns={columns}
        isFetching={pagesIsFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updatePagesToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        searchHelperBody={<WebHookSearchHelper />}
      />
    </Fragment>
  );
}

export default LandingPages;
