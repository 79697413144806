import React from "react";
import { Typography } from "@mui/material";

// Contact Search Helper
export const ContactSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators:</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <Typography variant="body2">
      <strong>Search commands</strong>
      <br />
      is:anonymous
      <br />
      is:unowned
      <br />
      is:mine
      <br />
      email:*
      <br />
      segment:&#123;segment_alias&#125;
      <br />
      name:*
      <br />
      company:*
      <br />
      owner:*
      <br />
      ip:*
      <br />
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      common:&#123;segment_alias&#125; + &#123;segment_alias&#125; + ...
      <br />
      tag:*
      <br />
      stage:*
      <br />
      email_sent:EMAIL_ID
      <br />
      email_read:EMAIL_ID
      <br />
      email_queued:EMAIL_ID
      <br />
      email_pending:EMAIL_ID
    </Typography>
  </div>
);

// Company Search Helper
export const CompanySearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators:</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <Typography variant="body2">
      <strong>Search commands</strong>
      <br />
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:published
      <br />
      is:unpublished
      <br />
      is:mine
      <br />
      is:uncategorized
      <br />
      category:&#123;category alias&#125;
    </Typography>
  </div>
);
export const UserSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:admin
      <br />
      is:active
      <br />
      is:inactive
      <br />
      email:*
      <br />
      name:*
      <br />
      position:*
      <br />
      role:*
      <br />
      username:*
    </Typography>
  </div>
);

export const WebHookSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:published
      <br />
      is:unpublished
      <br />
      is:mine
      <br />
      is:uncategorized
      <br />
      is:prefcenter
      <br />
      category:&#123;category alias&#125;
      <br />
      lang:&#123;lang code&#125;
    </Typography>
  </div>
);

export const SegmentSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:global
      <br />
      name:*
    </Typography>
  </div>
);

export const AssetSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:published
      <br />
      is:unpublished
      <br />
      is:mine
      <br />
      is:uncategorized
      <br />
      name:*
      <br />
      category:&#123;category alias&#125;
    </Typography>
  </div>
);
export const FormSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:published
      <br />
      is:unpublished
      <br />
      is:mine
      <br />
      is:uncategorized
      <br />
      name:*
      <br />
      has:results
      <br />
      category:&#123;category alias&#125;
    </Typography>
  </div>
);
export const SmsSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">ids:ID1,ID2 (comma separated IDs, no spaces)</Typography>
  </div>
);
export const ReportSearchHelper = () => (
  <div>
    <Typography variant="h6">Search Operators</Typography>
    <Typography variant="body2">
      <br />
      + (plus sign) - Search for the exact string (i.e. if admin, then administrator will not match)
      <br />
      ! (exclamation mark) - Not equals string
      <br />
      " " (double quotes) - Search by phrase
      <br />
      ( ) (parentheses) - Group expressions together.
      <br />
      OR - By default, the expressions will be joined as AND statements. Use the OR operator to change that.
      <br />% - Use the % as a wildcard to search for specific names or values in a phrase (i.e. to find all companies
      with the word ‘Technologies’ then type %technologies%)
    </Typography>
    <br />
    <Typography variant="h6">Search Commands</Typography>
    <Typography variant="body2">
      {" "}
      ids:ID1,ID2 (comma separated IDs, no spaces)
      <br />
      is:published
      <br />
      is:unpublished
      <br />
      is:mine
      <br />
    </Typography>
  </div>
);
