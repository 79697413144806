import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Select, DatePicker, ButtonGroup, TimePicker, LookupInput } from "../../reusableComponents/Inputs";
import moment from "moment";
import CountriesSelect from "../../reusableComponents/ReusableSelects/Countries";
import LocalesSelect from "../../reusableComponents/ReusableSelects/Locales";
import RegionsSelect from "../../reusableComponents/ReusableSelects/Regions";
import TimezoneSelect from "../../reusableComponents/ReusableSelects/Timezone";
import { t } from "i18next";

const FieldsForms = ({ field, control }) => {
  const yesLabel = field?.properties?.yes || t("caymland.core.form.yes");
  const noLabel = field?.properties?.no || t("caymland.core.form.no");
  const toggleButtons = [
    { value: 3, label: "X" },
    { value: 0, label: noLabel },
    { value: 1, label: yesLabel },
  ];
  let content;
  let options = [];

  switch (field.type) {
    case "text":
    case "email":
    case "number":
    case "tel":
    case "url":
      content = (
        <>
          <div key={field.id} className="w-full">
            <Controller
              control={control}
              name={field.alias}
              render={({ field: { name, onChange, value, onBlur } }) => {
                return (
                  <input
                    {...field}
                    style={{
                      background: "inherit",
                      border: "0",
                      width: "100%",
                      height: "28px",
                      outline: "none",
                      padding: "4px",
                    }}
                    onBlur={onBlur}
                    name={name}
                    type={field.type}
                    value={value}
                    onChange={onChange}
                    required={field.isRequired}
                  />
                );
              }}
            />
          </div>
        </>
      );
      break;

    case "boolean":
      // const defaultBooleanValue = field?.defaultValue == 1 ? 1 : field?.defaultValue == 0 ? 0 : 3;
      const defaultBooleanValue = fieldValue === 1 ? 1 : fieldValue === 0 ? 0 : 3;
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Convert null to 3 for display purposes
              const displayValue = value === "" ? 3 : value;

              return (
                <div className="d-flex gap-5px">
                  <ButtonGroup
                    margin
                    viewEdit
                    buttons={toggleButtons}
                    defaultValue={defaultBooleanValue}
                    value={displayValue} // Use displayValue for rendering
                    onChange={(event) => {
                      let newValue = parseInt(event.target.value, 10);
                      // Convert 3 back to null for submission
                      newValue = newValue === 3 ? "" : newValue;
                      onChange(newValue);
                    }}
                  />
                  {<span style={{ color: "red", marginRight: "10px" }}>{field.isRequired ? "*" : ""}</span>}
                </div>
              );
            }}
          />
        </div>
      );
      break;

    case "date":
    case "datetime":
      content = (
        <div key={field.id} className={`w-full`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <DatePicker
                // label={field.label}
                viewEdit
                marginBottom={false}
                required={field.isRequired}
                name={name}
                isClearable={false}
                value={value}
                showTimeSelect={field.type === "datetime" ? true : false}
                onChange={(date) => {
                  // Format the date to "YYYY-MM-DD HH:mm:ss"
                  const formattedDate =
                    field.type === "datetime"
                      ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                      : moment(date).format("YYYY-MM-DD");
                  onChange(formattedDate); // Use the formatted date
                }}
              />
            )}
          />
        </div>
      );
      break;

    case "country":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <CountriesSelect name={name} onChange={onChange} value={value} required={field.isRequired} viewEdit />
            )}
          />
        </div>
      );
      break;
    case "locale":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <LocalesSelect name={name} onChange={onChange} value={value} required={field.isRequired} viewEdit />
            )}
          />
        </div>
      );
      break;
    case "region":
      content = (
        <div key={field.id} className={`w-full mt-20`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <RegionsSelect name={name} onChange={onChange} value={value} required={field.isRequired} viewEdit />
            )}
          />
        </div>
      );

      break;

    case "lookup":
      content = (
        <div key={field.id} className="w-full">
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value, onBlur } }) => (
              <>
                <LookupInput
                  field={field}
                  control={control}
                  value={value}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                />
              </>
            )}
          />
        </div>
      );
      break;

    case "select":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      } else if (typeof field?.properties?.list === "object") {
        options = Object.values(field.properties.list).map((i) => ({ label: i.label, value: i.value }));
      }

      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                margin
                // label={field.label}
                viewEdit={true}
                isRequired={field.isRequired}
                isMulti={field.type === "multiselect" ? true : false}
                options={options}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value}
              />
            )}
          />
        </div>
      );
      break;
    case "multiselect":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`w-full mt-20 `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              const selectedOptions = options.filter((option) => value?.includes(option.value));
              return (
                <Select
                  name={name}
                  extraStyle
                  viewEdit={true}
                  isRequired={field.isRequired}
                  isMulti={field.type === "multiselect"}
                  options={options}
                  value={selectedOptions} // Set the selected options as the value
                  onChange={(selectedOptions) => {
                    // Transform the selected options into an array of their values
                    const newValue = selectedOptions.map((option) => option.value);

                    // Call the original onChange method with the new array of values
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
        </div>
      );
      break;

    case "textarea":
      content = (
        <div key={field.id} className=" w-full small-editor">
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <input
                style={{ background: "inherit", border: "0", width: "100%", height: "28px", outline: "none" }}
                name={name}
                required={field.isRequired}
                data={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "time":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimePicker
                // label={name}
                name={field.alias}
                marginBottom={false}
                viewEdit={true}
                value={value}
                required={field.isRequired}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "timezone":
      content = (
        <div key={field.id} className={`w-full`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimezoneSelect
                name={name}
                viewEdit
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value ? { label: value, value } : null}
                required={field.isRequired}
              />
            )}
          />
        </div>
      );
      break;

    default:
      content = (
        <div className="pb-0 mb-0">
          <p>
            {t("caymland.core.needs.done")} ({field.type})
          </p>
        </div>
      );
  }
  return content;
};

export default FieldsForms;
