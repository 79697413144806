import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import {
  useAddFolderStagesMutation,
  useDeleteFolderStagesMutation,
  useDeleteStageMutation,
  useGetFolderStagesQuery,
  useGetStagesQuery,
  useRemoveStagesToFoldersMutation,
  useUpdateFolderStagesMutation,
  useUpdateStageMutation,
  useUpdateStagesToFoldersMutation,
} from "../../../redux/api/stagesApi";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { CategoryComponent } from "../../reusableComponents/tableComponents/CategoryComponent";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { t } from "i18next";

function Stages() {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const [activeRowId, setActiveRowId] = useState(null);
  const [editingFolder, setEditingFolder] = useState();
  const name = "Stages";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "stages",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "stages",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const { data: stagesData, isFetching: stagesIsFetching, refetch: refetchTableData } = useGetStagesQuery(query);
  const { data: folderStagesData, refetch } = useGetFolderStagesQuery();
  const [deleteStage] = useDeleteStageMutation();
  const [updateStage] = useUpdateStageMutation();
  const [addFolder] = useAddFolderStagesMutation();
  const [updateFolder] = useUpdateFolderStagesMutation();
  const [deleteFolder] = useDeleteFolderStagesMutation();
  const [updateStagesToFolder] = useUpdateStagesToFoldersMutation();
  const [removeStagesFromFolder] = useRemoveStagesToFoldersMutation();

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
    };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateStage(updatedData);
    await refetchTableData();
    setLocalLoading(-1);
  };

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/stages/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "stages",
    removeItemFromFolder: removeStagesFromFolder,
    deleteItem: deleteStage,
    refetch: refetchTableData,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
  });

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.user.account.permissions.editname"),
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="stages"
        />
      ),
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.lead.lead.category"),
      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const body = [<CampaignForm control={control} />];

  const actionBatchButtons = ActionBatchButtons({
    link: "stages",
    refetch: refetchTableData,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.stages.menu.root")} />
      <DragAndDropTable
        data={stagesData}
        folderData={folderStagesData}
        columns={columns}
        isFetching={stagesIsFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateStagesToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        linkNavigate="edit"
      />
    </Fragment>
  );
}

export default Stages;
