import { Badge } from "../../../components/ui/badge";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { formatDistanceToNowStrict } from "date-fns";
import Skeleton from "@mui/material/Skeleton";
import { cn } from "../../../lib/utils";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../../../components/ui/context-menu";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../components/ui/pagination";
import {
  ReplyOutlined as ReplyOutlinedIcon,
  ReplyAllOutlined as ReplyAllOutlinedIcon,
  ForwardToInboxOutlined as ForwardToInboxOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  ArchiveOutlined as ArchiveOutlinedIcon,
  MarkEmailUnreadOutlined as MarkEmailUnreadOutlinedIcon,
  MarkEmailReadOutlined as MarkEmailReadOutlinedIcon,
  MailLockOutlined as MailLockOutlinedIcon,
  LocalPrintshopOutlined as LocalPrintshopOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
} from "@mui/icons-material";
import { Checkbox } from "../../../components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { Separator } from "../../../components/ui/separator";
import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Paperclip, Lock, Files } from "lucide-react";
import {
  useArchiveMessageMutation,
  useDeleteMessageMutation,
  useLockMessageMutation,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
  useMessageReadMutation,
  useUnlockMessageMutation,
} from "../../../../src/redux/api/mailboxApi";
import PostalMime from "postal-mime";
import InnerHTML from "dangerously-set-html-content";
import Highlighter from "react-highlight-words";

export function MailList({
  items,
  sendReadRequest,
  setQuery,
  query,
  isFetching,
  parsedEmail,
  setMailIDs,
  mailIDs,
  setShow,
  setMessage,
  setBackgroundToast,
  addTab,
  handleDragStart,
  setLockStatus,
  searchTerm,
}) {
  const totalPages = Math.ceil((items?.total ?? 0) / query.pageSize);
  const { t } = useTranslation();
  const [mail, setMail] = useState();
  const [parseMail, setParseMail] = useState();

  // const containerRef = useRef(null);

  const [markAsRead] = useMarkAsReadMutation();
  const [markAsUnread] = useMarkAsUnreadMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [lockMessage] = useLockMessageMutation();
  const [unlockMessage] = useUnlockMessageMutation();
  const [archiveMessage] = useArchiveMessageMutation();
  const [messageRead] = useMessageReadMutation();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (containerRef.current && !containerRef.current.contains(event.target)) {
  //       setMailIDs([]);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [containerRef]);

  const handleMailAction = async (actionType, itemHash) => {
    let response = await messageRead(itemHash);
    let rawData = response?.data || (response?.error && response?.error?.data);

    if (rawData) {
      const parser = new PostalMime();
      const parsedEmailData = await parser.parse(rawData);

      let replyData = {
        subject: "",
        body: parsedEmailData?.html,
      };

      switch (actionType) {
        case "Reply":
          replyData.subject = `Re: ${parsedEmailData?.subject}`;
          replyData.to = parsedEmailData?.from?.address;
          break;
        case "Reply All":
          replyData.subject = `Re: ${parsedEmailData?.subject}`;
          replyData.to = parsedEmailData?.from?.address;
          replyData.cc = parsedEmailData?.cc?.map((person) => person.address).join("; ") ?? "";
          break;
        case "Forward":
          replyData.subject = `Fw: ${parsedEmailData?.subject}`;
          delete replyData.to;
          break;
        default:
          return;
      }

      addTab(actionType, replyData);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        pageIndex: newPage,
      }));
    }
  };

  const handleNext = () => {
    handlePageChange(query.pageIndex + 1);
  };

  const handlePrevious = () => {
    handlePageChange(query.pageIndex - 1);
  };

  const clickHandler = (event, item) => {
    event.stopPropagation();

    if (event.ctrlKey || event.metaKey) {
      setMailIDs((prev) => {
        if (prev.includes(item.id)) {
          return prev.filter((id) => id !== item.id);
        } else {
          return [...prev, item.id];
        }
      });
    } else {
      if (item.id === mail) return;
      const hash = item?.hash;
      sendReadRequest(hash, item);
      setMail(item.id);
    }
  };

  const handleCheckboxChange = (event, item) => {
    setMailIDs((prev) => {
      if (event) {
        return [...prev, item.id];
      } else {
        return prev.filter((id) => id !== item.id);
      }
    });
  };

  const singleMessageHandler = async (actionType, item) => {
    try {
      let response;
      if (actionType === "read") {
        response = await markAsRead({ ids: [item.id] });
      } else if (actionType === "unread") {
        response = await markAsUnread({ ids: [item.id] });
      } else if (actionType === "delete") {
        response = await deleteMessage({ ids: [item.id] });
      } else if (actionType === "lock") {
        response = await lockMessage({ ids: [item.id] });
        setLockStatus(true);
      } else if (actionType === "unlock") {
        response = await unlockMessage({ ids: [item.id] });
        setLockStatus(false);
      } else if (actionType === "archive") {
        response = await archiveMessage({ ids: [item.id] });
      } else if (actionType === "replay") {
        handleMailAction("Reply", item.hash);
        return;
      } else if (actionType === "replyAll") {
        handleMailAction("Reply All", item.hash);
        return;
      } else if (actionType === "forward") {
        handleMailAction("Forward", item.hash);
        return;
      }

      if (response && response.data) {
        setShow(true);
        setMessage(t("caymland.mailbox.slidebar"));
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 5000);
      } else {
        console.error("Failed to mark email:", response.error);
      }
    } catch (error) {
      console.error("Error in marking email:", error);
    }
  };

  function createEmlContent(mail) {
    let emlContent = "";

    mail.headers.forEach((header) => {
      emlContent += `${header.name}: ${header.value}\r\n`;
    });

    emlContent += `Subject: ${mail.subject}\r\n`;
    emlContent += `From: ${mail.from.address}\r\n`;
    emlContent += `To: ${mail.to.map((to) => to.address).join(", ")}\r\n`;
    emlContent += "\r\n";

    if (mail.text) {
      emlContent += mail.text + "\r\n\r\n";
    }
    if (mail.html) {
      emlContent += mail.html + "\r\n\r\n";
    }

    return emlContent;
  }

  function downloadEmlFile(parsedEmail) {
    const emlContent = createEmlContent(parsedEmail);
    const blob = new Blob([emlContent], { type: "message/rfc822" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `email-${new Date().getTime()}.eml`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const dragImageRef = useRef(null);

  const handleCustomDragStart = (e, item) => {
    document.body.style.cursor = "grabbing !important";
    e.target.style.cursor = "grabbing !important";
    if (!dragImageRef.current) {
      const dragImage = document.createElement("div");
      dragImage.style.position = "absolute";
      dragImage.style.top = "-100%";
      dragImage.style.left = "-100%";
      dragImage.style.backgroundColor = "#f4f4f4";
      dragImage.style.padding = "10px";
      dragImage.style.border = "1px solid #ccc";
      dragImage.style.borderRadius = "5px";
      if (mailIDs.length > 0) {
        dragImage.innerText = `Dragged ${mailIDs.length} elements`;
      } else if (item.subject) {
        dragImage.innerText = item.subject;
      } else {
        dragImage.innerText = "No Subject";
      }
      document.body.appendChild(dragImage);
      dragImageRef.current = dragImage;
    }

    e.dataTransfer.setDragImage(dragImageRef.current, 0, 0);

    handleDragStart && handleDragStart(e, item.id);
  };

  const handleDragEnd = (e) => {
    document.body.style.cursor = "auto !important";
    e.target.style.cursor = "grab !important";
    if (dragImageRef.current) {
      document.body.removeChild(dragImageRef.current);
      dragImageRef.current = null;
    }
  };

  return (
    <div className=" tw-w-full">
      <div
        className={`tw-flex tw-flex-col tw-gap-2  parent-select tw-overflow-y-auto  tw-pt-0 tw-h-[77vh] ${
          mailIDs.length === 0 ? "tw-pl-4 tw-pr-4" : "tw-px-4"
        }`}
      >
        {isFetching ? (
          <>
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="tw-border tw-w-full tw-p-3 tw-rounded-lg">
                <Skeleton width="40%" />
                <Skeleton width="60%" />
                <Skeleton width="80%" />
              </div>
            ))}
          </>
        ) : items?.data?.length ? (
          items.data.map((item) => {
            return (
              <div
                className="tw-flex"
                draggable
                // onDragEnd={(e) => e.dataTransfer.clearData()}
                // onDragStart={(e) => handleDragStart(e, item.id)}

                onDragStart={(e) => handleCustomDragStart(e, item)}
                onDragEnd={handleDragEnd}
              >
                <ContextMenu key={item.id}>
                  <CSSTransition in={mailIDs.length > 0} timeout={300} classNames="checkbox" unmountOnExit>
                    <Checkbox
                      className="tw-self-center tw-mr-1"
                      checked={mailIDs.includes(item.id)}
                      onCheckedChange={(checked) => handleCheckboxChange(checked, item)}
                    />
                  </CSSTransition>

                  <ContextMenuTrigger
                    className={cn(
                      "tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-2 tw-rounded-lg tw-border tw-p-3 tw-text-left tw-text-sm tw-transition-all hover:tw-bg-accent tw-cursor-pointer",
                      mail === item.id && "tw-bg-muted"
                    )}
                    onClick={(event) => clickHandler(event, item)}
                    onContextMenu={(event) => {
                      if (mailIDs.length > 0) {
                        event.preventDefault();
                      }
                      clickHandler(event, item);
                    }}
                  >
                    <div className="tw-flex tw-w-full tw-flex-col tw-gap-1 tw-overflow-hidden">
                      <div className="tw-flex tw-items-center">
                        <div className="tw-flex tw-items-center tw-gap-2 tw-w-[78%]">
                          <div className={` tw-text-[13px] ${!item.isRead ? "tw-font-bold " : "tw-font-normal"}`}>
                            <Highlighter
                              highlightClassName="highlight"
                              searchWords={[searchTerm]}
                              autoEscape={true}
                              textToHighlight={item.from}
                            />
                            {/* {item.from} */}
                          </div>
                          {/* {!item.isRead && <span className="tw-flex tw-h-2 tw-w-2 tw-rounded-full tw-bg-red-600" />} */}
                        </div>
                        <div
                          className={cn(
                            "tw-ml-auto tw-text-[11px] tw-w-1/5",
                            mail === item.id ? "tw-text-foreground" : "tw-text-muted-foreground"
                          )}
                        >
                          {formatDistanceToNowStrict(new Date(item.date), {
                            addSuffix: true,
                          })}
                        </div>
                      </div>
                      <div className={` ${!item.isRead ? "tw-font-bold " : "tw-font-normal"} tw-text-[11px]`}>
                        {/* {item.subject} */}
                        <Highlighter
                          highlightClassName="highlight"
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          textToHighlight={item.subject}
                        />
                      </div>

                      <div
                        className={` ${
                          !item.isRead ? "tw-font-semibold" : "tw-font-normal"
                        } tw-line-clamp-2 tw-text-xs tw-text-muted-foreground`}
                      >
                        {item?.textPlain && <InnerHTML html={`${item.textPlain.substring(0, 300)}...`} allowRerender />}
                        {/* {item.textPlain.substring(0, 300) + "..."} */}
                      </div>
                    </div>
                    <div
                      className={`tw-flex tw-items-center  tw-w-full tw-gap-2 ${
                        item?.classification ? "tw-justify-between" : "tw-justify-end"
                      }`}
                    >
                      {item?.classification && (
                        <Badge
                          key={item?.classification}
                          variant="customVariant"
                          className="tw-text-[11px]"
                          style={{ zoom: 0.8 }}
                        >
                          {item?.classification}
                        </Badge>
                      )}
                      <div className="tw-flex tw-gap-1">
                        {item?.lock && <Lock className=" tw-h-4 tw-w-4 tw-text-muted-foreground" />}
                        {item?.attach && <Paperclip className=" tw-h-4 tw-w-4 tw-text-muted-foreground" />}
                      </div>
                    </div>
                  </ContextMenuTrigger>

                  <ContextMenuContent>
                    {[
                      {
                        icon: ReplyOutlinedIcon,
                        textKey: "caymland.mailbox.message.replay",
                        action: "replay",
                      },
                      {
                        icon: ReplyAllOutlinedIcon,
                        textKey: "caymland.mailbox.message.replayAll",
                        action: "replyAll",
                      },
                      {
                        icon: ForwardToInboxOutlinedIcon,
                        textKey: "caymland.mailbox.message.forward",
                        action: "forward",
                      },
                      {
                        icon: FileDownloadOutlinedIcon,
                        textKey: "caymland.mailbox.list.action.download",
                        action: "download",
                      },
                      {
                        icon: ArchiveOutlinedIcon,
                        textKey: "caymland.mailbox.list.action.archiveWithoutAnswer",
                        action: "archive",
                      },
                      {
                        icon: MarkEmailUnreadOutlinedIcon,
                        textKey: "caymland.mailbox.list.action.setAsUnread",
                        action: "unread",
                      },
                      {
                        icon: MarkEmailReadOutlinedIcon,
                        textKey: "caymland.mailbox.list.action.setAsRead",
                        action: "read",
                      },
                      {
                        icon: DeleteOutlineOutlinedIcon,
                        textKey: "caymland.mailbox.list.action.deleteMessage",
                        action: "delete",
                      },
                      ...(item.lock
                        ? [
                            {
                              icon: LockOpenOutlinedIcon,
                              textKey: "caymland.mailbox.list.action.unlock",
                              action: "unlock",
                            },
                          ]
                        : [
                            {
                              icon: MailLockOutlinedIcon,
                              textKey: "caymland.mailbox.list.action.lockMessage",
                              action: "lock",
                            },
                          ]),
                    ].map(({ icon: Icon, textKey, action }) => (
                      <ContextMenuItem
                        key={textKey}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (action === "download") {
                            downloadEmlFile(parsedEmail);
                          } else {
                            singleMessageHandler(action, item);
                          }
                        }}
                      >
                        <Icon style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "15px" }} />
                        {t(textKey)}
                      </ContextMenuItem>
                    ))}
                  </ContextMenuContent>
                </ContextMenu>
              </div>
            );
          })
        ) : (
          <div className="tw-p-8 tw-text-[12px] tw-text-center tw-text-muted-foreground">
            {t("caymland.mailbox.toolbar.feedback")}
          </div>
        )}
      </div>
      <Separator />
      <Pagination className={"tw-mt-2.5 tw-max-w-[90%]"}>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              className="tw-text-[black]-300"
              onClick={handlePrevious}
              disabled={query.pageIndex === 0}
            />
          </PaginationItem>
          <PaginationItem>
            <span className="tw-font-medium ">{query.pageIndex * query.pageSize + 1}</span>-
            <span className="tw-font-medium">
              {Math.min((query.pageIndex + 1) * query.pageSize, items?.total ?? 0)}
            </span>{" "}
            <span className="tw-font-normal tw-text-xs">of</span>{" "}
            <span className="tw-font-medium">{items?.total ?? 0}</span>
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              className="tw-text-[black]-300"
              onClick={handleNext}
              disabled={query.pageIndex === totalPages - 1}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
}
