import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Error, Success } from "../../reusableComponents/Alerts";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import MainContent from "./mainContent";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useAddProductMutation, useGetProductQuery, useUpdateProductMutation } from "../../../redux/api/productsApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function AddEditProducts(state) {
  const navigate = useNavigate();
  const location = useLocation();
  const ClonePath = location.pathname.includes("clone");
  const id = location?.state?.id || location?.state || useParams().id;

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "plugin:Brochures",
  });

  const [newItem, setNewItem] = useState();
  const { data, isLoading } = useGetProductQuery(id, { skip: !id });
  const [addNewProduct] = useAddProductMutation();
  const [updateProduct] = useUpdateProductMutation(id);

  const token = useSelector((state) => state?.auth?.token);

  const clientSchema = yup.object({
    name: yup.string().required(),
    weight: yup.string().required(),
    language: yup.mixed().required(),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(clientSchema),
    defaultValues: {
      language: "de", // Set default value here
    },
  });

  useEffect(() => {
    if (id && data?.brochureproduct && data?.brochureproduct?.preview) {
      const { brochureproduct } = data;
      setValue("preview", brochureproduct.preview);
    }
  }, [id, data?.brochureproduct, setValue]);

  useEffect(() => {
    if (id && data?.brochureproduct) {
      const { brochureproduct } = data;

      let productData = {
        ...brochureproduct,
        category: brochureproduct?.category
          ? {
              label: brochureproduct.category.title,
              value: brochureproduct.category.id,
            }
          : null,
        isPublished: ClonePath ? false : brochureproduct.isPublished,
      };

      reset(productData);
    }
  }, [id, data?.brochureproduct, ClonePath, reset]);

  const handleCancel = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/products");
      } else {
        return;
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleAddEditProduct(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleAddEditProduct(data)),
    },
  ];

  useEffect(() => {
    if (newItem) {
      setValue("category", {
        label: newItem.label,
        value: newItem.value,
      });
    }
  }, [newItem, setValue]);

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;

  const getUploadParams = async ({ file, meta }) => {
    const body = new FormData();
    if (file.type.startsWith("image/")) {
      body.append("brochures_products[]", file);

      return {
        url: `${api}/api/brochures/products/upload`,
        method: "POST",
        body,
        headers: { Authorization: `JWT ${token}` },
      };
    } else {
      setShowAlert(true);
      setAlertMessage("Attempted to upload a non-image file:", file.type);
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return null;
    }
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    console.log(meta);
    if (!meta.type.startsWith("image/")) {
      setShowAlert(true);
      setAlertMessage(`Attempted to upload an unsupported file type: ${file?.type}`);
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 7000);
      return;
    }
    if (status === "done") {
      try {
        let response = JSON.parse(xhr.response);
        console.log(response);
        if (response?.brochureproduct?.name) {
          setValue("previewBlob", meta?.previewUrl);
          setValue("preview", response?.brochureproduct?.name);
        }
      } catch (e) {
        console.error("Error parsing response or setting preview:", e);
      }
    } else if (status === "removed") {
      setValue("preview", null);
    }
  };

  const handleAddEditProduct = async (formData, close = false) => {
    const payload = {
      ...formData,
      category:
        formData.category && typeof formData.category === "object" ? formData.category.value : formData.category,
      asset: formData.asset && typeof formData.asset === "object" ? formData.asset.id : formData.asset,
      language:
        formData.language && typeof formData.language === "object" ? formData.language.value : formData.language,
      preview:
        formData.preview && formData.preview.startsWith("blob:")
          ? formData.preview.split("/").pop() // Extract UUID from blob URL
          : formData.preview && /\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i.test(formData.preview)
          ? formData.preview.match(/[^/]+$/)[0] // Extract filename if it matches allowed extensions
          : formData.preview, // Otherwise, use the full preview URL as-is
    };

    try {
      const response =
        formData.id && !formData.ClonePath
          ? await updateProduct({ id: formData.id, ...payload })
          : await addNewProduct(payload);

      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: formData.id && !formData.ClonePath ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed) {
            if (close) {
              navigate(`/products`);
            } else {
              navigate(`/products/edit/${response?.data?.brochureproduct?.id}`);
            }
          }
        });
      } else {
        Error(t("caymland.message.error"));
      }
    } catch (e) {
      Error(t("caymland.message.error"));
    }
  };

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isLoading}
          alertMessage={alertMessage}
          showAlert={showAlert}
          title={!ClonePath && id ? "Edit Product" : t("caymland.brochures.products.add")}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={<MainContent control={control} isFetching={isLoading} errors={errors} />}
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          <Sidebar
            isFetching={isLoading}
            getUploadParams={getUploadParams}
            handleChangeStatus={handleChangeStatus}
            modalOptions={modalOptions}
            setModalOptions={setModalOptions}
            setNewItem={setNewItem}
            control={control}
            errors={errors}
            watch={watch}
          />
        }
      />
    </>
  );
}

export default AddEditProducts;
