import React from "react";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import { Checkbox, Select } from "../../../reusableComponents/Inputs";
import { t } from "i18next";
import { Controller } from "react-hook-form";

const Sidebar = ({ isFetching, control }) => {
  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <Controller
        control={control}
        name="isPublished"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
        )}
      />
    </>
  );
};

export default Sidebar;
