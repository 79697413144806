import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const classificationApi = createApi({
  reducerPath: "classificationApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Classification"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getClassifications: build.query({
      query: (q) =>
        `/api/mailbox/classifications?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Classification"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addClassification: build.mutation({
      query: (body) => ({
        url: `api/mailbox/classifications/new`,
        method: "POST",
        body,
      }),
    }),
    updateClassification: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/mailbox/classifications/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Classification"],
    }),
    getClassification: build.query({
      query: (id) => `/api/mailbox/classifications/${id}`,
      providesTags: (id) => [{ type: "Classification", id }],
      lazy: true,
    }),
    deleteClassification: build.mutation({
      query: (id) => ({
        url: `/api/mailbox/classifications/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Classification"],
    }),
  }),
});

export const {
  useGetClassificationsQuery,
  useAddClassificationMutation,
  useUpdateClassificationMutation,
  useGetClassificationQuery,
  useDeleteClassificationMutation,
} = classificationApi;
