import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import Swal from "sweetalert2";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { useGetSelectSegmentsQuery } from "../../../redux/api/emailsApi";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { MRT_ToggleSelect } from "../../reusableComponents/CustomTable/MRT_ToggleSelect";
import { t } from "i18next";
import { Tooltip, Zoom } from "@mui/material";
import {
  useAddFolderOrdersMutation,
  useDeleteFolderOrdersMutation,
  useDeleteOrderMutation,
  useExportOrdersMutation,
  useGetFolderOrdersQuery,
  useGetOrdersQuery,
  useRemoveOrderToFoldersMutation,
  useUpdateFolderOrdersMutation,
  useUpdateOrderToFoldersMutation,
} from "../../../redux/api/ordersApi";
import CloseIcon from "@mui/icons-material/Close";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import StatusModal from "./StatusModal";

export const StatusComponent = ({ data }) => {
  return (
    data?.isPublished && (
      <>
        {data?.status === 1 ? (
          <div className="flex gap-10">
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.orders.status.1")}
              style={{ padding: "0" }}
              className="circle blue"
            ></Tooltip>

            <span>{t("caymland.orders.status.1")}</span>
          </div>
        ) : data?.status === 2 ? (
          <div className="flex gap-10">
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.orders.status.2")}
              style={{ padding: "0" }}
              className="circle  orange"
            ></Tooltip>
            <span>{t("caymland.orders.status.2")}</span>
          </div>
        ) : data?.status === 3 ? (
          <div className="flex gap-10">
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.orders.status.3")}
              style={{ padding: "0" }}
              className="circle  green"
            ></Tooltip>
            <span>{t("caymland.orders.status.3")}</span>
          </div>
        ) : data?.status === 4 ? (
          <div className="flex gap-10">
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.orders.status.4")}
              style={{ padding: "0" }}
              className="circle  red"
            ></Tooltip>
            <span>{t("caymland.orders.status.4")}</span>
          </div>
        ) : null}
      </>
    )
  );
};

const status = [
  {
    value: 1,
    label: "In progress",
  },
  {
    value: 2,
    label: "Open",
  },
  {
    value: 3,
    label: "Finished",
  },
  {
    value: 4,
    label: "Canceled",
  },
];

function Orders() {
  const navigate = useNavigate();
  const location = useLocation();
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [modalOptions, setModalOptions] = useState({
    id: null,
    isOpen: false,
    title: "",
    type: "",
  });

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "id",
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "brochures.orders",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "brochures.orders",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const columns = useMemo(() => [
    {
      accessorKey: "status",
      accessorFn: (row) => row.status,
      header: t("caymland.caymlandb2b.opportunity.filter.status"),
      Cell: ({ cell }) => <StatusComponent data={cell.row.original} />,
      enableColumnFilters: false,
    },
    {
      accessorKey: "downloaded",
      accessorFn: (row) => row.status,
      header: t("caymland.brochures.orders.downloaded"),
      Cell: ({ cell }) =>
        cell.row.original.isDownloaded ? (
          <CheckOutlinedIcon sx={{ fontSize: 16 }} />
        ) : (
          <CloseIcon sx={{ fontSize: 16, color: "red" }} />
        ),
      enableColumnFilters: false,
    },
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: "Name",
      Cell: ({ cell }) => <LinkComponent data={cell.row.original} link="brochures/orders" view={false} />,
      enableColumnFilters: false,
    },
    {
      accessorKey: "user",
      accessorFn: (row) => t("caymlandb2b.opportunity.user"),
      header: t("caymlandb2b.opportunity.user"),
      size: 150,
      Cell: ({ cell }) => (
        <>
          {cell?.row?.original?.user?.firstName} {cell?.row?.original?.user?.lastName}
        </>
      ),
    },
    {
      accessorKey: "Last Modified",
      accessorFn: (row) => t("caymland.ip.organisations.last-modified.20"),
      header: t("caymland.ip.organisations.last-modified.20"),
      size: 150,
      Cell: ({ cell }) => (
        <>
          {cell.row.original.dateModified
            ? format(new Date(cell.row.original.dateModified), "dd.MM.yyyy HH:mm")
            : format(new Date(cell.row.original.dateAdded), "dd.MM.yyyy HH:mm")}
        </>
      ),
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/orders/new"),
    },
  ];

  const { data: ordersData, isFetching: isFetching, refetch: refetchTableData } = useGetOrdersQuery(query);
  const { data: folderOrdersData, refetch } = useGetFolderOrdersQuery();
  const { data: selectSegments } = useGetSelectSegmentsQuery();

  const listCategory = (type) => {
    if (type) {
      setQuery((prev) => ({
        ...prev,
        where: [
          {
            col: "status",
            expr: "eq",
            val: type,
          },
        ],
      }));
    } else {
      setQuery((prev) => ({
        ...prev,
        where: [],
      }));
    }
  };

  const [deleteOrder] = useDeleteOrderMutation();
  const [exportOrders] = useExportOrdersMutation();

  const [addFolder] = useAddFolderOrdersMutation();
  const [updateFolder] = useUpdateFolderOrdersMutation();
  const [deleteFolder] = useDeleteFolderOrdersMutation();
  const [updateOrderToFolder] = useUpdateOrderToFoldersMutation();
  const [removeOrderFromFolder] = useRemoveOrderToFoldersMutation();

  const handleBatchExport = async (selectedRows, table) => {
    const payload = {
      ids: selectedRows,
    };

    const currentDate = format(new Date(), "dd-MM-yyyy_HH-mm-ss");
    const fileName = `${currentDate}.docx`;

    Swal.fire({
      title: t("caymland.mailbox.message.delete.title"),
      text: "Export selected orders",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("caymland.core.yes"),
      cancelButtonText: t("caymland.core.no"),
      customClass: {
        confirmButton: "result-component swal-custom ",
        cancelButton: "delete-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await exportOrders(payload);
        console.log("response", response);

        if (response?.data) {
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          refetchTableData();
        } else {
          Swal.fire(t("caymland.core.error"), t("caymland.message.error"));
        }
      }
    });
  };

  const actionButtons = ActionButtons({
    link: "orders",
    deleteItem: deleteOrder,
    refetch: refetchTableData,
    removeItemFromFolder: removeOrderFromFolder,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "brochures/orders",
    changeStatus: true,
    exportFile: true,
    setModalOptions: setModalOptions,
    refetch: refetchTableData,
    handleBatchExport: handleBatchExport,
  });

  const options = Object.entries(selectSegments?.lists || []).map(([_, { id, name }]) => ({
    value: id,
    label: name,
  }));

  const selectProps = {
    data: status,
    onChange: listCategory,
    value: query?.where && query?.where[0]?.val,
    title: t("caymland.email.emails"),
  };

  const toolbarButtons = useMemo(() => [
    {
      name: "status",
      component: MRT_ToggleSelect,
      props: {
        data: status,
        onChange: listCategory,
        value: query?.where && query?.where[0]?.val,
        title: t("caymland.core.update.heading.status"),
      },
    },
  ]);

  return (
    <Fragment>
      <StatusModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        refetchTableData={refetchTableData}
      />
      <Header buttons={buttons} title={t("caymland.green.tab.orders")} showAlert={show} alertMessage={message} />
      <DragAndDropTable
        filteredData={ordersData?.data}
        folderData={folderOrdersData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={<CampaignForm control={control} />}
        handleSubmit={handleSubmit}
        reset={reset}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateOrderToFolder}
        linkNavigate={"edit"}
        toolbarActions={{
          select: true,
        }}
        selectProps={selectProps}
        toolbarButtons={toolbarButtons}
        enableSorting={false}
        reorderBetween={false}
      />
    </Fragment>
  );
}

export default Orders;
