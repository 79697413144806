import React from "react";
import Editor from "@monaco-editor/react";

const CustomJson = ({ modifiedData, setModifiedData, focus = false }) => {
  return (
    <Editor
      className="monacoEditor"
      options={{
        unusualLineTerminators: "auto",
      }}
      defaultLanguage="json"
      value={focus ? modifiedData?.json : modifiedData.customJson}
      theme="vs-dark"
      onChange={(e) =>
        setModifiedData({
          ...modifiedData,
          ...(focus ? { json: e } : { customJson: e }),
        })
      }
    />
  );
};

export default CustomJson;
