import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Nav, NavLink, NavItem } from "reactstrap";
import { useParams } from "react-router-dom";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { useGetSegmentsLeadsQuery } from "../../../redux/api/segmentsApi";
import { useTranslation } from "react-i18next";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import {
  useAddSegmentsToContactMutation,
  useGetContactsQuery,
  useRemoveSegmentsToContactMutation,
  useUpdateContactSegmentsMutation,
} from "../../../redux/api/contactsApi";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { Tooltip } from "@mui/material";
import { FaPlus } from "react-icons/fa";
import ContactSegmentModal from "./ContactSegmentModal";
import Button from "../../reusableComponents/Buttons/Button";

function TableViewSegment({ contactsLength, setContactsLength, rebuild }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("contacts");
  const [isOpen, setIsOpen] = useState(false);
  const [data1, setData1] = useState([]);
  const name = "Contacts";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "last_active",
    name: "name",
    search: location?.state?.search ? location.state.search : null,
  });
  const [addSegment] = useAddSegmentsToContactMutation();
  const [removeSegment] = useRemoveSegmentsToContactMutation();
  const [updateSegment] = useUpdateContactSegmentsMutation();

  const { data, isFetching, refetch } = useGetSegmentsLeadsQuery({ query, id });

  useEffect(() => {
    if (data?.data) {
      setData1(data.data);
      setContactsLength(data?.total);
    }
  }, [data]);

  useEffect(() => {
    if (activeTab === "allcontacts") {
      setQuery((prev) => ({
        ...prev,
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        added: [null],
        search: null,
      }));
    }
    if (activeTab === "contacts") {
      setQuery((prev) => ({
        ...prev,
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        added: [null],
        removed: null,
      }));
    }
    if (activeTab === "dynamic_added") {
      setQuery((prev) => ({
        ...prev,
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        added: "dynamically",
      }));
    }
    if (activeTab === "manual_added") {
      setQuery((prev) => ({
        ...prev,
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        added: "manually",
      }));
    }
    if (activeTab === "manual_removed") {
      setQuery((prev) => ({
        ...prev,
        pageIndex: 0,
        pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
        added: null,
        removed: "manually",
      }));
    }
  }, [activeTab]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) => {
          if (row?.fields?.all?.firstname) {
            if (row?.fields?.all?.lastname) {
              return row?.fields?.all?.firstname + " " + row?.fields?.all?.lastname;
            } else {
              return row?.fields?.all?.firstname;
            }
          } else {
            return row?.ipAddresses[0]?.ip;
          }
        },

        header: t("caymland.lottery.column.name.name"),
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "email",
        accessorFn: (row) => row?.fields?.all?.email,
        header: t("caymland.core.type.email"),
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "Location",
        accessorFn: (row) => row.location,
        header: t("caymland.lead.lead.thead.location"),
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const { state, city, country } = cell?.row?.original;
          let flagLetters = cell.row?.original?.fields?.country
            ? cell.row.original?.country === "Switzerland"
              ? "ch"
              : cell?.row?.original?.country.toLowerCase()
            : "";
          return state || city || country ? (
            <div>
              {flagLetters && <i className={`flag-icon flag-icon-${flagLetters.toLowerCase()}`}></i>} {city}
              {city && state && ", "} {state}
              {!city && !state && country}
            </div>
          ) : null;
        },
      },
    ],
    []
  );

  const actionButtons = ActionButtons({
    link: "contacts",
    name: "contact",
    addContactSegment: activeTab == "allcontacts" || activeTab == "manual_removed",
    removeContactSegment: activeTab != "allcontacts" && activeTab != "manual_removed",
    addSegment: addSegment,
    removeSegment: removeSegment,
    refetch: refetch,
    folderId: id,
    edit: false,
    _delete: false,
    clone: false,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "contacts",
    _delete: false,
    segmentsAddContacts: activeTab == "allcontacts" || activeTab == "manual_removed",
    segmentsRemoveContacts: activeTab != "allcontacts" && activeTab != "manual_removed",
    segmentId: id,
    refetch: refetch,
    updateSegment: updateSegment,
  });

  const buttons = [
    {
      name: "close",
      title: `${t("caymland.segments.add.contact")}`,
      icon: <FaPlus size={15} />,
      onClick: () => setIsOpen(!isOpen),
    },
  ];

  return (
    <>
      <Card style={{ boxShadow: "unset", height: "auto", marginBottom: "0" }}>
        <CardBody style={{ padding: "0" }}>
          <div className="flex items-center justify-between">
            <Nav className="nav-primary w-full" tabs>
              <NavItem>
                <NavLink
                  href="#"
                  className={"contacts" === activeTab ? "active" : ""}
                  onClick={() => setActiveTab("contacts")}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                  }}
                >
                  {t("caymland.lead.lead.events.segments")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={"dynamic_added" === activeTab ? "active" : ""}
                  onClick={() => setActiveTab("dynamic_added")}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                  }}
                >
                  {t("caymland.segment.stats.graph.dynamic")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={"manual_added" === activeTab ? "active" : ""}
                  onClick={() => setActiveTab("manual_added")}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                  }}
                >
                  {t("caymland.lead.report.segment.manually_added")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={"manual_removed" === activeTab ? "active" : ""}
                  onClick={() => setActiveTab("manual_removed")}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                  }}
                >
                  {t("caymland.lead.report.segment.manually_removed")}
                </NavLink>
              </NavItem>
            </Nav>
            <div style={{ position: "absolute", right: "10px", top: "50px", zIndex: 9999999 }}>
              <Button buttons={buttons} />
            </div>
          </div>
          <div
            style={{
              borderBottom: "1px solid #ebebeb",
            }}
          >
            <ContactSegmentModal isOpen={isOpen} setIsOpen={setIsOpen} id={id} refetch={refetch} />
            <CustomTable
              showDetailPanel
              columns={columns}
              data={data}
              query={query}
              setQuery={setQuery}
              viewTable
              segments
              actionButtons={actionButtons}
              actionBatchButtons={actionBatchButtons}
              isFetching={isFetching}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default TableViewSegment;
