import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./index.scss";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import App from "./components/app";

//config data
import { store } from "./redux/store";
import { Buffer } from "buffer";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const Root = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#135f95",
        light: "#2db1ed",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: "#2a3142",
        light: "#6d7998",
      },
      text: {
        primary: "#333333",
        secondary: "#2a3142",
        disabled: "#8a8a8a",
        hint: "#8a8a8a",
      },
    },
    typography: {
      fontFamily: ["Titillium Web", "sans-serif"].join(","),
      allVariants: {
        color: "#313131",
      },
    },
  });

  if (process.env.REACT_APP_NODE_ENV !== "development") {
    console.log = () => {};
    console.error = () => {};
    console.info = () => {};
    console.debug = () => {};
  }
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
