export const MRT_GlobalSearch = (search, setQuery) => {
  if (search) {
    setQuery((current) => ({ ...current, search }));
  } else {
    setQuery((current) => {
      const { search, ...other } = current;
      return other;
    });
  }
};
