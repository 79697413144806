import { t } from "i18next";
import React, { useState } from "react";
import { Edit } from "@mui/icons-material";
import { Select } from "../../../reusableComponents/Inputs";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useGetUserSelectQuery } from "../../../../redux/api/usersApi";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";

const Owners = ({ data, refetch }) => {
  console.log(data);
  const [hover, setHover] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  const { data: ownerOptions } = useGetUserSelectQuery();
  const [updateOwner] = useUpdateContactsMutation();

  const handleCancel = (e) => {
    e.stopPropagation();
    setIsEdit(false);
    setSelectedOwner(null); // Reset the selection if cancelled
  };

  const handleOwnerChange = (e) => {
    setSelectedOwner(e.value); // Update selected owner value
  };

  const handleOwner = async () => {
    if (selectedOwner) {
      try {
        await updateOwner({
          id: data.contact.id,
          owner: selectedOwner,
        });
        refetch();
        setIsEdit(false); // Close the edit mode on successful save
      } catch (error) {
        console.error("Error updating owner:", error);
      }
    }
  };

  return (
    <div
      className="right-info"
      style={{ minWidth: "200px" }}
      onClick={() => setIsEdit(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="tw-flex tw-gap-2 tw-items-center ">
        <p style={{ padding: "0 20px" }}> {t("caymland.lead.list.filter.owner")}</p>
        {hover && !isEdit && (
          <Edit fontSize="small" style={{ marginLeft: "auto", marginRight: "10px", marginBottom: "16px" }} />
        )}
      </div>
      {isEdit ? (
        <div className="flex items-center gap-5px w-full">
          <Select options={ownerOptions} value={selectedOwner} onChange={handleOwnerChange} />
          <div className="flex gap-5px items-center ">
            <button className="normal-btn" onClick={handleCancel}>
              <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
            </button>
            <button className="normal-btn" onClick={handleOwner}>
              <AiOutlineSave color="#135F95" size={20} title="Save" />
            </button>
          </div>
        </div>
      ) : (
        <span
          className="badge badge-light tag-pills-sm-mb"
          style={{
            fontSize: "0.75rem",
            fontWeight: "400",
            border: "1px solid #55607F",
            color: "#55607F",
            background: "white",
          }}
        >
          {data?.contact?.owner?.firstName} {data?.contact?.owner?.lastName}
        </span>
      )}
    </div>
  );
};

export default Owners;
