import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Success } from "../../../reusableComponents/Alerts";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import MainContent from "./MainContent";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import * as yup from "yup";
import { useGetTempQuery, useAddTempMutation, useUpdateTempMutation } from "../../../../redux/api/tempApi";

function AddEditTemplate() {
  const navigate = useNavigate();
  const location = useLocation();
  const isNew = location.pathname.includes("new");
  const isEdit = location.pathname.includes("edit");
  const ClonePath = location.pathname.includes("clone");
  const id = location?.state?.id || location?.state || useParams().id;

  const { data, isLoading, isFetching } = useGetTempQuery(id, { skip: !id });

  const [addNewTemp] = useAddTempMutation();
  const [updateTemp] = useUpdateTempMutation(id);

  const schema = yup.object().shape({
    name: yup.string().required(t("caymland.core.fieldrequired")),
    content: yup.string().required(t("caymland.core.fieldrequired")),
    language: yup.mixed().required(t("caymland.core.fieldrequired")),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      language: "de", // Set default value here
    },
  });

  useEffect(() => {
    if (id && data?.template) {
      reset({
        ...data.template,
      });
    }
  }, [id, isNew, data, reset, isEdit]);

  const handleCancel = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/mailbox-admin/template");
      } else {
        return;
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleAddEditTemplate(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleAddEditTemplate(data)),
    },
  ];

  const handleAddEditTemplate = async (formData, close = false) => {
    const languageValue = typeof formData.language === "object" ? formData.language.value : formData.language;
    const payload = {
      ...formData,
      language: languageValue,
    };

    const response = id && !ClonePath ? await updateTemp({ id, ...payload }) : await addNewTemp(payload);
    if (!response?.error) {
      Success(
        `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
          type: id && !ClonePath ? "edited" : "created",
        })}`
      ).then((result) => {
        if (result.isConfirmed) {
          if (close) {
            navigate(`/mailbox-admin/template`);
          } else {
            navigate(`/mailbox-admin/template/edit/${response?.data?.template?.id}`);
          }
        }
      });
    } else {
      console.error("Submission Error", response.error.data.errors[0].message);
    }
  };

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isFetching}
          title={!ClonePath && id ? "Edit Order" : t("caymland.mailbox.template.add")}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={<MainContent isFetching={isLoading} control={control} errors={errors} />}
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={<Sidebar isFetching={isLoading} control={control} errors={errors} />}
      />
    </>
  );
}

export default AddEditTemplate;
