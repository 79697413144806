import React from "react";
import Moment from "moment";
import { DragHandle } from "../../widgetGenerator/index";
import { Label } from "../../../../reusableComponents/Inputs";
import EditDeleteMenu from "../../EditDeleteMenu";
import { t } from "i18next";
import { FaCheck } from "react-icons/fa"; // Assuming you are using react-icons

export default function Activities({ name, logs, width, height, handleEdit, handleDelete, id }) {
  // Check if logs is an array, and if not, convert it into an array
  const logArray = Array.isArray(logs) ? logs : Object.values(logs);

  return (
    <div style={{ width: `${width}%`, height: `${height}px`, marginTop: "20px" }}>
      <div className="chart-header">
        <DragHandle />
        <div style={{ marginTop: "8px", marginLeft: "10px" }}>
          <Label name={name} label={name} />
        </div>
        <EditDeleteMenu handleEdit={handleEdit} handleDelete={handleDelete} id={id} />
      </div>
      <div
        style={{
          padding: "0px 19px 15px 19px",
          fontSize: "15px",
          fontWeight: "600",
          marginTop: "20px",
        }}
      >
        {t("caymland.core.recent.activity")}
      </div>
      <div className="card-body px-20" style={{ height: height, overflowY: "auto" }}>
        <div className="activity">
          {logArray.map((activity, index) => (
            <div key={index} className="d-flex align-items-center" style={{ marginBottom: "15px" }}>
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "15px",
                  borderRadius: "50%",
                  background: activity.action === "create" ? "limegreen" : "#e9e7ed",
                }}
              >
                {activity.action === "create" && <FaCheck size={12} color="#fff" />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px", marginBottom: "2px" }}>
                  {activity.action === "create"
                    ? t("caymland.showare.field.created_by")
                    : t("caymland.core.update.by.past.tense")}{" "}
                  <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{Moment(activity.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
