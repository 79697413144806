import React from "react";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import { Checkbox, DatePicker, Label, Select } from "../../reusableComponents/Inputs";
import Dropzone from "react-dropzone-uploader";
import { retrieveCategories } from "../../reusableComponents/retriveCategoriesByBundle";
import { useTranslation } from "react-i18next";
import { categoriesApi, useAddCategoryMutation } from "../../../redux/api/categoriesApi";
import ModalGenerator from "../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../reusableComponents/ModalGenerator/ModalGeneratorData";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

const Sidebar = ({
  isFetching,
  getUploadParams,
  handleChangeStatus,
  modalOptions,
  setModalOptions,
  setNewItem,
  control,
  errors,
  watch,
}) => {
  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();
  console.log(watch());

  const locale = useSelector((state) => state?.settings?.data?.locales);
  const { t } = useTranslation();
  const previewBlob = watch("previewBlob");
  const preview = watch("preview");

  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />

      <Controller
        control={control}
        name="category"
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            label={t("caymland.page.tracking.form.category")}
            options={retrieveCategories}
            value={value}
            placeholder={t("caymland.core.select")}
            onChange={(selectedOption) => {
              selectedOption?.value === "create_new"
                ? setModalOptions((prev) => ({ ...prev, isOpen: true }))
                : onChange(selectedOption);
            }}
            trigger={triggerCategories}
            isClearable
            isCreatable
          />
        )}
      />
      <Controller
        control={control}
        name="language"
        render={({ field: { name, onChange, value } }) => (
          <Select
            name="language"
            label={t("caymland.core.language")}
            options={locale}
            value={value}
            required
            placeholder={t("caymland.core.select")}
            onChange={onChange}
            tooltip={t("caymland.dynamicContent.form.language.help")}
            errors={errors && errors[name]}
          />
        )}
      />
      <div className="switch-holder pb-20">
        <div className="switch-toggle">
          <Controller
            control={control}
            name="downloadableOnly"
            render={({ field: { name, value, onChange } }) => (
              <Checkbox
                name={name}
                value={value}
                onChange={onChange}
                label={t("caymland.brochures.downloadable_only")}
              />
            )}
          />
        </div>
        <div className="switch-toggle">
          <Controller
            control={control}
            name="isPublished"
            render={({ field: { name, value, onChange } }) => (
              <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
            )}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="publishUp"
        render={({ field: { name, onChange, value } }) => (
          <DatePicker label={t("caymland.core.form.publishup")} name={name} value={value} onChange={onChange} />
        )}
      />

      <Controller
        control={control}
        name="publishDown"
        render={({ field: { name, onChange, value } }) => (
          <DatePicker label={t("caymland.core.form.publishdown")} name={name} value={value} onChange={onChange} />
        )}
      />

      <div className="tw-flex tw-w-full tw-justify-between tw-items-center">
        <Label label={`Upload (only img file)`} />
      </div>
      <form className="dropzone digits mt-10px" id="singleFileUpload" action="/upload.php">
        <div className="dz-message needsclick h-200-dropzone">
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            multiple={false}
            canCancel={false}
            inputContent={t("caymland.ticket.form.file")}
            accept="image/*"
            styles={{
              dropzoneActive: { borderColor: "green" },
            }}
          />
        </div>
      </form>
      <div className="tw-full tw-mt-5">
        {preview ? (
          <img
            src={previewBlob || preview}
            alt="Uploaded"
            style={{
              maxWidth: "100%",
              "max-width": "100% !important",
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default Sidebar;
