import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { t } from "i18next";
import { Tooltip, Zoom } from "@mui/material";
import Chip from "@mui/material/Chip";
import {
  useAddFolderSMSMutation,
  useDeleteFolderSMSMutation,
  useDeleteSmsMutation,
  useGetFolderSMSQuery,
  useGetSmsesQuery,
  useRemoveSMSToFoldersMutation,
  useUpdateFolderSMSMutation,
  useUpdateSmsesMutation,
  useUpdateSMSToFoldersMutation,
} from "../../../../redux/api/smsApi";
import { SmsModal } from "./SmsModal";
import { SmsSearchHelper } from "../../../reusableComponents/HelperTexts/SearchHelper";

export const StatusComponent = ({ data }) => {
  return (
    <div className="flex gap-10">
      {data.pendingCount > 0 && (
        <Tooltip
          arrow
          followCursor
          placement="top"
          TransitionComponent={Zoom}
          title={t("caymland.channel.stat.leadcount.tooltip")}
        >
          <Chip
            label={`${t("caymland.sms.stat.leadcount", { count: data?.pendingCount })}`}
            size="small"
            sx={{
              borderRadius: "5px",
              padding: "2px 10px",
              fontSize: "14px",
              margin: "2px 0",
              marginLeft: "2px",
              cursor: "pointer",
              transition: "background-color 0.3s ease, color 0.3s ease",
              width: "fit-content",
              border: "1px solid #6c757d",
              color: "#6c757d",
              "&:hover": {
                backgroundColor: "#6c757d",
                color: "white",
              },
            }}
          />
        </Tooltip>
      )}

      <Tooltip
        arrow
        followCursor
        placement="top"
        TransitionComponent={Zoom}
        title={t("caymland.channel.stat.leadcount.tooltip")}
      >
        <Chip
          label={`${t("caymland.sms.stat.sentcount", { count: data?.sentCount })}`}
          size="small"
          sx={{
            borderRadius: "5px",
            padding: "2px 10px",
            fontSize: "14px",
            margin: "2px 0",
            marginLeft: "2px",
            cursor: "pointer",
            transition: "background-color 0.3s ease, color 0.3s ease",
            width: "fit-content",
            border: "1px solid #135f95",
            color: "#135f95",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#135f95",
              color: "white",
            },
          }}
        />
      </Tooltip>
    </div>
  );
};

function Sms(props) {
  const navigate = useNavigate();
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const [localLoading, setLocalLoading] = useState(-1);
  const [idModal, setIdModal] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const name = "SMS";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    search: sessionStorage.getItem(`search${name}`) ? sessionStorage.getItem(`search${name}`) : "",
    where: [],
  });

  const [updateSmses] = useUpdateSmsesMutation();
  const [deleteSms] = useDeleteSmsMutation();
  const { data: smsesData, isFetching, refetch: refetchTableData } = useGetSmsesQuery(query);

  const { data: folderSmsData, refetch } = useGetFolderSMSQuery();
  const [addFolder] = useAddFolderSMSMutation();
  const [updateFolder] = useUpdateFolderSMSMutation();
  const [deleteFolder] = useDeleteFolderSMSMutation();
  const [updateSMSToFolder] = useUpdateSMSToFoldersMutation();
  const [removeSMSFromFolder] = useRemoveSMSToFoldersMutation();

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "sms",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "sms",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { isPublished: !data.isPublished };
    await updateSmses({ id: data.id, ...updatedData });
    await refetchTableData();
    setLocalLoading(-1);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: "Name",
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="smses"
          view={true}
        />
      ),
      size: 100,
      enableColumnFilters: false,
    },
    {
      accessorKey: "category",
      accessorFn: (row) => (row.category?.title ? row.category?.title : t("caymland.core.form.uncategorized")),
      header: t("caymland.core.category"),
      size: 150,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "status",
      accessorFn: (row) => row.name,
      header: "Status",
      Cell: ({ cell }) => <StatusComponent data={cell.row.original} />,
      enableColumnFilters: false,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/sms/new"),
    },
  ];

  const openModal = async (id) => {
    setIdModal(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setIdModal(null);
    setModalIsOpen(false);
  };

  const actionButtons = ActionButtons({
    link: "sms",
    deleteItem: deleteSms,
    refetch: refetchTableData,
    removeItemFromFolder: removeSMSFromFolder,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    showPreviewMessage: true,
    openModal: openModal,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "smses/v2",
    refetch: refetchTableData,
  });

  return (
    <Fragment>
      {idModal && <SmsModal modalIsOpen={modalIsOpen} closeModal={closeModal} id={idModal} />}
      <Header buttons={buttons} title="SMS" />
      <DragAndDropTable
        filteredData={smsesData?.data}
        folderData={folderSmsData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={<CampaignForm control={control} />}
        handleSubmit={handleSubmit}
        reset={reset}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateSMSToFolder}
        toolbarActions={{
          select: true,
        }}
        searchHelperBody={<SmsSearchHelper />}
      />
    </Fragment>
  );
}

export default Sms;
