import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const SidebarSkeleton = ({ applyPadding = false, company = false }) => {
  return (
    <Card
      style={{
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
        height: "auto",
        width: "100%",
        marginTop: company ? "20px" : "5px",
      }}
    >
      <CardContent sx={{ p: applyPadding ? 2 : 0 }}>
        <Box sx={{ width: "100%", my: 0 }}>
          <Skeleton variant="text" width="100%" height={60} />
          <Skeleton variant="rectangular" width="100%" height={600} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SidebarSkeleton;
