import React, { useEffect, useState } from "react";
import { languages } from "../../../../reusableComponents/data";
import { Text, Select, Checkbox, DatePicker, TextArea } from "../../../../reusableComponents/Inputs";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../../redux/api/categoriesApi";
import { useTranslation } from "react-i18next";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";

function Sidebar({ modifiedData, setModifiedData, errors, setErrors, pages }) {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "page",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const [redirectTypes, setRedirectTypes] = useState([
    { value: null, label: t("caymland.page.form.redirecttype.none") },
    { value: "301", label: t("caymland.page.form.redirecttype.permanent") },
    { value: "302", label: t("caymland.page.form.redirecttype.temporary") },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }

    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (e, name) => {
    if (e === null) {
      setModifiedData({ ...modifiedData, [name]: "" });
    } else {
      setModifiedData({ ...modifiedData, [name]: e?.value });
    }
  };

  const handleSelectChangeCategory = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData({
      ...modifiedData,
      [name]: checked,
    });
  };

  const handleTranslationOfChange = (e) => {
    setModifiedData({
      ...modifiedData,
      translationParent: e,
    });
  };

  const languageValue = modifiedData?.language ?? languages.find((x) => x.value === modifiedData?.language);

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Text
        name="title"
        errors={errors?.title}
        required={true}
        value={modifiedData?.title}
        onChange={(e) => handleInputChange(e)}
        label={t("caymland.core.title")}
      />
      <Text
        name="alias"
        value={modifiedData?.alias}
        onChange={(e) => handleInputChange(e)}
        label={t("caymland.core.alias")}
        tooltip={t("caymland.page.help.alias")}
      />
      <Select
        name="category"
        label={t("caymland.page.tracking.form.category")}
        bundle="page"
        options={retrieveCategories}
        value={modifiedData?.category ?? null}
        onChange={handleSelectChangeCategory}
        trigger={triggerCategories}
        isCreatable
        isClearable
      />
      <Select
        name="language"
        label={t("caymland.core.language")}
        options={languages.map((lang) => ({ label: t(lang.labelKey), value: lang.value }))}
        value={languageValue}
        onChange={(e) => handleSelectChange(e, "language")}
        tooltip={t("caymland.dynamicContent.form.language.help")}
      />

      <Select
        name="translation"
        label={t("caymland.core.form.translation_parent")}
        valueField="id"
        labelField="title"
        options={pages}
        value={modifiedData?.translationParent ?? null}
        onChange={handleTranslationOfChange}
        tooltip={t("caymland.core.form.translation_parent.help")}
      />
      <Checkbox
        name="isPublished"
        value={modifiedData?.isPublished}
        onChange={(e) => handleToggleChange(e)}
        label={t("caymland.core.form.published")}
      />
      <Checkbox
        name="preferenceCenter"
        value={modifiedData?.isPreferenceCenter}
        label={t("caymland.page.config.preference_center")}
        onChange={(e) =>
          setModifiedData({
            ...modifiedData,
            isPreferenceCenter: e.target.checked,
          })
        }
      />
      <DatePicker
        label={t("caymland.core.form.publishup")}
        name={"Publish at (date/time)"}
        value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
        maxDate={modifiedData?.publishDown}
      />
      <DatePicker
        label={t("caymland.core.form.publishdown")}
        name={"Unpublish at (date/time)"}
        value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
        minDate={modifiedData?.publishUp}
      />
      <Select
        name="RedirectType"
        label={t("caymland.page.form.redirecttype")}
        tooltip={t("caymland.page.form.redirecttype.help")}
        options={redirectTypes}
        value={redirectTypes.find((rt) => rt.value === modifiedData?.redirectType)}
        onChange={(e) => {
          setModifiedData((prev) => ({
            ...prev,
            redirectType: e.value,
          }));
        }}
      />
      {modifiedData?.redirectType && (
        <Text
          name="redirectUrl"
          value={modifiedData?.redirectUrl}
          onChange={(e) => handleInputChange(e)}
          label=" URL Redirect"
          placeholder="http://"
          required={true}
          tooltip={t("caymland.page.form.redirecturl.help")}
        />
      )}
      <Checkbox
        name="noIndex"
        value={modifiedData?.noIndex}
        onChange={(e) => handleToggleChange(e)}
        label={t("caymland.form.form.no_index")}
      />
      <TextArea
        label={t("caymland.page.form.metadescription")}
        value={modifiedData?.metaDescription ?? ""}
        onChange={(data) => {
          setModifiedData({ ...modifiedData, metaDescription: data });
        }}
        showToolbar={false}
      />
    </>
  );
}

export default Sidebar;
