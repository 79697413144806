import React, { useEffect, useRef, useState } from "react";
import FocusEditor from "react-email-editor";
import { useGetBuilderConfigQuery } from "../../../../../redux/api/usersApi";

const Builder = ({
  editorData: { projectId, options, customTools },
  isOpen,
  loading,
  setModifiedData,
  modifiedData,
  setIsOpen,
  title,
  apply,
  setApplyAndCloseTemplateRef,
}) => {
  const focusEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const { data } = useGetBuilderConfigQuery("popup");
  const [initialLoad, setInitialLoad] = useState(true);
  const [editorReady, setEditorReady] = useState(false); // Track editor's readiness

  const applyAndCloseTemplate = (params) => {
    focusEditorRef.current.editor.exportHtml((data) => {
      const { design, html, chunks } = data;

      // Extract CSS from chunks.css and convert it to a string
      let css = typeof chunks.css === "string" ? chunks.css : JSON.stringify(chunks.css);

      // Replace the existing <style> tags with the new CSS
      var regex = /<style\b[^>]*>(.*?)<\/style>/gs;
      const updatedHtml = html.replace(regex, `<style type='text/css'>${css}</style>`);

      // Encode the modified HTML and design JSON
      const encodedHtml = Buffer.from(updatedHtml).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      // Update the state with the modified data
      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Directly update the editor with the latest design
      focusEditorRef.current.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setApplyBuilder("savebuilder");
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    // Pass the applyAndCloseTemplate function to the parent component
    if (setApplyAndCloseTemplateRef) {
      setApplyAndCloseTemplateRef(() => applyAndCloseTemplate);
    }
  }, [setApplyAndCloseTemplateRef]); // Only run when setApplyAndCloseTemplateRef changes

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [shouldApply]);

  const onReady = () => {
    if (data && modifiedData?.json && initialLoad) {
      try {
        focusEditorRef?.current?.editor.loadDesign(JSON.parse(modifiedData.json));
        setEditorLoaded(true);
      } catch (error) {
        console.error("Error parsing JSON: ", error);
      }
    } else {
      console.error("JSON or data is empty");
    }
  };

  // Use a useEffect to wait until the data and modifiedData are both ready
  useEffect(() => {
    if (data && modifiedData?.json && !editorReady) {
      onReady(); // Call onReady only when everything is available
    }
  }, [data, modifiedData?.json]);

  useEffect(() => {
    setEditorReady(false);
  }, [modifiedData.customJson, data]);

  return <>{data && <FocusEditor ref={focusEditorRef} onReady={onReady} minHeight="100%" options={data} />}</>;
};

export default Builder;
