import React, { useEffect, useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { t } from "i18next";
import { Controller } from "react-hook-form";
import { Select } from "../../reusableComponents/Inputs";
import { productsApi } from "../../../../src/redux/api/productsApi";
import { useWatch } from "react-hook-form";

const FieldSection = ({ watch, control, fields, append, remove, setValue, getValues }) => {
  const [computedWeight, setComputedWeight] = useState(0);
  const [triggerProductsQuery] = productsApi.useLazyGetProductsQuery();

  const fieldsTotal = useWatch({
    control,
    name: "products",
  });

  const totalQty = fieldsTotal?.reduce((acc, field) => acc + (Number(field.qty) || 0), 0) || 0;

  useEffect(() => {
    if (fieldsTotal && Array.isArray(fieldsTotal) && fieldsTotal.length > 0) {
      const totalWeight = fieldsTotal.reduce((acc, field) => {
        const qty = Number(field.qty);
        const weight = field.product?.weight ? Number(field.product.weight) : 0;
        return acc + qty * weight;
      }, 0);

      setComputedWeight(totalWeight);
    } else {
      setComputedWeight(0);
    }
  }, [fieldsTotal]);

  const retrieveProducts = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerProductsQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerProductsQuery(query).unwrap();
      resolve(response?.data);
    });

  const handleAddProduct = () => {
    append({ id: "", product: "", qty: "", weight: "" });
  };

  const handleRemoveProduct = (index) => {
    setValue(`products.${index}.removed`, true);
    remove(index);
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex">
        <div
          className="tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2 tw-w-[5%]  tw-h-[34px] tw-shadow tw-duration-150  tw-bg-secondary  tw-border tw-rounded-tl-md tw-cursor-pointer"
          onClick={handleAddProduct}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{
              color: "darkgreen",
              fontSize: "16px",
            }}
          />
        </div>
        <div className="tw-w-[50%] tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2  tw-h-[34px] tw-shadow tw-duration-150  tw-bg-secondary  tw-border ">
          {t("caymland.brochures.order.product.list.name")}
        </div>
        <div className="tw-w-[20%] tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2  tw-h-[34px] tw-shadow tw-duration-150  tw-bg-secondary  tw-border">
          {t("caymland.brochures.order.product.list.qty")}
        </div>
        <div className="tw-w-[20%] tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2   tw-h-[34px] tw-shadow tw-duration-150  tw-bg-secondary  tw-border tw-rounded-tr-md">
          {t("caymland.brochures.order.product.list.weight")}
        </div>
        <div className="tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2 tw-w-[5%]  tw-h-[34px] tw-shadow tw-duration-150  tw-bg-secondary  tw-border"></div>
      </div>

      <div className="flex flex-col">
        {fields.map((oneField, index) => (
          <div key={oneField.id} className="col-sm-12 col-md-12 ">
            <div className="normal-btn flex items-center">
              <div className="tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2 tw-w-[5%] tw-bg-white tw-h-[34px] tw-shadow  tw-border hover:tw-bg-[#fafafa]  hover:tw-scale-105 tw-duration-150"></div>

              <div className="tw-flex tw-items-center tw-font-medium tw-capitalize tw-text-sm  tw-w-[50%] tw-bg-white tw-text-black tw-h-[34px] tw-shadow  tw-border hover:tw-bg-[#fafafa]  tw-text-foreground hover:tw-text-muted-foreground">
                <Controller
                  control={control}
                  name={`products.${index}.product`}
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <Select
                        {...oneField}
                        name={name}
                        margin
                        viewEdit
                        options={retrieveProducts}
                        value={value}
                        isClearable={false}
                        labelField="name"
                        valueField="id"
                        onChange={(selected) => {
                          console.log("selected", selected);
                          setValue(`products.${index}.product`, selected);
                          setValue(`products.${index}.id`, selected.id);
                          setValue(`products.${index}.weight`, selected.weight);
                          setValue(`products.${index}.qty`, 1);
                          setValue(`products.${index}.initial`, selected.weight);
                        }}
                      />
                    );
                  }}
                />
              </div>

              <div className="tw-flex tw-items-center tw-justify-between te-text-[14px] tw-py-0 tw-px-8px tw-w-[20%] tw-h-[34px] tw-text-black  tw-shadow  hover:tw-bg-[#fafafa] tw-bg-white tw-border  tw-pl-[0.8rem]  hover:tw-scale-y-105 tw-duration-150">
                <Controller
                  control={control}
                  name={`products.${index}.qty`}
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <input
                        style={{
                          background: "inherit",
                          border: "0",
                          width: "100%",
                          height: "28px",
                          outline: "none",
                          padding: "4px",
                          textAlign: 'center'
                        }}
                        className="tw-text-center no-arrows"
                        type="number"
                        value={value}
                        onChange={(e) => {
                          const newQty = +e.target.value;
                          const weight = Number(getValues(`products.${index}.weight`));
                          const totalWeight = newQty * weight;
                          setValue(`products.${index}.qty`, newQty);
                          setValue(`products.${index}.weight`, totalWeight);
                        }}
                      />
                    );
                  }}
                />
              </div>

              <div className="tw-flex tw-items-center tw-justify-center te-text-[14px] tw-py-0 tw-px-8px tw-w-[20%] tw-h-[34px] tw-text-black  tw-shadow  hover:tw-bg-[#fafafa] tw-bg-white tw-border  tw-text-center  hover:tw-scale-y-105 tw-duration-150">
                <Controller
                  control={control}
                  name={`products.${index}.weight`}
                  render={({ field }) => {
                    const sigleProduct = watch(`products.${index}`);
                    const valueTotal = sigleProduct.qty * sigleProduct.product.weight || 0;

                    return <span className="tw-text-center">{valueTotal}</span>;
                  }}
                />
              </div>

              <div
                className="tw-flex tw-items-center tw-justify-center  tw-font-medium tw-capitalize tw-text-sm tw-px-2 tw-w-[5%] tw-bg-white tw-h-[34px] tw-shadow  tw-border hover:tw-bg-[#fafafa]  hover:tw-scale-105 tw-duration-150 tw-cursor-pointer"
                onClick={() => handleRemoveProduct(index)}
              >
                <CloseOutlinedIcon
                  sx={{
                    color: "crimson",
                    fontSize: "16px",
                  }}
                  className="hover:tw-scale-110 tw-duration-200 "
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex">
        <div className="tw-flex tw-items-center tw-justify-center  tw-font-bold tw-capitalize tw-text-sm tw-px-2 tw-w-[5%]  tw-h-[34px] tw-shadow tw-duration-150   tw-border"></div>
        <div className="tw-w-[50%] tw-flex tw-items-center tw-justify-start  tw-font-bold tw-capitalize tw-text-sm tw-px-2  tw-h-[34px] tw-shadow tw-duration-150    tw-border ">
          {t("caymland.dashboard.label.segments.total")}
        </div>
        <div className="tw-w-[20%] tw-flex tw-items-center tw-justify-center  tw-font-bold tw-capitalize tw-text-sm  tw-px-2  tw-h-[34px] tw-shadow tw-duration-150    tw-border">
          {totalQty}
        </div>
        <div className="tw-w-[20%] tw-flex tw-items-center tw-justify-center  tw-font-bold tw-capitalize tw-text-sm   tw-h-[34px] tw-shadow tw-duration-150    tw-border tw-rounded-tr-md">
          {computedWeight}
        </div>
        <div className="tw-flex tw-items-center tw-justify-center  tw-font-bold tw-capitalize tw-text-sm tw-px-2 tw-w-[5%]  tw-h-[34px] tw-shadow tw-duration-150    tw-border"></div>
      </div>
    </div>
  );
};

export default FieldSection;
