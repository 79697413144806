import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import { Error, Success } from "../../reusableComponents/Alerts";
import Button from "../../reusableComponents/Buttons/Button";
import { Select, Text, Checkbox } from "../../reusableComponents/Inputs";
import {
  useCreateCategoryMutation,
  useGetParentCategoriesOptionsQuery,
  useUpdateCategoryMutation,
} from "../../../redux/api/categoriesApi";
import reactCSS from "reactcss";
import ColorPicker from "../../reusableComponents/Inputs/ColorPicker";
import { useTranslation } from "react-i18next";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

export const AddorEditCategoryModal = ({
  setModifiedData,
  modifiedData,
  toggle,
  addOrEdit,
  isOpen,
  setIsOpen,
  refetch,
  types,
  isFetching,
  setShowAlert,
  showAlert,
  setAlertMessage,
  alertMessage,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [createCategory, { isLoading: isAddLoading }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: isEditLoading }] = useUpdateCategoryMutation();
  const isSaving = isEditLoading || isAddLoading;

  const { data } = useGetParentCategoriesOptionsQuery(modifiedData?.id, {
    skip: modifiedData?.bundle !== "point" && !modifiedData?.id,
  });

  const colorPickerRef = useRef();

  // State for multi-select columns
  const [leftColumns, setLeftColumns] = useState([]);
  const [rightColumns, setRightColumns] = useState([]);
  const [leftColumnSearchValue, setLeftColumnSearchValue] = useState("");
  const [rightColumnSearchValue, setRightColumnSearchValue] = useState("");

  useEffect(() => {
    if (isOpen && data?.options) {
      const left = data.options.filter((option) => option.parentId === null);
      const right = data.options.filter((option) => option.parentId !== null);
      setLeftColumns(left);
      setRightColumns(right);
      setErrors({});
      setModifiedData((prev) => ({
        ...prev,
        childIds: right.map((option) => option.value), // Initialize childIds with values from the right column
      }));
    }
  }, [isOpen, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateFields = () => {
    const newErrors = {};

    if (!modifiedData.bundle) newErrors.bundle = true;
    if (!modifiedData.title) newErrors.title = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleSaveCategory = async (type) => {
    if (!validateFields()) return;

    let response;
    const payload = { ...modifiedData, childIds: rightColumns.map((option) => option.value) };
    // Set childIds to an empty array if no options are in the right column
    if (!rightColumns.length) {
      payload.childIds = [];
    }

    if (type === "add") {
      response = await createCategory(payload);
    } else {
      response = await updateCategory(payload);
    }
    if (response && !response.error) {
      Success(
        t("caymland.core.success"),
        `${
          type === "add"
            ? t("caymland.category.notice.created", {
                name: modifiedData.title,
                id: response.data.category.id,
              })
            : t("caymland.category.notice.updated", {
                name: modifiedData.bundle,
              })
        }`
      ).then((result) => {
        if (result.isConfirmed) {
          setModifiedData({
            bundle: "",
            title: "",
            description: "",
            alias: "",
            color: "#000000",
            published: false,
            childIds: [], // Reset childIds after saving
          });
          setIsOpen(false);
          if (type !== "add") refetch();
        }
      });
    } else {
      Error(t("caymland.message.error"));
    }
  };

  const handleColorChange = (color) => {
    setModifiedData((prev) => ({
      ...prev,
      color: color?.hex,
    }));
  };

  const handleToggle = (e) => {
    setModifiedData((props) => ({
      ...props,
      isPublished: e.target.checked,
    }));
  };

  const moveItem = (item, direction) => {
    if (direction === "right") {
      setRightColumns((prev) => {
        const newRight = [...prev, item];
        setModifiedData((data) => ({
          ...data,
          childIds: newRight.map((option) => option.value), // Update childIds
        }));
        return newRight;
      });
      setLeftColumns((prev) => prev.filter((i) => i.value !== item.value));
    } else {
      setLeftColumns((prev) => {
        const newLeft = [...prev, item];
        setModifiedData((data) => ({
          ...data,
          childIds: rightColumns.filter((i) => i.value !== item.value).map((option) => option.value), // Update childIds
        }));
        return newLeft;
      });
      setRightColumns((prev) => prev.filter((i) => i.value !== item.value));
    }
  };

  const handleSelectAllColumns = () => {
    setRightColumns([...rightColumns, ...leftColumns]);
    setModifiedData((data) => ({
      ...data,
      childIds: [...rightColumns, ...leftColumns].map((option) => option.value), // Set all childIds
    }));
    setLeftColumns([]);
  };

  const handleDeselectAllColumns = () => {
    setLeftColumns([...leftColumns, ...rightColumns]);
    setModifiedData((data) => ({
      ...data,
      childIds: [], // Reset childIds
    }));
    setRightColumns([]);
  };

  const handleLeftColumnSearchChange = (e) => {
    setLeftColumnSearchValue(e.target.value);
  };

  const handleRightColumnSearchChange = (e) => {
    setRightColumnSearchValue(e.target.value);
  };

  return (
    <Modal
      size="lg"
      style={{
        maxWidth: "600px",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      className="category-modal"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={true}
    >
      <form>
        <ModalHeader>
          <h3>
            {addOrEdit === "Add"
              ? t("caymland.category.header.new")
              : t("caymland.category.header.edit", {
                  name: modifiedData.title,
                  type: modifiedData.title,
                })}
          </h3>
        </ModalHeader>
        <ModalBody>
          <div className="form-row px-2 pb-3 pt-3">
            {addOrEdit === "Add" ? (
              <Select
                name="validationDefault04"
                required={true}
                label={t("caymland.core.type")}
                options={types}
                getOptionLabel="value"
                errors={errors.bundle}
                value={types.find((c) => c.value === modifiedData?.bundle)}
                onChange={(e) => {
                  setModifiedData((props) => ({
                    ...props,
                    bundle: e ? e.value : null,
                  }));
                }}
              />
            ) : null}
            {addOrEdit === "Edit" && modifiedData.bundle === "point" && data?.type === "multiselect" ? (
              <div className="col-12 px-3" style={{ marginRight: "30px" }}>
                <span style={{ fontSize: "13px" }}>{t("caymland.core.children.category")}</span>

                <div className="d-flex">
                  <div className="w-50">
                    <div className="flex flex-col gap-10 w-100">
                      <div className="input-container ic1 mb-10px">
                        <Text
                          name={t("caymland.core.fields.export.label")}
                          value={leftColumnSearchValue}
                          onChange={handleLeftColumnSearchChange}
                          placeholder={t("caymland.core.multiselect.search")}
                        />
                      </div>
                    </div>

                    <FormControl as="select" multiple style={{ height: "200px" }}>
                      {leftColumns
                        .filter((option) => option.label.toLowerCase().includes(leftColumnSearchValue.toLowerCase()))
                        .map((option) => (
                          <option
                            key={option.value}
                            value={option.alias}
                            style={{ fontSize: "14px" }}
                            onClick={() => moveItem(option, "right")}
                          >
                            {option.label}
                          </option>
                        ))}
                    </FormControl>

                    <div className="float-right">
                      <Link className="cursor-pointer" onClick={handleSelectAllColumns}>
                        {t("caymland.core.select.all")}
                      </Link>
                    </div>
                  </div>
                  <RiArrowLeftRightFill size={35} className="align-self-center" />
                  <div className="w-50" style={{ marginLeft: "10px" }}>
                    <div className="flex flex-col gap-10 w-100">
                      <div className="input-container ic1 mb-10px">
                        <Text
                          name="Select fields"
                          value={rightColumnSearchValue}
                          onChange={handleRightColumnSearchChange}
                          placeholder={t("caymland.core.multiselect.search")}
                        />
                      </div>
                    </div>
                    <FormControl as="select" multiple style={{ height: "200px" }}>
                      {rightColumns
                        .filter((option) => option.label.toLowerCase().includes(rightColumnSearchValue.toLowerCase()))
                        .map((option) => (
                          <option
                            style={{ fontSize: "14px" }}
                            key={option.value}
                            value={option.alias}
                            onClick={() => moveItem(option, "left")}
                          >
                            {option.label}
                          </option>
                        ))}
                    </FormControl>

                    <Link className="cursor-pointer" onClick={handleDeselectAllColumns}>
                      {t("caymland.core.deselect.all")}
                    </Link>
                  </div>
                </div>
              </div>
            ) : addOrEdit === "Edit" && modifiedData.bundle == "point" && data?.type == "select" ? (
              <Select
                name="parentId"
                label={t("caymland.core.parent.category")}
                options={data?.options}
                value={modifiedData.parentId}
                onChange={(e) => {
                  setModifiedData((data) => ({
                    ...data,
                    parentId: e.value,
                  }));
                }}
              />
            ) : null}
            <Text
              name="title"
              value={modifiedData?.title}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.title")}
              required={true}
              wFull="w-100"
              errors={errors.title}
            />
            <Text
              name="description"
              value={modifiedData?.description}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.description")}
              wFull="w-100"
            />
            <Text
              name="alias"
              value={modifiedData?.alias}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.alias")}
              wFull="w-100"
              tooltip={t("caymland.category.form.alias.help")}
            />
            <div className="w-full" ref={colorPickerRef}>
              <ColorPicker
                type="text"
                id="colorInput"
                name="colorInput"
                value={modifiedData?.color || "#000000"}
                onChange={handleColorChange}
                colorPickerRef={colorPickerRef}
                label={t("caymland.core.color")}
                wFull="w-full"
              />
            </div>
            <div className="w-100" style={{ marginBottom: "20px" }}>
              <Checkbox
                name="isPublished"
                onChange={(e) => handleToggle(e)}
                label={t("caymland.core.form.published")}
                value={modifiedData.isPublished}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            loading={isSaving}
            buttons={[
              {
                name: "close",
                title: t("caymland.core.form.cancel"),
                icon: <ImCancelCircle size={15} />,
                onClick: () => toggle(),
              },
              {
                name: "save",
                title: t("caymland.core.form.apply"),
                icon: <TbDeviceFloppy size={15} />,
                onClick: () => handleSaveCategory(addOrEdit === "Add" ? "add" : "edit"),
              },
            ]}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};
