import React, { Fragment, useMemo, useState } from "react";
import CustomTable from "../../../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

function EmailStats(extras) {
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "ASC",
    orderBy: "id",
    where: [],
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "label",
        accessorFn: (row) => row.label,
        header: t("caymland.core.label"),
        size: 150,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "value",
        accessorFn: (row) => row.value,
        header: t("caymland.core.value"),
        size: 150,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {
          let extractedValue = "";

          if (row.original.link) {
            const linkParts = row.original.link.split("=");
            extractedValue = linkParts.length > 1 ? decodeURIComponent(linkParts[1]) : "";
          }

          return (
            <div
              style={{
                cursor: "pointer",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
              title={extractedValue}
            >
              {row.original.link && row.original.link !== "/" ? (
                <div
                  style={{
                    color: "#135f95",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/contacts?search=${encodeURIComponent(extractedValue)}`, "_blank");
                  }}
                >
                  {row.original.value}
                </div>
              ) : (
                <span>{row.original.value}</span>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "percentage",
        accessorFn: (row) => row.percentage,
        header: t("caymland.core.percentage"),
        size: 150,
        enableColumnActions: false,
        enableSorting: false,
      },
    ],
    [navigate]
  );

  return (
    <Fragment>
      <CustomTable
        columnsClicks={columns}
        data={extras?.extras?.stats || []}
        query={query}
        setQuery={setQuery}
        linkNavigate={null}
        hideActionButtons={true}
        pagination={false}
        searchHelper={false}
        showGlobalFilter={false}
      />
    </Fragment>
  );
}

export default EmailStats;
