import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AirplaneTicketOutlinedIcon from "@mui/icons-material/AirplaneTicketOutlined";
import OptionIcon from "@mui/icons-material/AssignmentTurnedIn";
import OfferIcon from "@mui/icons-material/LocalOffer";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import OccupancyIcon from "@mui/icons-material/Hotel";
import { t } from "i18next";
import moment from "moment";

// Group reservations by ReservationInternalType
const groupReservationsByType = (reservations) => {
  return reservations.reduce((groups, reservation) => {
    const type = reservation.ReservationInternalType;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(reservation);
    return groups;
  }, {});
};

// Function to format keys by adding space before capital letters
// const formatKey = (key) => {
//   return key.replace(/([a-z])([A-Z])/g, "$1 $2"); // Adds a space before each capital letter
// };

const ReservationsAccordion = ({ reservations, number }) => {
  // Move ReservationInternalTypeMapping inside the component
  const ReservationInternalTypeMapping = {
    0: {
      label: t("caymland.tomas.custom.fields.buchung"),
      icon: <AirplaneTicketOutlinedIcon style={{ fontSize: "28px" }} />,
    },
    1: { label: t("caymland.tomas.custom.fields.option"), icon: <OptionIcon style={{ fontSize: "28px" }} /> },
    3: { label: t("caymland.tomas.custom.fields.angebot"), icon: <OfferIcon style={{ fontSize: "28px" }} /> },
    5: {
      label: t("caymland.tomas.custom.fields.prospektanfrage"),
      icon: <LibraryBooksOutlinedIcon style={{ fontSize: "28px" }} />,
    },
    7: { label: t("caymland.tomas.custom.fields.eigenbelegung"), icon: <OccupancyIcon style={{ fontSize: "28px" }} /> },
  };

  // Move ReservationStateMapping inside the component
  const ReservationStateMapping = {
    0: { label: t("caymland.tomas.custom.fields.test"), color: "#04B76B" },
    1: { label: t("caymland.tomas.custom.fields.aktiv"), color: "#04B76B" },
    2: { label: t("caymland.tomas.custom.fields.abgeschlossen"), color: "#fdb933" },
    3: { label: t("caymland.tomas.custom.fields.storniert"), color: "f86b4f" },
  };

  // Filter reservations based on ProcessNumber
  const filteredReservations = reservations.filter((reservation) => reservation.ProcessNumber === number);

  if (!filteredReservations || filteredReservations.length === 0) {
    return <Typography>{t("No reservations available")}</Typography>;
  }

  // Group reservations by ReservationInternalType
  const groupedReservations = groupReservationsByType(filteredReservations);

  return (
    <div>
      {Object.keys(groupedReservations).map((type) => {
        const { label, icon } = ReservationInternalTypeMapping[type] || { label: "Unknown", icon: <ExpandMoreIcon /> };
        const reservationsOfType = groupedReservations[type];

        return (
          <div key={type} style={styles.typeGroup}>
            {/* Group Title */}
            <div style={styles.groupTitle}>
              {icon}
              <Typography variant="h6" style={styles.groupLabel}>
                {label}
              </Typography>
            </div>

            {/* Accordion for each reservation */}
            <div style={styles.gridContainer}>
              {reservationsOfType.map((reservation) => {
                const reservationState = reservation.ReservationState;
                const stateColor = ReservationStateMapping[reservationState]?.color || "inherit"; // Default to "inherit" if no mapping

                return (
                  <div key={reservation.ProcessPartNumber} style={styles.gridItem}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${reservation.ProcessPartNumber}-content`}
                        id={`panel-${reservation.ProcessPartNumber}-header`}
                      >
                        <Typography style={{ color: stateColor }}>{reservation.ProcessPartNumber}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={styles.tableContainer}>
                          {/* Table Headers */}
                          <div style={styles.tableRow}>
                            <div style={styles.tableHeaderField}>Field</div>
                            <div style={styles.tableHeader}>Value</div>
                          </div>

                          {/* Dynamically display key-value pairs */}
                          {Object.entries(reservation)
                            .filter(([key, value]) => value !== null && value !== undefined && value !== "") // Filter out empty values
                            .map(([key, value]) => (
                              <div key={key} style={styles.tableRow}>
                                <div style={styles.tableCell}>
                                  <strong>{t(`caymland.reservation.table.info.${key}`)}</strong> {/* Format the key */}
                                </div>
                                <div style={styles.tableCell}>
                                  {key === "ReservationState" ? (
                                    <span style={{ color: ReservationStateMapping[value]?.color }}>
                                      {ReservationStateMapping[value]?.label || value}
                                    </span>
                                  ) : key.includes("Date") ? (
                                    moment(value).format("DD.MM.YYYY HH:mm") // Format date values
                                  ) : typeof value === "object" && value !== null ? (
                                    JSON.stringify(value) // Handle object values
                                  ) : (
                                    value.toString() // Handle primitive values
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

// Define the styles for grid layout and accordion details
const styles = {
  typeGroup: {
    marginBottom: "20px",
    border: "2px solid #ccc", // Border around each group
    borderRadius: "8px",
    padding: "15px",
  },
  groupTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  groupLabel: {
    marginLeft: "10px",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 items per row
    gap: "10px", // Space between items
  },
  gridItem: {
    width: "100%",
    // maxWidth: "400px", // Limit accordion width
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column", // Stack rows vertically
    width: "100%", // Make sure it takes the full width of the container
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between", // Align field and value
  },
  tableHeader: {
    fontWeight: "bold", // Make headers bold
    width: "50%", // Each column takes 50% of the row
    textAlign: "left", // Align text to the left for header
    border: "1px solid lightgray",
    padding: "8px", // Add padding to cells
    background: "#f3f3f3",
  },
  tableHeaderField: {
    fontWeight: "bold", // Make headers bold
    width: "50%", // Each column takes 50% of the row
    textAlign: "left", // Align text to the left for header
    border: "1px solid lightgray",
    padding: "8px", // Add padding to cells
    background: "#f3f3f3",
  },
  tableCell: {
    width: "50%", // Each column takes 50% of the row
    textAlign: "left", // Align text to the left for data
    padding: "8px", // Add padding to cells
    border: "1px solid lightgray",
    fontSize: "12px",
  },
};

export default ReservationsAccordion;
