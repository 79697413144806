import { Button } from "@mui/material";
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineSave } from "react-icons/ai";
import { t } from "i18next";

export default function ({ action, actions: { handleNodeAdd }, handleSubmit, handleCancel, reset, defaultValues }) {
  return (
    <Button
      style={{ margin: "4px", border: "1px solid rgba(19, 95, 149, 0.5)" }}
      startIcon={action === "edit" ? <AiOutlineSave size={15} /> : <BiAddToQueue size={15} />}
      onClick={handleSubmit((data) => {
        handleNodeAdd(data);
        handleCancel();
        reset(defaultValues);
      })}
    >
      {action === "edit" ? t("caymland.core.form.update") : t("caymland.core.form.add")}
    </Button>
  );
}
