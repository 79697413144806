import React, { useEffect, useState } from "react";
import { languages } from "../../../../reusableComponents/data";
import Button from "../../../../reusableComponents/Buttons/Button";
import { Label, Text, Select as ReusSelect, Checkbox, DatePicker } from "../../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";
import { categoriesApi, useAddCategoryMutation } from "../../../../../redux/api/categoriesApi";
import { useGetEmailsQuery } from "../../../../../redux/api/emailsApi";
import { segmentsApi } from "../../../../../redux/api/segmentsApi";
import { t } from "i18next";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";

function Sidebar({ modifiedData, setModifiedData, errors, setErrors, setOriginalData, originalData }) {
  const [translations, setTranslations] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState();
  const [selectLists, setSelectLists] = useState([]);

  console.log(modifiedData);
  console.log(selectedTranslation);

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "email",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const [q, setQ] = useState({
    pageIndex: 0,
    pageSize: 1000,
    orderByDir: "ASC",
    orderBy: "id",
    where: [],
  });
  const [triggerSegmentsQuery] = segmentsApi.useLazyGetSegmentsQuery();
  const { data } = useGetEmailsQuery(q);

  //function for getting conact segments from api
  function isArrayContainingObject(array) {
    return array.some((element) => element !== null && typeof element === "object" && !Array.isArray(element));
  }
  useEffect(() => {
    if (Array.isArray(modifiedData.lists) && isArrayContainingObject(modifiedData.lists)) {
      const formattedLists = modifiedData.lists.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setSelectLists(formattedLists);

      // Update modifiedData.lists with just the IDs
      const listIds = formattedLists.map((item) => item.value);
      if (JSON.stringify(listIds) !== JSON.stringify(modifiedData.lists)) {
        setModifiedData((prev) => ({
          ...prev,
          lists: listIds,
        }));
        setOriginalData((prev) => ({
          ...prev,
          lists: listIds,
        }));
      }
    }
  }, [modifiedData?.lists, originalData?.lists]);
  const handleSelectChangee = (selectedOptions) => {
    setSelectLists(selectedOptions);
    const updatedLists = selectedOptions.map((option) => option.value);
    setModifiedData((prev) => ({
      ...prev,
      lists: updatedLists,
    }));
  };

  //Segments
  const retrieveSegments = (inputValue) =>
    new Promise(async (resolve) => {
      if (!triggerSegmentsQuery) resolve([]);

      const query = {
        where: [
          {
            expr: "eq",
            col: "isPublished",
            val: 1,
          },
        ],
      };

      if (inputValue.length) {
        query.search = inputValue;
      }
      const response = await triggerSegmentsQuery(query).unwrap();

      if (!response?.errors) {
        const segmentsData = response?.data;
        const segmentsArray = segmentsData?.map((field) => ({
          label: field?.name,
          value: field?.id,
        }));

        resolve(segmentsArray);
      }
    });

  // Use useEffect to update options when modifiedData.language or other dependencies change
  useEffect(() => {
    if (data?.data) {
      let response = data?.data;
      let groupList = [];
      setTranslations([]);

      response
        .filter(
          (item) =>
            !item.translationParent &&
            item.emailType === modifiedData.emailType &&
            item.id !== modifiedData.id &&
            item.language !== modifiedData.language
        )
        .forEach((item) => {
          const groupKey = item.language;
          if (!groupList[groupKey]) {
            groupList[groupKey] = [];
          }
          groupList[groupKey].push({
            label: item.name,
            value: item.id,
          });
        });

      const groupedOptions = Object.keys(groupList).map((key) => ({
        label: key,
        options: groupList[key],
      }));

      setTimeout(() => {
        setTranslations(groupedOptions);
      }, 1000);
    }
  }, [modifiedData.language, data]);

  useEffect(() => {}, [translations]);
  console.log(translations);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name" || name === "subject") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }

    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSelectChangeCategory = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  useEffect(() => {
    // Reset selectedTranslation when translations change to ensure dropdown refresh
    setSelectedTranslation(null);
  }, [translations]);

  const handleSelectChange = (e, name) => {
    if (e === null) {
      setModifiedData({ ...modifiedData, [name]: "" });
    } else {
      setModifiedData({ ...modifiedData, [name]: e });
    }
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData({
      ...modifiedData,
      [name]: checked,
    });
  };

  //Is a translation of
  const handleTranslationOfChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.value;
    const isStaticOption = selectedValues === "staticOption";
    const parentKey = modifiedData.emailType === "template" ? "templateTranslationParent" : "segmentTranslationParent";

    if (isStaticOption) {
      setSelectedTranslation(selectedValues);
      setModifiedData({
        ...modifiedData,
        [parentKey]: null,
      });
    } else {
      setSelectedTranslation(selectedValues);
      setModifiedData({
        ...modifiedData,
        [parentKey]: selectedValues,
      });
    }
  };

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Label
        name="chooseEmailType"
        required={true}
        label={t("caymland.email.send.emailtype")}
        tooltip={t("caymland.email.type.template.description")}
      />

      <div className="flex items-center justify-center w-full" style={{ marginBottom: "25px" }}>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "campaignEmail",
                title: `${t("caymland.campaign.campaign")} ${t("caymland.email.email")}`,
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    emailType: "template",
                    lists: null,
                    segmentTranslationParent: null,
                    templateTranslationParent: null,
                  });
                },
              },
            ]}
            variant={modifiedData?.emailType === "template" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "segmentEmail",
                title: `${t("caymland.email.report.segment")} ${t("caymland.email.email")}`,
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    emailType: "list",
                    segmentTranslationParent: null,
                    templateTranslationParent: null,
                  });
                },
              },
            ]}
            variant={modifiedData?.emailType === "list" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
      </div>
      <div className="mb-20px">
        <Text
          name="subject"
          errors={errors?.subject}
          required={true}
          value={modifiedData?.subject || ""}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.mailbox.compose.label.subject")}
        />
      </div>
      <div className="mb-20px">
        <Text
          name="name"
          errors={errors?.name}
          required={true}
          value={modifiedData?.name || ""}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.email.form.internal.name")}
        />
      </div>
      {modifiedData?.emailType === "list" &&
        !modifiedData?.templateTranslationParent &&
        !modifiedData?.segmentTranslationParent &&
        modifiedData.translationParent !== "null" && (
          <div style={{ marginBottom: "15px" }}>
            <ReusSelect
              name="lists"
              label={t("caymland.email.form.list")}
              errors={errors?.lists}
              isMulti={true}
              isClearable
              required={true}
              options={retrieveSegments}
              value={selectLists}
              onChange={handleSelectChangee}
            />
          </div>
        )}

      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="category"
          bundle="email"
          label={t("caymland.page.tracking.form.category")}
          options={retrieveCategories}
          trigger={triggerCategories}
          value={modifiedData?.category ?? null}
          onChange={(e) => handleSelectChangeCategory(e)}
          isClearable
          isCreatable
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="language"
          label={t("caymland.core.language")}
          options={languages.map((lang) => ({ label: t(lang.labelKey), value: lang.value }))}
          value={modifiedData?.language}
          onChange={(e) => handleSelectChange(e.value, "language")}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <ReusSelect
          tooltip={t("caymland.core.form.translation_parent.help")}
          label={t("caymland.core.form.translation_parent")}
          name="isTranslation"
          options={translations}
          value={translations
            .flatMap((group) => group.options)
            .find(
              (option) =>
                option.value === (selectedTranslation ? selectedTranslation : modifiedData?.translationParent?.id)
            )}
          placeholder={`${t("caymland.core.select")}...`}
          onChange={(selectedOptions) => handleTranslationOfChange(selectedOptions)}
        />
      </div>
      <div>
        <Checkbox
          name="isPublished"
          label={t("caymland.core.form.published")}
          value={modifiedData?.isPublished || false}
          onChange={(e) => handleToggleChange(e)}
        />
      </div>
      <div>
        <Checkbox
          name="Double-Opt-In"
          label={t("caymland.email.double_opt_in")}
          value={modifiedData?.doubleOptIn || false}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              doubleOptIn: e.target.checked,
            })
          }
          tooltip={t("caymland.email.double_opt_in.tooltip")}
        />
      </div>
      <div>
        <Checkbox
          name="ignoreDNC"
          label={t("caymland.email.ignore_dnc")}
          value={modifiedData?.ignoreDNC || false}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              ignoreDNC: e.target.checked,
            })
          }
          tooltip={t("caymland.email.ignore_dnc.tooltip")}
        />
      </div>
      <div>
        <Checkbox
          name="scheduleEmail"
          label={t("caymland.email.publish_command")}
          value={modifiedData?.scheduleEmail || false}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              scheduleEmail: e.target.checked,
            })
          }
          tooltip={t("caymland.email.publish_command.help")}
        />
        {modifiedData?.scheduleEmail && (
          <>
            <div className="warning-text p-no-margin" style={{ display: "block", position: "relative" }}>
              <p>{t("caymland.email.publish_command.help")}</p>
            </div>
            <div className=" mt-10">
              <DatePicker
                label={t("caymland.core.form.publishup")}
                name={"Publish at (date/time)"}
                value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
                onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
                maxDate={modifiedData?.publishDown}
              />
            </div>
            <div className="">
              <DatePicker
                label={t("caymland.core.form.publishdown")}
                name={"Unpublish at (date/time)"}
                value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
                onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
                minDate={modifiedData?.publishUp}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Sidebar;
