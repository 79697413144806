import React from "react";
import Builder from "./Builder";
import { useLocation } from "react-router-dom";

function Details({ errors, control, nodes, forms, actions, eventFormReset, watch }) {
  const location = useLocation();

  const isNew = location.pathname.includes("new");
  return <Builder nodesData={nodes} forms={forms} actions={actions} eventFormReset={eventFormReset} isNew={isNew} />;
}

export default Details;
