import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Products"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getProducts: build.query({
      query: (q) =>
        `/api/brochures/products?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Products"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addProduct: build.mutation({
      query: (body) => ({
        url: `api/brochures/products/new`,
        method: "POST",
        body,
      }),
    }),
    updateProduct: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/brochures/products/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Products"],
    }),
    getProduct: build.query({
      query: (id) => `/api/brochures/products/${id}`,
      providesTags: (id) => [{ type: "Products", id }],
      lazy: true,
    }),
    deleteProduct: build.mutation({
      query: (id) => ({
        url: `/api/brochures/products/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),
    reorderProductFields: build.mutation({
      query: ({ fieldOrder }) => ({
        url: `/api/brochures/products/reorder`,
        method: "POST",
        body: fieldOrder,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      invalidatesTags: (result, error, { objectId }) => [{ type: "Products", id: fieldOrder }],
    }),

    //FOLDERS
    //GET FOLDERS
    getFolderProducts: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=brochures.products`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderProducts: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderProducts: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Products", id }],
    }),
    //DELETE FOLDER
    deleteFolderProducts: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Products", id }],
    }),
    //MOVE EMAIL TO FOLDER
    updateProductsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/bproducts/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE EMAIL TO FOLDER
    removeProductsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/bproducts/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useGetProductQuery,
  useDeleteProductMutation,
  useReorderProductFieldsMutation,

  // //FOLDERS
  useGetFolderProductsQuery,
  useUpdateFolderProductsMutation,
  useDeleteFolderProductsMutation,
  useAddFolderProductsMutation,
  useUpdateProductsToFoldersMutation,
  useRemoveProductsToFoldersMutation,
} = productsApi;
