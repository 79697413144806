import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const purposesApi = createApi({
  reducerPath: "purposesApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Purposes"],
  endpoints: (build) => ({
    getPurposes: build.query({
      query: customBaseQuery((q) => `/api/purposes?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Purposes"],
    }),
    getPurposeById: build.query({
      query: (id) => `/api/purposes/${id}`,
      providesTags: (result, error, id) => [{ type: "Purposes", id }],
      lazy: true,
    }),
    addPurpose: build.mutation({
      query: (body) => ({
        url: `api/purposes/new`,
        method: "POST",
        body,
      }),
    }),
    updatePurpose: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/purposes/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Purposes", id }],
    }),
    deletePurpose: build.mutation({
      query: (id) => ({
        url: `/api/purposes/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Purposes", id }],
    }),
    removePurpose: build.mutation({
      query: ({ purposeId, contactId }) => ({
        url: `/api/purposes/${purposeId}/contact/${contactId}/remove`,
        method: "POST",
      }),

      invalidatesTags: [{ type: "Purposes" }],
    }),
  }),
});

export const {
  useGetPurposesQuery,
  useGetPurposeByIdQuery,
  useAddPurposeMutation,
  useUpdatePurposeMutation,
  useDeletePurposeMutation,
  useRemovePurposeMutation,
} = purposesApi;
