import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Error, Success } from "../../reusableComponents/Alerts";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import MainContent from "./mainContent";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import HeaderSkeleton from "../../reusableComponents/TitleHeader";
import { t } from "i18next";
import { useSelector } from "react-redux";
import {
  useAddTemplatesMutation,
  useGetTemplateQuery,
  useUpdateTemplatesMutation,
} from "../../../redux/api/templatesApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function AddEditTemplete(state) {
  const navigate = useNavigate();
  const location = useLocation();
  const ClonePath = location.pathname.includes("clone");
  const id = location?.state?.id || location?.state || useParams().id;

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const { data, isLoading } = useGetTemplateQuery(id, { skip: !id });
  const [addNewTemplate] = useAddTemplatesMutation();
  const [updateTemplates] = useUpdateTemplatesMutation(id);
  const token = useSelector((state) => state?.auth?.token);

  const clientSchema = yup.object({
    name: yup.string().required(),
    language: yup.mixed().required(),
    source: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(clientSchema),
    defaultValues: {
      language: "de", // Set default value here
    },
  });

  useEffect(() => {
    if (id && data?.brochuretemplate) {
      const { brochuretemplate } = data;

      let templatesData = { ...brochuretemplate };
      if (ClonePath) {
        templatesData.isPublished = false;
      }

      reset(templatesData);
    }
  }, [id, data?.brochuretemplate]);

  const handleCancel = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/templates");
      } else {
        return;
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleAddEditTemplates(data, "true")),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleAddEditTemplates(data)),
    },
  ];

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;

  const getUploadParams = async ({ file, meta }) => {
    const body = new FormData();
    body.append("brochures_template[]", file);

    return {
      url: `${api}/api/brochures/templates/upload`,
      method: "POST",
      body,
      headers: { Authorization: `JWT ${token}` },
    };
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (meta.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      setShowAlert(true);
      setAlertMessage(`Attempted to upload an unsupported file type: ${file?.type}`);
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 7000);
      return;
    }

    if (status === "done") {
      try {
        let response = JSON.parse(xhr.response);
        console.log("response", response);
        if (response?.brochuretemplate?.name) {
          setValue("source", response?.brochuretemplate?.name);
        } else {
          setShowAlert(true);
          setAlertMessage("An unexpected file type was processed.");
          setTimeout(() => {
            setAlertMessage("");
            setShowAlert(false);
          }, 7000);
        }
      } catch (e) {
        console.error("Error processing the file", e);
        setShowAlert(true);
        setAlertMessage("Failed to process the file due to an internal error.");
        setTimeout(() => {
          setAlertMessage("");
          setShowAlert(false);
        }, 7000);
      }
    } else if (status === "removed") {
      setValue("source", null);
    }
  };

  const handleAddEditTemplates = async (formData, close = false) => {
    const payload = {
      ...formData,
      language:
        formData.language && typeof formData.language === "object" ? formData.language.value : formData.language,
    };
    try {
      const response =
        formData.id && !formData.ClonePath ? await updateTemplates({ id, ...payload }) : await addNewTemplate(payload);
      console.log("response", response);

      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id && !ClonePath ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed) {
            if (close) {
              navigate(`/templates`);
            } else {
              navigate(`/templates/edit/${response?.data?.brochuretemplate?.id}`);
            }
          }
        });
      } else {
        Error(t("caymland.message.error"));
      }
    } catch (e) {
      Error(t("caymland.message.error"));
    }
  };

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isLoading}
          alertMessage={alertMessage}
          showAlert={showAlert}
          title={!ClonePath && id ? "Edit Template" : "Add Template"}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          <MainContent
            getUploadParams={getUploadParams}
            handleChangeStatus={handleChangeStatus}
            isFetching={isLoading}
            control={control}
            errors={errors}
            watch={watch}
          />
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={<Sidebar isFetching={isLoading} control={control} errors={errors} />}
      />
    </>
  );
}

export default AddEditTemplete;
