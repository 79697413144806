import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { useSelector } from "react-redux";

const CKEditor4 = ({ onChange, name, value, onEditorReady, render }) => {
  const userCurent = useSelector((state) => state.auth.user);

  const [isReady, setIsReady] = useState(false);
  const editorRef = useRef(null);

  const mailboxHeight = 810;
  const caymlandCurrentLang = userCurent.locale.startsWith("de") ? "de" : "en";
  const mailboxDefaultOptions = {
    font: "Arial",
    font_size: 10,
    font_color: "#000000",
  };

  useEffect(() => {
    setIsReady(true);
  }, []);

  const handleEditorInstanceReady = (event) => {
    const editor = event.editor;
    editorRef.current = editor;

    if (onEditorReady) {
      onEditorReady(editor);
    }

    editor.setData(value || "<p></p>");

    editor.on("change", () => {
      const data = editor.getData();
      onChange(data !== "<p>&nbsp;</p>" ? data : "");
    });
  };

  // const handleEditorInstanceReady = (event) => {
  //   editorRef.current = event.editor;
  //   if (onEditorReady) {
  //     onEditorReady(editorRef.current);
  //   }

  //   if (value) {
  //     editorRef.current.setData(value);
  //   }

  //   editorRef.current.on('change', () => {
  //     const data = editorRef.current.getData();
  //     onChange(data !== '<p>&nbsp;</p>' ? data : null);
  //   });
  // };

  // useEffect(() => {
  //   if (editorRef.current) {
  //     if (editorRef.current.getData() !== value) {
  //       editorRef.current.setData(value || '<p></p>');
  //     }
  //   }
  // }, [value]);

  if (!isReady) return null;

  return (
    <div key={render}>
      <style>{`
        .cke_top {
       zoom: 1.2;
        }
      `}</style>
      <CKEditor
        ref={editorRef}
        initData={value}
        name={name}
        onInstanceReady={handleEditorInstanceReady}
        config={{
          versionCheck: false,
          height: mailboxHeight - 420,
          width: "auto",
          language: caymlandCurrentLang,
          allowedContent: true,
          removeButtons: "Image,About",
          fontSize_sizes:
            "8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt",
          font_defaultLabel: mailboxDefaultOptions.font,
          fontSize_defaultLabel: mailboxDefaultOptions.font_size + "pt",
          extraPlugins: "colorbutton,colordialog,font",
        }}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.addCss(
            `.cke_editable{cursor:text; color: ${mailboxDefaultOptions.font_color}; font-size: ${mailboxDefaultOptions.font_size}pt; font-family: ${mailboxDefaultOptions.font};}`
          );
        }}
      />
    </div>
  );
};

export default CKEditor4;
