import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Select } from "../../reusableComponents/Inputs";
import { useForm, Controller } from "react-hook-form";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useChangeStatusMutation } from "../../../redux/api/templatesApi";

const StatusModal = ({ modalOptions, setModalOptions, setShow, setMessage, refetchTableData }) => {
  const statusOptions = [
    {
      label: t("caymland.orders.status.1"),
      value: 1,
    },
    {
      label: t("caymland.orders.status.2"),
      value: 2,
    },
    {
      label: t("caymland.orders.status.3"),
      value: 3,
    },
    {
      label: t("caymland.orders.status.4"),
      value: 4,
    },
  ];

  const closeModal = () => {
    setModalOptions(() => ({
      isOpen: false,
      title: "",
    }));
    reset();
  };

  const [updateStatus] = useChangeStatusMutation();

  const handleStatus = async (data) => {
    const ids = modalOptions.id;
    const status = data.status?.value;

    const payload = {
      brochures_orders_status_batch: {
        ids: JSON.stringify(ids),
        status,
      },
    };
    const response = await updateStatus({ ...payload });
    if (!response.error) {
      closeModal();
      setShow(true);
      setMessage(t("caymland.message.type.successfully", { type: "edited" }));
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
      refetchTableData();
    } else {
      setShow(true);
      setMessage(response.error.data.error.message);
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        status: yup.mixed().nullable(),
      })
    ),
  });
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleStatus(data)),
    },
  ];

  return (
    <Modal show={modalOptions.isOpen && modalOptions.type === "status"} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ padding: "4px", fontSize: "18px" }}>{modalOptions.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Controller
          control={control}
          name="status"
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              label={t("caymland.brochures.status.label")}
              isClearable
              className="w-full"
              value={value}
              options={statusOptions}
              onChange={onChange}
              errors={errors && errors[name]}
            />
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default StatusModal;
