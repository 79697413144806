import React, { useState, useRef, useEffect } from "react";

const LookupInput = ({
  control,
  field,
  onChange,
  name,
  onBlur,
  value,
  style = {
    background: "inherit",
    border: "0",
    width: "100%",
    height: "28px",
    outline: "none",
    padding: "4px",
  },
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(
    Array.isArray(field.properties.list) ? field.properties.list : []
  );
  const containerRef = useRef(null);

  const handleInputChange = (onChange, event) => {
    const inputValue = event.target.value;
    onChange(inputValue);

    // Ensure field.properties.list is an array, then filter
    if (Array.isArray(field.properties.list) && field.properties.list.length > 0) {
      setFilteredOptions(
        field.properties.list.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
      );
    } else {
      setFilteredOptions([]); // Set to an empty array if no options are available
    }

    setShowOptions(true);
  };

  const handleOptionSelect = (onChange, option) => {
    onChange(option);
    setShowOptions(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} key={field.id} className="tw-w-full tw-relative">
      <>
        <input
          style={style}
          onBlur={onBlur}
          name={name}
          type="text"
          value={value}
          onChange={(e) => handleInputChange(onChange, e)}
          placeholder={field.label}
          required={field.isRequired}
          onClick={() => setShowOptions(true)}
        />
        {showOptions && (
          <ul className="tw-absolute tw-bg-white tw-border tw-border-gray-300 tw-rounded-md tw-shadow-md tw-w-full tw-max-h-40 tw-overflow-auto tw-mt-1 tw-z-[999]">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionSelect(onChange, option)}
                  className="tw-py-2 tw-px-3 tw-cursor-pointer hover:tw-bg-blue-100"
                >
                  {option}
                </li>
              ))
            ) : (
              <li className="tw-py-2 tw-px-3 tw-text-gray-500">No options available</li>
            )}
          </ul>
        )}
      </>
    </div>
  );
};

export default LookupInput;
