import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { BiDotsVertical } from "react-icons/bi";
import { arrayMoveImmutable } from "array-move";
import { Text, TextArea, Select, Label } from "../../../../reusableComponents/Inputs";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import { assetsApi } from "../../../../../redux/api/assetsApi";
import { t } from "i18next";
import { formsApi } from "../../../../../redux/api/formsApi";

const SortableItem = SortableElement(({ value, index, onChange, onRemove, rowIndex }) => (
  <div index={rowIndex} key={index} className="d-flex" style={{ marginBottom: "5px" }}>
    <button
      type="button"
      onClick={() => onRemove(value)}
      className="result-component"
      style={{
        height: "40px",
        width: "80px",
      }}
    >
      X
    </button>
    <div className="mb-1" style={{ height: "28px", width: "100%" }}>
      <Text name="label" value={value.label || ""} onChange={(e) => onChange(e, rowIndex, "label")} label="Label" />
    </div>
    <div className="mb-1" style={{ height: "28px", width: "100%" }}>
      <Text name="value" value={value.value || ""} onChange={(e) => onChange(e, rowIndex, "value")} label="Value" />
    </div>

    <button type="button" className="result-component" style={{ height: "40px", width: "80px" }}>
      <span>
        <BiDotsVertical size={12} />
      </span>
    </button>
  </div>
));

const SortableList = SortableContainer(({ items, onChange, onRemove }) => {
  return (
    <div>
      {Array.isArray(items) &&
        items.map((value, index) => (
          <SortableItem
            value={value}
            rowIndex={index}
            index={index}
            key={value.id}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
});

function Advanced({ modifiedData, setModifiedData, pagesOptions }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModifiedData({ ...modifiedData, [name]: value });
  };

  const [triggerAssetsQuery] = assetsApi.useLazyGetAssetsQuery();
  const [triggerFormsQuery] = formsApi.useLazyGetFormsQuery();

  const retrieveForms = (inputValue) =>
    new Promise(async (resolve) => {
      if (!triggerFormsQuery) resolve([]);
      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }
      const response = await triggerFormsQuery(query).unwrap();
      const data = response?.data;
      if (data) {
        let arr = Object?.entries(data)?.map((x) => x[1]);
        resolve(arr);
      }
    });

  const retrieveAssets = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerAssetsQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerAssetsQuery(query).unwrap();
      const data = response?.data;
      resolve(data);
    });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let arr = arrayMoveImmutable(modifiedData.headers, oldIndex, newIndex);
    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }

    setModifiedData({ ...modifiedData, headers: arr });
  };

  const handleOptionsChange = (e, index, type) => {
    const { name, value } = e.target;
    const list = [...modifiedData.headers];

    list[index][type] = value;
    setModifiedData((prev) => ({
      ...prev,
      headers: list,
    }));
  };

  const handleOptionsRemove = (item) => {
    setModifiedData((prev) => ({
      ...prev,
      headers: modifiedData.headers.filter((x) => x.id !== item.id),
    }));
  };

  const handleOptionsAdd = () => {
    let id = Math.floor(Math.random() * 10000000) + 1;
    let arr = modifiedData.headers;

    if (!Array.isArray(arr)) {
      arr = [];
    }

    arr.push({
      id: id,
      label: "",
      value: "",
      position: arr.length + 1,
    });

    setModifiedData((prev) => ({
      ...prev,
      headers: arr,
    }));
  };

  const handleUtmChange = (e) => {
    const { name, value } = e.target;

    setModifiedData((prev) => ({
      ...prev,
      utmTags: {
        ...prev.utmTags,
        [name]: value,
      },
    }));
  };

  return (
    <div className="grid" style={{ gap: "50px" }}>
      <div className="flex gap-30 mt-10 items-start">
        <div className="grid " style={{ flex: 1 }}>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="fromName"
              value={modifiedData?.fromName || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.from_name")}
              tooltip={t("caymland.email.from_name.tooltip")}
              iconStart={<PersonIcon sx={{ fontSize: 22 }} />}
            />
          </div>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="fromAddress"
              value={modifiedData?.fromAddress || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.from_email")}
              tooltip={t("caymland.email.from_email.tooltip")}
              iconStart={<MailIcon sx={{ fontSize: 22 }} />}
            />
          </div>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="replyToAddress"
              value={modifiedData?.replyToAddress || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.reply_to_email")}
              tooltip={t("caymland.email.reply_to_email.tooltip")}
              iconStart={<MailIcon sx={{ fontSize: 22 }} />}
            />
          </div>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="bccAddress"
              value={modifiedData?.bccAddress || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.bcc")}
              tooltip={t("caymland.email.bcc.tooltip")}
              iconStart={<MailIcon sx={{ fontSize: 22 }} />}
            />
          </div>

          <div className="small-editor">
            <TextArea
              label={t("caymland.email.form.previewtext")}
              tooltip={t("caymland.email.bcc.tooltip")}
              value={modifiedData?.previewText ?? ""}
              onChange={(data) => {
                setModifiedData((prev) => ({
                  ...prev,
                  previewText: data,
                }));
              }}
            />
          </div>
          <div style={{ marginTop: "-10px" }}>
            <Select
              name="assets"
              label={t("caymland.mailbox.compose.label.attachments")}
              options={retrieveAssets}
              labelField="title"
              valueField="id"
              value={modifiedData?.assetAttachments || ""}
              onChange={(e) => {
                if (e === null) {
                  setModifiedData({ ...modifiedData, assetAttachments: [] });
                } else {
                  setModifiedData({
                    ...modifiedData,
                    assetAttachments: e,
                  });
                }
              }}
            />
          </div>
          <div>
            <Select
              name="language"
              label={t("caymland.email.form.unsubscribeform")}
              options={retrieveForms}
              value={modifiedData?.unsubscribeForm}
              valueField="id"
              labelField="name"
              onChange={(e) => {
                if (e === null) {
                  setModifiedData({ ...modifiedData, unsubscribeForm: null });
                } else {
                  setModifiedData({ ...modifiedData, unsubscribeForm: e });
                }
              }}
              tooltip={t("caymland.email.form.unsubscribeform.tooltip")}
            />
          </div>
          <div>
            <Select
              name="language"
              label={t("caymland.email.form.preference_center")}
              options={pagesOptions}
              value={modifiedData?.preferenceCenter}
              onChange={(e) => {
                setModifiedData((prev) => ({
                  ...prev,
                  preferenceCenter: e.value,
                }));
              }}
              tooltip={t("caymland.email.form.preference_center.tooltip")}
            />
          </div>
          <div
            style={{
              borderTop: "1px solid #d7d7d7",
              padding: "20px 0",
              marginTop: "10px",
            }}
          >
            <span style={{ fontSize: "20px" }}>{t("caymland.email.utm_tags")}</span>
            <div className="mb-30" style={{ marginTop: "30px" }}>
              <Text
                name="utmSource"
                value={modifiedData?.utmTags?.utmSource || ""}
                onChange={(e) => handleUtmChange(e)}
                label={t("caymland.email.campaign_source")}
              />
            </div>
            <div className="mb-30" style={{ marginTop: "30px" }}>
              <Text
                name="utmMedium"
                value={modifiedData?.utmTags?.utmMedium || ""}
                onChange={(e) => handleUtmChange(e)}
                label={t("caymland.email.campaign_medium")}
              />
            </div>
            <div className="input-container ic1 mb-30" style={{ marginTop: "30px" }}>
              <Text
                name="utmCampaign"
                value={modifiedData?.utmTags?.utmCampaign || ""}
                onChange={(e) => handleUtmChange(e)}
                label={t("caymland.email.campaign_name")}
              />
            </div>
            <div className="input-container ic1 mb-30" style={{ marginTop: "30px" }}>
              <Text
                name="utmContent"
                value={modifiedData?.utmTags?.utmContent || ""}
                onChange={(e) => handleUtmChange(e)}
                label={t("caymland.email.campaign_content")}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className="flex items-center p-no-margin gap-5px">
            <Label
              name="customHeaders"
              label={t("caymland.email.custom_headers")}
              tooltip={t("caymland.email.custom_headers.tooltip")}
            />
          </div>
          <button type="button" onClick={handleOptionsAdd} className="addOptions-btn">
            <span>{`${t("caymland.core.form.add")} ${t("caymland.core.value")}`}</span>
          </button>
          <SortableList
            items={modifiedData?.headers}
            onSortEnd={onSortEnd}
            onChange={handleOptionsChange}
            onRemove={handleOptionsRemove}
            axis={"y"}
          />
        </div>
      </div>
    </div>
  );
}

export default Advanced;
