import React, { useEffect, useState } from "react";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import { Select } from "../../reusableComponents/Inputs";
import { t } from "i18next";
import { templatesApi } from "../../../redux/api/templatesApi";
import { usersApi } from "../../../redux/api/usersApi";
import { Controller } from "react-hook-form";
import i18next from "i18next";
import { useSelector } from "react-redux";

const Sidebar = ({ isFetching, control, errors, rerender, setValue }) => {
  const userCurent = useSelector((state) => state.auth.user);
  const [triggerTemplatesQuery] = templatesApi.useLazyGetTemplatesQuery();
  const [triggerUserQuery] = usersApi.useLazyGetUsersQuery();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState();

  useEffect(() => {
    i18next.on("loaded", () => {
      setLoading(false);
    });

    if (i18next.isInitialized) {
      setLoading(false);
    }

    return () => {
      i18next.off("loaded");
    };
  }, []);

  useEffect(() => {
    if (templates && userCurent) {
      const foundTemplate = templates?.find((template) => template.id === userCurent.template);
      if (foundTemplate) {
        setValue("template", foundTemplate);
      }
    }
  }, [userCurent, setValue, templates]);

  const status = [
    { value: 1, label: t("caymland.orders.status.1") },
    { value: 2, label: t("caymland.orders.status.2") },
    { value: 3, label: t("caymland.orders.status.3") },
    { value: 4, label: t("caymland.orders.status.4") },
  ];

  const retrieveTemplates = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerTemplatesQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerTemplatesQuery(query).unwrap();
      const data = response?.data;
      setTemplates(data);
      resolve(data);
    });

  const retrieveUser = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerUserQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerUserQuery(query).unwrap();
      const formattedData = response?.data.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      }));
      resolve(formattedData);
    });

  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <div key={loading || rerender}>
        <Controller
          control={control}
          name="status"
          key={loading || rerender}
          render={({ field: { name, onChange, value } }) => (
            <Select
              name="status"
              key={loading || rerender}
              label={t("caymland.multiple.status")}
              options={status.map((stat) => ({ label: stat.label, value: stat.value }))}
              required
              isClearable={false}
              value={value}
              onChange={onChange}
              errors={errors && errors[name]}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="template"
        render={({ field: { name, onChange, value } }) => (
          <Select
            name="template"
            label={t("caymland.lottery.tab.templates")}
            placeholder={t("caymland.core.select")}
            isClearable={false}
            labelField="name"
            valueField="id"
            options={retrieveTemplates}
            value={value}
            onChange={onChange}
            required
            errors={errors && errors[name]}
          />
        )}
      />
      <Controller
        control={control}
        name="user"
        render={({ field: { name, onChange, value } }) => (
          <Select
            name="user"
            label={t("caymland.brochures.orders.owner")}
            options={retrieveUser}
            value={value}
            isClearable={false}
            onChange={onChange}
            errors={errors && errors[name]}
          />
        )}
      />
    </>
  );
};

export default Sidebar;
