import React, { Fragment, useState, useEffect, useMemo } from "react";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import {
  useAddTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
} from "../../../redux/api/tagsApi";
import { MRT_CustomHeaderRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomHeaderRowsAction";
import { MRT_CustomRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomRowsAction";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import TagsModal from "./TagsModal";
import { t } from "i18next";

function TagsEditor(props) {
  const [tagName, setTagName] = useState("");
  const [idModal, setIdModal] = useState(null);
  const name = "TagsEditor";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    type: null,
    id: null,
  });

  const { data, isFetching, refetch } = useGetTagsQuery(query);
  const [updateTag] = useUpdateTagMutation();
  const [addTag] = useAddTagMutation();
  const [deleteTag] = useDeleteTagMutation();

  const columns = useMemo(() => [
    {
      accessorKey: "tag",
      accessorFn: (row) => row.tag,
      header: t("caymland.user.account.permissions.editname"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent data={cell.row.original} view={true} link="tags" modal={setModalOptions} disableLink={true} />
      ),
    },
    {
      accessorKey: "totalLeads",
      accessorFn: (row) => row.totalLeads,
      header: t("caymland.tageditor.total.leads"),
      size: 150,
      enableSorting: false,
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.totalLeads,
    },
  ]);

  const actionButtons = ActionButtons({
    link: "tags",
    deleteItem: deleteTag,
    refetch: refetch,
    clone: false,
    openModal: setModalOptions,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "tageditor",
    refetch: refetch,
  });
  const buttons = [
    {
      name: "AddNew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={15} />,
      onClick: () => {
        setModalOptions({
          isOpen: true,
          type: "add",
        });
      },
    },
  ];

  const openModal = async (e, id) => {
    setIdModal(id);
    setModalOptions({
      isOpen: true,
    });
  };

  return (
    <Fragment>
      <TagsModal
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        setAlertMessage={setAlertMessage}
        alertMessage={alertMessage}
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setTagName={setTagName}
        tagName={tagName}
        updateTag={updateTag}
        addTag={addTag}
        refetch={refetch}
      />
      <Header buttons={buttons} title={t("caymland.tags_editor")} alertMessage={alertMessage} showAlert={showAlert} />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        isFetching={isFetching}
        linkNavigate="tags"
        modal={setModalOptions}
        searchHelper={false}
      />
    </Fragment>
  );
}

export default TagsEditor;
