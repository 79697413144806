import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Text, TextArea, Checkbox, Select } from "../../reusableComponents/Inputs";
import { t } from "i18next";
import {
  useAddPurposeMutation,
  useGetPurposeByIdQuery,
  useUpdatePurposeMutation,
} from "../../../redux/api/purposesApi";
import { update } from "lodash";
import { Error, Success } from "../../reusableComponents/Alerts";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  purpose: Yup.string().required(t("validation.required")),
  description: Yup.string(),
  unit: Yup.string().required(t("validation.required")),
  validPeriod: Yup.string().required(t("validation.required")),
  isPublished: Yup.boolean(),
});

const CustomModal = ({ modalOptions, setModalOptions, refetch }) => {
  const { data } = useGetPurposeByIdQuery(modalOptions.id, { skip: !modalOptions.id });
  const [addPurpose] = useAddPurposeMutation();
  const [updatePurpose] = useUpdatePurposeMutation();

  // Initialize useForm with default values and validation schema
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      purpose: "",
      description: "",
      unit: "",
      validPeriod: "",
      isPublished: false,
    },
  });
  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen });
  };

  // Effect to set form values when editing
  useEffect(() => {
    if (data) {
      setValue("purpose", data.purpose.purpose || "");
      setValue("description", data.purpose.description || "");
      setValue("unit", data.purpose.unit || "");
      setValue("validPeriod", data.purpose.validPeriod || "");
      setValue("isPublished", data.purpose.isPublished || false);
    }
  }, [data, setValue]);

  const onSubmit = async (formData) => {
    if (modalOptions.type === "edit") {
      console.log("Updating data:", formData);
      const response = await updatePurpose({ id: modalOptions.id, ...formData });
      if (!response.error) {
        refetch();
        Success(
          `${t("caymland.core.update.step.success")}`,
          t("caymland.message.type.successfully", { type: t("caymland.core.type.edited") })
        );
      } else {
        Error(t("caymland.message.error"));
      }
    } else {
      console.log("Creating new data:", formData);
      const response = await addPurpose({ ...formData });
      if (!response.error) {
        refetch();
        Success(
          `${t("caymland.core.update.step.success")}`,
          t("caymland.message.type.successfully", { type: t("caymland.lead.segments.contacts.added") })
        );
      } else {
        Error(t("caymland.message.error"));
      }
    }
    // Close the modal after submission
    reset();
    toggle();
  };

  const buttons = [
    {
      name: "close",
      title: `${t("caymland.core.close")}`,
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => toggle(),
    },
    {
      name: "save",
      title: modalOptions?.type === "edit" ? t("caymland.core.form.update") : t("caymland.core.form.add"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <Modal show={modalOptions.isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{modalOptions?.type === "edit" ? `Edit ${data?.purpose?.purpose}` : "Add Purpose"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {/* Purpose Field */}
          <Controller
            name="purpose"
            control={control}
            render={({ field }) => (
              <Text
                label={t("Purpose")}
                {...field} // Connects the field to react-hook-form's state
                errors={errors.purpose?.message}
              />
            )}
          />

          {/* Description Field */}
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea
                label={t("caymland.core.description")}
                {...field} // Connects the field to react-hook-form's state
              />
            )}
          />

          {/* Unit Field */}
          <Controller
            name="unit"
            control={control}
            render={({ field }) => (
              <Select
                label={t("caymland.core.time.unit")}
                options={[
                  { value: "hour", label: t("caymland.storage.purpose.valid.period.hour") },
                  { value: "day", label: t("caymland.storage.purpose.valid.period.days") },
                  { value: "week", label: t("caymland.storage.purpose.valid.period.week") },
                  { value: "month", label: t("caymland.storage.purpose.valid.period.month") },
                  { value: "year", label: t("caymland.storage.purpose.valid.period.year") },
                  { value: "unlimited", label: t("caymland.storage.purpose.valid.period.unlimited") },
                ]}
                value={field.value} // This will only pass the value
                onChange={(selectedOption) => field.onChange(selectedOption.value)} // Just pass value
                errors={errors.unit?.message}
              />
            )}
          />

          {/* Valid Period (Valid Point) Field */}
          <Controller
            name="validPeriod"
            control={control}
            render={({ field }) => (
              <Text
                label={t("caymland.storage.purpose.valid.period")}
                type="number"
                {...field} // Connects the field to react-hook-form's state
                errors={errors.validPeriod?.message}
              />
            )}
          />

          {/* Is Published Field */}
          <Controller
            name="isPublished"
            control={control}
            render={({ field }) => (
              <Checkbox
                name={field.name}
                label={t("caymland.core.form.published")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
