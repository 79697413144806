import React, { Fragment, useEffect, useMemo, useState } from "react";
import CustomTable from "../../../../../reusableComponents/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const Links = ({ extras, highlightedLinkTable, setHighlightedLinkTable, highlightedLink, setHighlightedLink, id }) => {
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalUniqueClicks, setTotalUniqueClicks] = useState(0);
  const [trackables, setTrackables] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({
    orderByDir: "asc",
    orderBy: "hits",
  });
  useEffect(() => {
    const trackablesWithIndex = extras?.trackables?.map((e, index) => ({
      ...e,
      index: index + 1,
    }));
    setTrackables(trackablesWithIndex);

    let totalClicks = 0;
    let totalUniqueClicks = 0;
    trackablesWithIndex?.map((e) => {
      totalClicks += parseInt(e.hits, 10);
      totalUniqueClicks += parseInt(e.unique_hits, 10);
    });
    setTotalClicks(totalClicks);
    setTotalUniqueClicks(totalUniqueClicks);
  }, [extras]);

  const sortedTrackables = useMemo(() => {
    const sorted = [...(trackables || [])].sort((a, b) => {
      let comparison = 0;
      switch (query?.orderBy) {
        case "URL":
          comparison = a.url.localeCompare(b.url);
          break;
        case "hits":
          comparison = a.hits - b.hits;
          break;
        case "Unique Click":
          comparison = a.unique_hits - b.unique_hits;
          break;
      }
      return query?.orderByDir === "desc" ? -comparison : comparison;
    });
    return sorted;
  }, [trackables, query]);

  useEffect(() => {
    if (sortedTrackables) {
      const formattedData = sortedTrackables.map((item) => ({
        url: item?.url,
        hits: item?.hits,
        unique_hits: item?.unique_hits,
        redirect_id: item?.redirect_id,
        id: item?.id,
      }));
      setData(formattedData);
    }
  }, [sortedTrackables]);

  const handleClick = (evt, trackingId) => {
    evt.preventDefault();
    setHighlightedLink(trackingId);
    setHighlightedLinkTable(null);
  };

  useEffect(() => {
    if (highlightedLink) {
      const element = document.getElementById(highlightedLink);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [highlightedLink]);

  useEffect(() => {
    if (extras?.trackables) {
      const formattedData = extras.trackables.map((item) => ({
        url: item.url,
        hits: item.hits,
        unique_hits: item.unique_hits,
        redirect_id: item.redirect_id,
      }));
      setData(formattedData);
    }
  }, [extras]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "URL",
        accessorFn: (row) => row.url,
        header: t("caymland.core.type.url"),
        size: 150,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <div
            style={{
              cursor: "pointer",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "350px",
            }}
            onClick={(e) => handleClick(e, row?.original?.redirect_id)}
            title={row?.original?.url}
          >
            {row?.original?.url}
          </div>
        ),
        footer: t("caymland.trackable.total_clicks"),
      },
      {
        accessorKey: "hits",
        accessorFn: (row) => row.hits,
        header: t("caymland.email.report.hits_count"),
        size: 150,
        enableColumnActions: false,
        Footer: () => <div style={{ textAlign: "right" }}>{totalClicks}</div>,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "Unique Clicks",
        accessorFn: (row) => row.unique_hits,
        Cell: ({ cell }) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/contacts`, {
                  state: {
                    search: `page_source:email page_source_id:${extras?.email?.id} page_id:${cell?.row?.original?.id}`,
                  },
                });
              }}
              className="result-component"
              style={{ float: "right" }}
              title={t("caymland.email.stat.tooltip")}
            >
              {cell?.row?.original?.unique_hits || 0}
            </div>
          );
        },
        header: t("caymland.trackable.click_unique_count"),
        size: 50,
        enableColumnActions: false,
        Footer: () => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/contacts`, {
                state: {
                  search: `page_source:email page_source_id:${id}`,
                },
              });
            }}
            className="result-component"
            style={{ float: "right" }}
            title={t("caymland.email.stat.tooltip")}
          >
            {totalUniqueClicks}
          </div>
        ),
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    [query, totalClicks, totalUniqueClicks]
  );

  return (
    <Fragment>
      <CustomTable
        columnsClicks={columns}
        data={data}
        query={query}
        setQuery={setQuery}
        highlightedLink={highlightedLinkTable || highlightedLink}
        linkNavigate={"advancedReport"}
        hideActionButtons={true}
        handleClick={handleClick}
        pagination={false}
        searchHelper={false}
        showGlobalFilter={false}
      />
    </Fragment>
  );
};

export default Links;
