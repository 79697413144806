import React from "react";
import { Select, Text } from "../../../reusableComponents/Inputs";
import { t } from "i18next";
import { useGetRolesQuery } from "../../../../redux/api/usersApi";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons for show/hide

const Details = ({ modifiedData, setModifiedData, handleChange, errors, setErrors }) => {
  const { data } = useGetRolesQuery();
  const [roleData, setRoleData] = useState([]);
  const [showPassword, setShowPassword] = useState(false); // State for password field visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (data?.data) {
      const mappedData = data?.data?.map?.((role) => ({
        label: role.name,
        value: role.id,
      }));
      setRoleData(mappedData);
    }
  }, [data]);

  const findRoleById = () => {
    if (!modifiedData.role || typeof modifiedData.role !== "object") {
      return modifiedData.role ? roleData.find((r) => r.value === modifiedData.role) : null;
    }
    return roleData.find((r) => r.value === modifiedData.role.id);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggles password field visibility
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggles confirm password field visibility
  };

  return (
    <>
      <div className="d-flex gap-10 w-100">
        <Text
          name="firstName"
          value={modifiedData.firstName}
          onChange={handleChange}
          label={t("caymland.core.firstname")}
          required={true}
          wFull="w-50"
          errors={errors.firstName}
        />

        <Text
          name="lastName"
          value={modifiedData.lastName}
          onChange={handleChange}
          label={t("caymland.core.lastname")}
          required={true}
          wFull="w-50"
          errors={errors.lastName}
        />
      </div>

      <div className="w-50" style={{ paddingRight: "5px" }}>
        <Select
          name="role"
          label={t("caymland.user.role")}
          required={true}
          value={findRoleById()}
          options={roleData}
          errors={errors.role}
          onChange={(e) => {
            const newRole = e === null ? null : e.value;
            setModifiedData((prev) => ({
              ...prev,
              role: newRole,
            }));
            if (errors.role) {
              setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors.role;
                return newErrors;
              });
            }
          }}
        />
      </div>

      <div className="d-flex gap-10 w-100">
        <Text name="position" value={modifiedData.position} onChange={handleChange} label="Position" wFull="w-50" />
        <Text
          name="signature"
          value={modifiedData.signature}
          onChange={handleChange}
          label={t("caymland.email.token.signature")}
          wFull="w-50"
        />
      </div>

      <hr
        style={{
          gridColumnStart: "1",
          gridColumnEnd: "3",
          marginTop: "2em",
          marginBottom: "20px",
          width: "100%",
          border: 0,
          borderTop: "1px solid #47535f",
        }}
      />

      <div className="d-flex gap-10 w-100">
        {/* Username Field */}
        <Text
          name="username"
          value={modifiedData.username}
          onChange={handleChange}
          label={t("caymland.core.username")}
          required={true}
          wFull="w-50"
          errors={errors.username}
        />

        {/* Password Field */}
        <div style={{ position: "relative", width: "50%" }}>
          <Text
            type={showPassword ? "text" : "password"} // Toggle between text/password type for password field
            name="password"
            value={modifiedData?.plainPassword?.password ?? null}
            onChange={(e) => {
              const newPassword = e.target.value;
              setModifiedData((prevData) => ({
                ...prevData,
                plainPassword: {
                  ...prevData.plainPassword,
                  password: newPassword,
                },
              }));
              if (errors.password) {
                setErrors((prevErrors) => {
                  const newErrors = { ...prevErrors };
                  delete newErrors.password;
                  return newErrors;
                });
              }
            }}
            label={t("caymland.core.password")}
            required={true}
            wFull="w-100" // Make sure this width doesn't conflict with the parent div
            errors={errors.password}
          />
          {/* Show/Hide password icon */}
          <span
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px", // Align icon to the right
              top: "40%",
              transform: "translateY(-50%)", // Vertically center the icon
              cursor: "pointer",
              fontSize: "1.5em",
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
      </div>

      <div className="d-flex gap-10 w-100">
        {/* Email Field */}
        <Text
          type="email"
          name="email"
          value={modifiedData.email}
          onChange={handleChange}
          label={t("caymland.core.theme.feature.email")}
          required={true}
          wFull="w-50"
          errors={errors.email}
        />

        {/* Confirm Password Field */}
        <div style={{ position: "relative", width: "50%" }}>
          <Text
            type={showConfirmPassword ? "text" : "password"} // Toggle between text/password type for confirm password field
            name="confirm"
            value={modifiedData?.plainPassword?.confirm ?? null}
            onChange={(e) =>
              setModifiedData((prevData) => ({
                ...prevData,
                plainPassword: {
                  ...prevData.plainPassword,
                  confirm: e.target.value,
                },
              }))
            }
            label={t("caymland.user.user.form.passwordconfirm")}
            required={true}
            wFull="w-100" // Adjust width here as well
            errors={errors.confirm}
          />
          {/* Show/Hide confirm password icon */}
          <span
            onClick={toggleConfirmPasswordVisibility}
            style={{
              position: "absolute",
              right: "10px", // Align icon to the right
              top: "40%",
              transform: "translateY(-50%)", // Vertically center the icon
              cursor: "pointer",
              fontSize: "1.5em",
            }}
          >
            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
      </div>
    </>
  );
};

export default Details;
