import React, { useState, useEffect, useRef } from "react";
import { useGlobalSearchQuery } from "../../../redux/api/settingsApi";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import Loading from "../loading";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [isActive, setIsActive] = useState(false);
  const { data: globalSearchData, isLoading } = useGlobalSearchQuery(debouncedQuery, { skip: !debouncedQuery });
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setQuery(""); // Optional: clear the query when clicking outside
      setIsActive(false); // Reset active state when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleResultClick = (url) => {
    navigate(url);
  };

  const renderResults = () => {
    const categories = globalSearchData?.data?.results;
    if (!categories) return null;

    return Object.keys(categories).map((category) => {
      const categoryData = categories[category];
      const count = categoryData.count;
      const items = Object.values(categoryData).filter((item) => typeof item === "string" && item !== count);

      if (items.length > 0) {
        return (
          <div key={category} className="search-category">
            <div className="search-category-header">
              <span>{category}</span>
              <span className="category-count">{count}</span>
            </div>
            {items.map((item, index) => {
              const urlMatch = item.match(/href="([^"]*)"/);
              const url = urlMatch ? urlMatch[1] : "#";
              const content = item.replace(/<a href="[^"]*"[^>]*>|<\/a>/g, ""); // Strips <a> tags for innerHTML
              const contactPointsMatch = item.match(/Contact points">(\d+)<\/span>/); // Extracts contact points if present
              const contactPoints = contactPointsMatch ? contactPointsMatch[1] : null;

              return (
                <div
                  key={index}
                  className="search-result-item"
                  onClick={() => handleResultClick(url)}
                  style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: content }} style={{ flex: 1 }} />
                  {contactPoints && (
                    <span className="contact-points" title="Contact points" style={{ marginLeft: "10px" }}>
                      {contactPoints}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div
      className={`search-bar-wrapper ${isActive ? "active" : ""}`}
      ref={wrapperRef}
      onClick={() => setIsActive(true)}
    >
      <div className="search-bar" style={{ borderBottom: "1px solid #ddd" }}>
        <button className="search-button">
          <FaSearch size={15} />
        </button>
        <input
          type="text"
          value={query}
          onChange={handleChange}
          placeholder={t("caymland.caymland.global.search") + "..."}
          className="search-input"
        />
      </div>
      {debouncedQuery && (
        <div className="search-results" style={{ display: "block" }}>
          {isLoading ? <Loading /> : renderResults()}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
