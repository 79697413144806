import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Templates"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getTemplates: build.query({
      query: (q) =>
        `/api/brochures/templates?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Templates"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addTemplates: build.mutation({
      query: (body) => ({
        url: `api/brochures/templates/new`,
        method: "POST",
        body,
      }),
    }),
    updateTemplates: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/brochures/templates/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Templates"],
    }),
    getTemplate: build.query({
      query: (id) => `/api/brochures/templates/${id}`,
      providesTags: (id) => [{ type: "Templates", id }],
      lazy: true,
    }),
    deleteTemplate: build.mutation({
      query: (id) => ({
        url: `/api/brochures/templates/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),
    changeStatus: build.mutation({
      query: (body) => ({
        url: `api/brochures/orders/status/batch`,
        method: "POST",
        body,
      }),
    }),

    //FOLDERS
    //GET FOLDERS
    getFolderTempletes: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=brochures.templates`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderTemplates: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderTemplates: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Templates", id }],
    }),
    //DELETE FOLDER
    deleteFolderTemplates: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Templates", id }],
    }),
    //MOVE EMAIL TO FOLDER
    updateTemplatesToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/btemplates/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE EMAIL TO FOLDER
    removeTemplatesToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/btemplates/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useAddTemplatesMutation,
  useUpdateTemplatesMutation,
  useGetTemplateQuery,
  useDeleteTemplateMutation,
  useChangeStatusMutation,

  // //FOLDERS
  useGetFolderTempletesQuery,
  useUpdateFolderTemplatesMutation,
  useDeleteFolderTemplatesMutation,
  useAddFolderTemplatesMutation,
  useUpdateTemplatesToFoldersMutation,
  useRemoveTemplatesToFoldersMutation,
} = templatesApi;
