import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Success } from "../../reusableComponents/Alerts";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import MainContent from "./mainContent";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { t } from "i18next";
import {
  useAddOrderMutation,
  useGetOrderFieldsQuery,
  useGetOrderQuery,
  useUpdateOrderMutation,
} from "../../../redux/api/ordersApi";
import { generateSchema } from "./GenerateSchema";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { contactsApi } from "../../../../src/redux/api";
import { format } from "date-fns";
import i18next from "i18next";
import { useGetContactByIdQuery } from "../../../../src/redux/api/contactsApi";

function AddEditOrders(state) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idFromUrl = queryParams.get("id");

  const navigate = useNavigate();
  const userCurent = useSelector((state) => state.auth.user);
  const isNew = location.pathname.includes("new");
  const isEdit = location.pathname.includes("edit");
  const ClonePath = location.pathname.includes("clone");

  const { initialData } = location?.state || {};

  const paramsId = useParams().id;
  const stateId = location.state?.id;

  const id = paramsId || (typeof stateId === "number" ? stateId : undefined);

  const [loading, setLoading] = useState(true);

  const [selectedOption, setSelectedOption] = useState(null);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();

  const {
    data: contactData,
    error,
    isLoading: contactLoading,
  } = useGetContactByIdQuery(idFromUrl, {
    skip: !idFromUrl,
  });

  const { data, isLoading, isFetching } = useGetOrderQuery(id, { skip: !id });
  const { data: orderFields } = useGetOrderFieldsQuery();

  const [addNewOrder] = useAddOrderMutation();
  const [updateOrder] = useUpdateOrderMutation(id);

  const schema = useMemo(
    () => generateSchema(data?.brochureorder?.leadFields || orderFields || {}),
    [data?.brochureorder?.leadFields, orderFields]
  );
  const fieldsArray = useMemo(
    () => Object.values(data?.brochureorder?.leadFields || orderFields || {}),
    [data?.brochureorder?.leadFields, orderFields]
  );

  const formattedDate = format(new Date(), "dd.MM.yyyy HH:mm");
  const orderName = t("caymland.brochures.form.order.name", { date: formattedDate });

  let fullName = `${initialData?.lead?.firstname ? initialData.lead.firstname + " " : ""}${
    initialData?.lead?.lastname || ""
  }`.trim();

  useEffect(() => {
    i18next.on("loaded", () => {
      setLoading(false);
    });

    if (i18next.isInitialized) {
      setLoading(false);
    }

    return () => {
      i18next.off("loaded");
    };
  }, []);

  let statusValues = [
    { value: 1, label: t("caymland.orders.status.1") },
    { value: 2, label: t("caymland.orders.status.2") },
    { value: 3, label: t("caymland.orders.status.3") },
    { value: 4, label: t("caymland.orders.status.4") },
  ];

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("brochureInitialData");
    if (storedData) {
      const mailText = JSON.parse(storedData);
      setValue("note", mailText);
      localStorage.removeItem("brochureInitialData");
    }
  }, []);

  useEffect(() => {
    async function fetchContactData() {
      let fieldsAll = contactData?.data?.fields?.all;

      if (contactData && fieldsAll) {
        setSelectedOption(fieldsAll);

        for (let field of fieldsArray) {
          if (fieldsAll.hasOwnProperty(field.alias)) {
            await setValue(field.alias, fieldsAll[field.alias], { shouldValidate: true });
            const fullName = `${fieldsAll.firstname} ${fieldsAll.lastname} ${orderName}`;
            await setValue("name", fullName, { shouldValidate: true });
          }
        }
      } else if (!contactData && idFromUrl) {
        console.log("No contact data available for ID:", idFromUrl);
      }
    }
    fetchContactData();
  }, [contactData, error, idFromUrl, contactLoading, fieldsArray]);

  useEffect(() => {
    if (id && data?.brochureorder && !idFromUrl) {
      const productsData = data?.brochureorder?.products.map((product) => ({
        id: product.product.id,
        product: product.product.name,
        qty: product.qty,
        weight: product.weight,
      }));
      reset({
        ...data?.brochureorder?.lead,
        ...data?.brochureorder,
        products: data?.brochureorder?.products,
        user: {
          label: `${userCurent.firstName} ${userCurent.lastName}`,
          value: userCurent.id,
        },
      });
    } else if (isNew && userCurent && !idFromUrl) {
      reset({
        ...data?.brochureorder,
        status: statusValues[0],
        name: initialData?.lead?.firstname || initialData?.lead?.lastName ? `${fullName} ${orderName}` : orderName,
        user: {
          label: `${userCurent.firstName} ${userCurent.lastName}`,
          value: userCurent.id,
        },
      });
    }
  }, [id, isNew, userCurent, data, reset, isEdit, loading]);

  const handleCancel = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/orders");
      } else {
        return;
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleAddEditOrder(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleAddEditOrder(data)),
    },
  ];

  const handleAddEditOrder = async (formData, close = false) => {
    const leadFields = Object.keys(formData).reduce((acc, key) => {
      if (fieldsArray.some((field) => field.alias === key)) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    const payload = {
      ...formData,
      products: Array.isArray(formData.products)
        ? formData.products.map((product) => ({
            id: typeof product.product === "object" ? product.product.id : product.id,
            product: typeof product.product === "object" ? product.product.name : product.product,
            qty: product.qty,
            weight: product.weight,
          }))
        : [],
      status: formData.status.value ? formData.status.value : formData.status,
      template: formData.template.id,
      user: formData.user.id,
      lead: leadFields,
    };

    const response = id && !ClonePath ? await updateOrder({ id, ...payload }) : await addNewOrder(payload);

    if (!response?.error) {

      
      Success(
        id && !ClonePath ? "Das Element wurde erfolgreich bearbeitet" : "Das Element wurde erfolgreich erstellt",
      ).then((result) => {
        if (result.isConfirmed) {
          if (close) {
            navigate(`/orders`);
          } else {
            navigate(`/orders/edit/${response?.data?.brochureorder?.id}`);
          }
        }
      });

      const transformedProducts = response?.data?.brochureorder?.products.map((product) => ({
        id: product.product.id,
        product: product.product.name,
        qty: product.qty,
        weight: product.weight,
      }));

      reset({
        ...response?.data.brochureorder,
        lead: response?.data.brochureorder.lead,
        products: transformedProducts,
      });
    } else {
      console.error("Submission Error", response.error.data.errors[0].message);
    }
  };

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise((resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: 30,
          orderByDir: "desc",
          orderBy: "last_active",
          search: inputValue,
        };

        const response = await triggerContactsQuery(query);

        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData.map((contact) => {
            const { firstname, lastname, email } = contact.fields.all;
            const fullName = [firstname, lastname].filter(Boolean).join(" ").trim();
            const label = fullName ? `${fullName} - ${email}` : email;
            return {
              ...contact.fields.all,
              label,
              value: contact.id,
            };
          });
          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  const handleContactSelect = (selectedContact) => {
    if (selectedContact) {
      setSelectedOption(selectedContact);

      fieldsArray.forEach((field) => {
        if (selectedContact.hasOwnProperty(field.alias)) {
          setValue(field.alias, selectedContact[field.alias], { shouldValidate: true });
        }
      });
    }
  };

  useEffect(() => {
    if (initialData && !idFromUrl) {
      console.log("test");
      const formattedDate = format(new Date(), "dd.MM.yyyy HH:mm");

      const orderName = t("caymland.brochures.form.order.name", { date: formattedDate });
      reset({
        ...initialData.lead,
        // status: { value: 1, label: trasletedStatus },
        user: {
          label: `${userCurent.firstName} ${userCurent.lastName}`,
          value: userCurent.id,
        },
        id: null,
        note: initialData.note,
        name: `${fullName}${" "}${orderName}`,
      });
    }
  }, [initialData]);

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isFetching}
          title={!ClonePath && id ? "Edit Order" : t("caymland.brochures.order.add")}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          <MainContent
            leadFieldsData={fieldsArray}
            isFetching={isLoading}
            control={control}
            watch={watch}
            dirtyFields={dirtyFields}
            errors={errors}
            leadData={data?.brochureorder?.lead || []}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
            retrieveContacts={retrieveContacts}
            handleContactSelect={handleContactSelect}
            selectedOption={selectedOption}
            rerender={loading}
            getValues={getValues}
          />
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          <Sidebar isFetching={isLoading} control={control} errors={errors} rerender={loading} setValue={setValue} />
        }
      />
    </>
  );
}

export default AddEditOrders;
