import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetCampaignsContactsQuery } from "../../../../redux/api/campaignsApi";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function Contacts({ contactData, query, setQuery }) {
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) => row.name || row?.ipAddresses[0]?.ip,
        header: t("caymland.core.name"),
        enableColumnActions: false,
      },
      {
        accessorKey: "email",
        accessorFn: (row) => row.email,
        header: t("caymland.email.email"),
        enableColumnActions: false,
      },
      {
        accessorKey: "Location",
        accessorFn: (row) => row.state,
        header: t("caymland.activity.campaigns.field.location"),
        enableColumnActions: false,
      },
    ],
    []
  );

  return <CustomTable columns={columns} query={query} data={contactData} setQuery={setQuery} viewTable={true} />;
}

export default Contacts;
