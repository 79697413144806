import * as yup from "yup";
import { t } from "i18next";
import moment from "moment";

export const generateSchema = (fields) => {
  const shape = {
    name: yup.string().required(t("caymland.core.fieldrequired")),
    status: yup.mixed().required(t("caymland.core.fieldrequired")),
    template: yup.mixed().required(t("caymland.core.fieldrequired")),
    user: yup.mixed().required(t("caymland.core.fieldrequired")),
  };

  Object.values(fields).forEach((field) => {
    switch (field.type) {
      case "text":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "email":
        shape[field.alias] = yup.string().nullable().email(t("caymland.core.valid.email"));
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "number":
        shape[field.alias] = yup.number().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "tel":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "url":
        shape[field.alias] = yup.string().nullable().url(t("caymland.core.valid.url"));
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "boolean":
        shape[field.alias] = yup.boolean().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "date":
        shape[field.alias] = yup
          .date().nullable()
          .transform((value, originalValue) =>
            moment(originalValue, "YYYY-MM-DD", true).isValid()
              ? moment(originalValue, "YYYY-MM-DD").toDate()
              : new Date(null)
          );
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "datetime":
        shape[field.alias] = yup
          .date().nullable()
          .transform((value, originalValue) =>
            moment(originalValue, "YYYY-MM-DD HH:mm:ss", true).isValid()
              ? moment(originalValue, "YYYY-MM-DD HH:mm:ss").toDate()
              : new Date(null)
          );
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "country":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "locale":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "region":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "lookup":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "select":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "multiselect":
        shape[field.alias] = yup.array().nullable().of(yup.string());
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "textarea":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "time":
        shape[field.alias] = yup.string().nullable().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, t("caymland.core.valid.time"));
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      case "timezone":
        shape[field.alias] = yup.string().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
      default:
        shape[field.alias] = yup.mixed().nullable();
        if (field.isRequired) {
          shape[field.alias] = shape[field.alias].required(t("caymland.core.fieldrequired"));
        }
        break;
    }
  });

  return yup.object().shape(shape);
};
