import React from "react";
import { Text, TextArea } from "../../reusableComponents/Inputs";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import { useTranslation } from "react-i18next";
import { Select } from "../../reusableComponents/Inputs";
import { assetsApi } from "../../../redux/api/assetsApi";
import { Controller } from "react-hook-form";

const MainContent = ({ isFetching, control, errors }) => {
  const { t } = useTranslation();

  const [triggerAssetsQuery] = assetsApi.useLazyGetAssetsQuery();

  const retrieveAssets = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerAssetsQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerAssetsQuery(query).unwrap();
      const data = response?.data;
      resolve(data);
    });

  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <>
      <div className="flex justify-between" style={{ gap: "10px", display: "flex", alignItems: "stretch" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "50%", gap: "20px" }}>
          <Controller
            control={control}
            name="name"
            render={({ field: { name, onChange, value } }) => (
              <Text
                label="Name"
                name={name}
                type="text"
                value={value}
                marginBottom={true}
                required={true}
                onChange={onChange}
                errors={errors && errors[name]}
              />
            )}
          />
          <Controller
            control={control}
            name="weight"
            render={({ field: { name, onChange, value } }) => (
              <Text
                label="Weight"
                name={name}
                type="number"
                value={value}
                marginBottom={true}
                required={true}
                onChange={onChange}
                errors={errors && errors[name]}
              />
            )}
          />
          <Controller
            control={control}
            name="asset"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name="status"
                label={t("caymland.social.monitoring.twitter.assets")}
                options={retrieveAssets}
                labelField="title"
                valueField="id"
                placeholder={t("caymland.core.select")}
                tooltip={t("caymland.brochures.assets.tooltip")}
                isClearable={false}
                value={value}
                onChange={onChange}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
          <Controller
            control={control}
            name="description"
            render={({ field: { name, onChange, value } }) => (
              <TextArea value={value} marginBottom={true} onChange={onChange} errors={errors && errors[name]} />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default MainContent;
