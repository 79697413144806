import React from "react";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import { Checkbox, Select, Text, TextArea } from "../../reusableComponents/Inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";

const Sidebar = ({ isFetching, control, errors }) => {
  const { t } = useTranslation();

  const locale = useSelector((state) => state?.settings?.data?.locales);

  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field: { name, onChange, value } }) => (
          <Text
            label={t("caymland.core.name")}
            name={name}
            type="text"
            value={value}
            marginBottom={true}
            required={true}
            onChange={onChange}
            errors={errors && errors[name]}
            wFull={"tw-w-full"}
          />
        )}
      />
      <div className="tw-mt-5 tw-w-full">
        <Controller
          control={control}
          name="description"
          render={({ field: { name, onChange, value } }) => (
            <TextArea
              label={t("caymland.core.description")}
              value={value}
              marginBottom={true}
              onChange={onChange}
              errors={errors && errors[name]}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="language"
        render={({ field: { name, onChange, value, control } }) => (
          <Select
            name={name}
            label={t("caymland.core.language")}
            options={locale}
            value={value}
            required
            placeholder={t("caymland.core.select")}
            onChange={onChange}
            tooltip={t("caymland.dynamicContent.form.language.help")}
            errors={errors && errors[name]}
          />
        )}
      />
      <Controller
        control={control}
        name="isPublished"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
        )}
      />
    </>
  );
};

export default Sidebar;
