import React, { useRef, useState } from "react";
import { Label, Text, TextArea } from "../../../reusableComponents/Inputs";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import { Controller } from "react-hook-form";
import CKEditor4 from "../../../main-menu-sidebar/mailbox/CKEditor4";

const MainContent = ({ isFetching, control, errors }) => {
  const editorRef = useRef(null);

  const handleEditorInstance = (editor) => {
    editorRef.current = editor;
  };

  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <>
      <Label name={"content"} label={"Content"} required={true} errors={errors && errors["content"]} />
      <Controller
        control={control}
        name="content"
        render={({ field: { name, onChange, value } }) => (
          <CKEditor4 value={value} onChange={onChange} name={name} onEditorReady={handleEditorInstance} />
        )}
      />
    </>
  );
};

export default MainContent;
