import React from "react";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import { Checkbox, Select, Text, TextArea } from "../../../reusableComponents/Inputs";
import { t } from "i18next";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

const Sidebar = ({ isFetching, control, errors }) => {
  const locale = useSelector((state) => state?.settings?.data?.locales);

  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field: { name, onChange, value } }) => (
          <Text
            label="Name"
            name={name}
            type="text"
            value={value}
            marginBottom={true}
            required={true}
            onChange={onChange}
            errors={errors && errors[name]}
            wFull="tw-w-full"
          />
        )}
      />
      <div className="tw-mt-5">
        <Controller
          control={control}
          name="description"
          render={({ field: { name, onChange, value } }) => (
            <TextArea
              label={"Description"}
              value={value}
              marginBottom={true}
              onChange={onChange}
              errors={errors && errors[name]}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="language"
        render={({ field: { name, onChange, value } }) => (
          <Select
            name="language"
            label={t("caymland.core.language")}
            options={locale}
            required
            isClearable={false}
            value={value}
            onChange={onChange}
            errors={errors && errors[name]}
          />
        )}
      />

      <Controller
        control={control}
        name="isPublished"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
        )}
      />
    </>
  );
};

export default Sidebar;
