import React, { useState } from "react";
import FieldsForms from "./FieldsForms";
// import { countryData } from "../../reusableComponents/data";
// import { useSelector } from "react-redux";
import { t } from "i18next";

const Fields = ({ control, watch, dirtyFields, errors, leadFieldsData }) => {
  const [editableField, setEditableField] = useState(null);

  // const countries = useSelector((state) => state?.settings?.countries || countryData);
  // const localeData = useSelector((state) => state?.settings?.locales);

  // const formatFieldValue = (field, value) => {
  //   if (value === undefined || value === null) {
  //     return "";
  //   }

  //   switch (field?.type) {
  //     case "boolean":
  //       return value === 1 || value === true
  //         ? field?.properties?.yes
  //         : value === 0 || value === false
  //         ? field?.properties?.no
  //         : "";
  //     case "multiselect":
  //       if (typeof value === "string") {
  //         return value
  //           .split("|")
  //           .map((val) => {
  //             const option = field?.properties?.list?.find((item) => item?.value === val);
  //             return option ? option?.label : val;
  //           })
  //           .join(", ");
  //       } else {
  //         return "";
  //       }
  //     case "country":
  //       return countries.find((country) => country?.value === value)?.label;
  //     case "locale":
  //       return localeData.find((locale) => locale?.value === value)?.label;
  //     case "datetime":
  //       return moment(value).format("DD.MM.YYYY HH:mm") || value;
  //     case "date":
  //       return moment(value).format("DD.MM.YYYY ") || value;
  //     default:
  //       return value?.length > 30 ? value?.slice(0, 30) + "..." : value;
  //   }
  // };

  const toggleEditable = (fieldId) => {
    setEditableField(fieldId);
  };

  return (
    <div className=" tw-rounded">
      {leadFieldsData?.map((field, index) => {
        return (
          <div className="col-sm-12 col-md-12 " style={{ padding: "10px", position: "relative" }} key={index}>
            <div
              className={`group normal-btn flex items-center  ${
                editableField === field.id ? "" : "hoverable-edit"
              } w-full`}
              onClick={() => toggleEditable(field.id)}
            >
              <div className="tw-flex tw-items-center tw-font-semibold tw-capitalize tw-text-sm tw-px-2 tw-w-[40%] tw-bg-white tw-text-black tw-h-[34px] tw-shadow tw-rounded-bl-md tw-rounded-tl-md tw-border tw-border-blue-100  hover:tw-shadow-md tw-duration-150 ">
                <span title={field.label}  className="tw-mr-2 tw-border-blue-100">
                  {field.label.length > 25 ? field.label.slice(0, 25) + "..." : field.label}
                </span>
              </div>
              <div
                className={`tw-flex tw-items-center tw-justify-between te-text-[14px] tw-py-0 tw-px-8px tw-w-[60%] tw-h-[34px] tw-text-black  tw-shadow tw-rounded-br-lg tw-bg-[#fafafa] tw-rounded-tr-lg tw-pl-1 hover:tw-shadow-md hover:tw-border  hover:tw-border-blue-200 tw-duration-150 ${
                  field.alias === "email" && field.isRequired
                    ? "tw-border-l-2 tw-border-red-300"
                    : field.isRequired && !watch(field.alias)
                    ? "tw-border-l-2 tw-border-red-300"
                    : dirtyFields[field.alias] && editableField !== field.id
                    ? "tw-border-l-2 tw-border-blue-200"
                    : "tw-border-l-0 "
                } `}
              >
                <FieldsForms field={field} control={control} />
              </div>
            </div>
            {errors[field.alias] && (
              <small className="tw-text-xs tw-text-red-300 tw-absolute tw-w-[90%] tw-text-end">
                {errors[field.alias].message}
              </small>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Fields;
