import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { useCreateFieldMutation, useUpdateFieldsMutation } from "../../../redux/api/fieldsApi";
import { SlideAlert, handleCancel } from "../../reusableComponents/Alerts";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useForm } from "react-hook-form";

import { Text, Checkbox } from "../../reusableComponents/Inputs";
import { Select } from "../../reusableComponents/Inputs";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { classificationApi } from "../../../../src/redux/api";
import { useCreateRuleMutation, useGetFoderSelectQuery, useGetRuleQuery, useUpdateRuleMutation } from "../../../../src/redux/api/ruleApi";
import { useGetCampaignsOptionsQuery } from "../../../redux/api/campaignsApi";
import * as yup from "yup";
import { useGetTemplateSelectQuery } from "../../../redux/api/tempApi";

function AddEditRules(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const isEdit = location.pathname.includes("edit");

  const locale = useSelector((state) => state?.settings?.data?.locales);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  
  const [createRule] = useCreateRuleMutation();
  const [updateRule] = useUpdateRuleMutation();

  const [triggerClassificationsQuery] = classificationApi.useLazyGetClassificationsQuery();
  const { data: foderSelect } = useGetFoderSelectQuery();
  const { data: campaignsSelect } = useGetCampaignsOptionsQuery();
  const { data: templateSelect } = useGetTemplateSelectQuery();
  const { data, isLoading, isFetching } = useGetRuleQuery(id, { skip: !id });


  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(yup.object({})),
  });

  useEffect(() => {
    if (id && data?.rule) {
      reset({
        ...data?.rule,
      });
    }
  }, [id, data, reset, isEdit]);

  const retrieveClassifications = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerClassificationsQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerClassificationsQuery(query).unwrap();
      console.log("response", response)
      const data = response?.data;
      console.log("data", data)
      resolve(data);
    });

  const handleAddEditProduct = async (formData, close = false) => {
  console.log("formData", formData)

    const payload = {
      ...formData,
      // autoReply: formData.autoReply && typeof formData.autoReply === "object" ? formData.autoReply.value : formData.autoReply,
      autoReply: null,
      campaign: formData.campaign && typeof formData.campaign === "object" ? formData.campaign.value : formData.campaign,
      classification: formData.classification && typeof formData.classification === "object" ? formData.classification.value : formData.classification,
      language: formData.language && typeof formData.language === "object" ? formData.language.value : formData.language,
      targetFolder: formData.targetFolder && typeof formData.targetFolder === "object" ? formData.targetFolder.value : formData.targetFolder,
      // saveIdentity: formData.saveIdentity ? 1 : 0, 
      campaign: null,
    };
    console.log("payload", payload)

    try {
      const response =
      formData.id && !formData.ClonePath ? await updateRule({ id, ...payload }) : await createRule(payload);
      console.log("response", response)
      
      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id && !ClonePath ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed) {
            if (close) {
              navigate(`/mailbox-admin/rules`);
            } else {
              // navigate(`/mailbox-admin/rules/edit/${response?.data?.brochureproduct?.id}`);
            }
          }
        });
      } else {
        Error(t("caymland.message.error"));
      }
    } catch (e) {
      Error(t("caymland.message.error"));
    }
  };
  
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/mailbox-admin/rules", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleAddEditProduct(data, "back")),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleAddEditProduct(data)),
    },
  ];

  return (
    <Fragment>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      {isLoading || loading ? (
        <HeaderSkeleton />
      ) : (
        <Header buttons={buttons} loading={isLoading || loading} title={t("caymland.mailbox.rules.index")} />
      )}
      <Wrapper
        childrenClassOne={"tw-w-full tw-p-5"}
        childrenOne={
          loading || isLoading ? (
            <DetailsSkeleton />
          ) : (
            <div className="flex justify-between" style={{ gap: "10px", display: "flex", alignItems: "stretch" }}>
              <div style={{ display: "flex", flexDirection: "column", width: "50%", gap: "20px" }}>
                <Controller
                  control={control}
                  name="From"
                  render={({ field: { name, onChange, value } }) => (
                    <Text
                      label={t("caymland.mailbox.rules.from")}
                      name={name}
                      value={value}
                      marginBottom={true}
                      onChange={onChange}
                      errors={errors && errors[name]}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="recipient"
                  render={({ field: { name, onChange, value } }) => (
                    <Text
                      label={t("caymland.mailbox.rules.recipient")}
                      name={name}
                      value={value}
                      marginBottom={true}
                      onChange={onChange}
                      errors={errors && errors[name]}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="forward"
                  render={({ field: { name, onChange, value } }) => (
                    <Text
                      label={t("caymland.mailbox.rules.forward")}
                      name={name}
                      value={value}
                      marginBottom={true}
                      onChange={onChange}
                      errors={errors && errors[name]}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="saveIdentity"
                  render={({ field: { name, value, onChange } }) => (
                    <Checkbox
                      name={name}
                      marginBottom={""}
                      value={value}
                      onChange={onChange}
                      label={t("caymland.mailbox.rules.save_identity")}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="campaign"
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      label={t("caymland.mailbox.rules.add_to_campaign")}
                      options={campaignsSelect}
                      isClearable={false}
                      // labelField="name"
                      // valueField="id"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                <Controller
                  control={control}
                  name="targetFolder"
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      label={t("caymland.mailbox.rules.folder")}
                      options={foderSelect}
                      isClearable={false}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="language"
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      label={t("caymland.core.language")}
                      options={locale}
                      isClearable={false}
                      value={value}
                      onChange={onChange}
                      errors={errors && errors[name]}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="classification"
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      label={t("caymland.mailbox.compose.label.classification")}
                      options={retrieveClassifications}
                      isClearable={false}
                      labelField="name"
                      valueField="id"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="autoReply"
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      label={t("caymland.mailbox.rules.auto_reply")}
                      options={templateSelect}
                      isClearable={false}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          )
        }
      />
    </Fragment>
  );
}

export default AddEditRules;
