import React from "react";

function Loading(props) {
  return (
    <div className="loading-container">
      <div className="loader-box" style={{ height: props.smallLoading && "0px" }}>
        <div className="custom-loader">
          <div className="line bg-info" style={{ height: props.smallLoading && "10px" }}></div>
          <div className="line bg-info" style={{ height: props.smallLoading && "10px" }}></div>
          <div className="line bg-info" style={{ height: props.smallLoading && "10px" }}></div>
          <div className="line bg-info" style={{ height: props.smallLoading && "10px" }}></div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
