import React, { useEffect, useState } from "react";
import "animate.css";
import { X } from "lucide-react";
import { TooltipProvider } from "../../../components/ui/tooltip";
import { ResizableHandle, ResizablePanelGroup } from "../../../components/ui/resizable";
import { Separator } from "../../../components/ui/separator";
import { MailDisplay } from "./MailDisplay";
import FieldSection from "./FieldSection";
import {
  useGetFolderMailboxQuery,
  useGetMailboxQuery,
  useMoveToFolderMutation,
} from "../../../../src/redux/api/mailboxApi";
import Folders from "./Folders";
import InboxSection from "./Inbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import Compose from "./Compose";
import { ScrollArea, ScrollBar } from "../../ui/scroll-area";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useLocation } from "react-router-dom";


function Mailbox(props) {
  const currentUser = useSelector((state) => state?.auth.user);
  const [activeFolder, setActiveFolder] = useState("Inbox");
  const [activeMail, setActiveMail] = useState(null);
  const [parsedEmail, setParsedEmail] = useState(null);
  const [tabs, setTabs] = useState([{ id: "inbox", title: "Inbox" }]);
  const [activeTab, setActiveTab] = useState("inbox");
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [mailIDs, setMailIDs] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [lockStatus, setLockStatus] = useState(true);
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "id",
    where: [
      {
        col: "folder",
        expr: "eq",
        val: 1,
      },
    ],
  });

  
  const location = useLocation();
  const { initialData, action } = location.state || {};

  useEffect(() => {
    if (initialData && action) {
      addTab(action, initialData);
    }
  }, [initialData, action]);

  const closeTab = (tabId) => {
    if (tabId === "inbox") return;
    const newTabs = tabs.filter((tab) => tab.id !== tabId);
    setTabs(newTabs);

    if (newTabs.length > 0) {
      setActiveTab(newTabs[newTabs.length - 1].id);
    } else {
      setActiveTab("inbox");
    }
  };

  const [moveToFolder] = useMoveToFolderMutation();
  const { data, isFetching, isLoading, refetch } = useGetMailboxQuery(query,  {
    pollingInterval: 30000
  });
  const { data: foldersMailbox, refetch: folderRefetch } = useGetFolderMailboxQuery({ pageSize: null });

  useEffect(() => {
    const findAndSetActiveFolder = async () => {
      if (currentUser && currentUser.defaultFolder !== null && foldersMailbox) {
        const folder = await foldersMailbox.data?.find((folder) => folder?.id === currentUser.defaultFolder);

        if (folder) {
          setMailIDs([]);
          setActiveFolder(folder?.name);
          setQuery((prev) => ({
            pageIndex: 0,
            pageSize: 30,
            orderByDir: "DESC",
            orderBy: "id",
            where: [
              {
                col: "folder",
                expr: "eq",
                val: folder.id,
              },
            ],
          }));
        } else {
        }
      } else {
      }
    };

    findAndSetActiveFolder();
  }, [currentUser, foldersMailbox]);

  const captureTextSelection = () => {
    if (window.getSelection) {
      setSelectedText(window.getSelection().toString());
    }
  };

  const renderTabContent = (tab) => {
    switch (tab.title) {
      case "Reply All":
      case "Reply":
      case "Forward":
      case "Compose":
        return (
          <Compose
            key={tab.id}
            initialData={tab.initialData}
            setActiveTab={setActiveTab}
            closeTab={closeTab}
            activeTab={activeTab}
          />
        );
      default:
        return (
          <>
            <MailDisplay
              mail={parsedEmail}
              activeMail={activeMail}
              setParsedEmail={setParsedEmail}
              addTab={addTab}
              captureTextSelection={captureTextSelection}
              setSelectedText={setSelectedText}
              setLockStatus={setLockStatus}
              lockStatus={lockStatus}
            />
            <ResizableHandle withHandle />
            <FieldSection
              parsedEmail={parsedEmail}
              activeMail={activeMail}
              selectedText={selectedText}
              setSelectedText={setSelectedText}
              mailList={data}
              refetch={refetch}
            />
          </>
        );
    }
  };

  const addTab = async (title, initialData) => {
    const newTabId = `${title}_${tabs.length + 1}`;
    const newTab = {
      id: newTabId,
      title,
      initialData,
    };
    setTabs([...tabs, newTab]);
    setActiveTab(newTabId);
  };

  const handleDrop = async (e, folderId) => {
    e.preventDefault();
    let emailId;

    if (mailIDs.length > 0) {
      emailId = mailIDs;
    } else {
      emailId = e.dataTransfer.getData("text/plain");
    }

    try {
      await moveToFolder({
        ids: emailId,
        folderId: parseInt(folderId),
      });
      await refetch();
    } catch (error) {
      console.error("Error moving email:", error);
    }
    await folderRefetch();
    setHoveredFolder(null);
    setMailIDs([]);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "calc(100vh - 26px)",
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        margin: "13px 0",
        display: "flex",
        width: "100%",
      }}
      className="animate__animated animate__fadeIn animate__faster tw-overflow-hidden"
    >
      <TooltipProvider>
        <div className="tw-flex tw-w-full">
          <div className="tw-w-[30%]">
            <ResizablePanelGroup
              direction="horizontal"
              className="tw-h-full tw-max-h-[800px] tw-items-stretch tw-min-h-full "
            >
              <Folders
                foldersMailbox={foldersMailbox?.data || []}
                refetch={folderRefetch}
                setQuery={setQuery}
                setActiveFolder={setActiveFolder}
                activeFolder={activeFolder}
                handleDrop={handleDrop}
                setHoveredFolder={setHoveredFolder}
                hoveredFolder={hoveredFolder}
                setMailIDs={setMailIDs}
              />
              <ResizableHandle withHandle />
              <InboxSection
                items={data || []}
                setQuery={setQuery}
                query={query}
                parsedEmail={parsedEmail}
                isFetching={isLoading}
                activeFolder={activeFolder}
                isLoading={isLoading}
                setParsedEmail={setParsedEmail}
                setActiveMail={setActiveMail}
                addTab={addTab}
                setActiveTab={setActiveTab}
                setMailIDs={setMailIDs}
                mailIDs={mailIDs}
                setLockStatus={setLockStatus}
              />
            </ResizablePanelGroup>
          </div>
          <Separator orientation="vertical" />
          <div style={{ width: "calc(70% - 2px)" }} >
            <Tabs defaultValue={activeTab} value={activeTab} onValueChange={setActiveTab} className="tw-w-full">
              <ScrollArea className="tw-whitespace-nowrap tw-rounded-md ">
                <TabsList className="tw-flex tw-gap-2 tw-px-2 tw-min-w-min tw-h-[52px]">
                  {tabs.map((tab) => {
                    const inboxTitle = t("caymland.mailbox.inbox");
                    const replyTitle = t("caymland.mailbox.list.action.reply");
                    const replyAllTitle = t("caymland.mailbox.list.action.replyAll");
                    const forwardlTitle = t("caymland.mailbox.list.action.forward");
                    const composeTitle = t("caymland.mailbox.list.action.compose");
                    let tabTitle =
                      tab.title === "Inbox"
                        ? inboxTitle
                        : tab.title === "Reply"
                        ? replyTitle
                        : tab.title === "Reply All"
                        ? replyAllTitle
                        : tab.title === "Forward"
                        ? forwardlTitle
                        : tab.title === "Compose"
                        ? composeTitle
                        : tab.title;
                    return (
                      <div key={tab.id}>
                        <TabsTrigger
                          value={tab.id}
                          className={`tw-w-auto tw-flex  ${
                            tab.id !== "Index" ? "tw-justify-evenly" : "tw-gap-3"
                          } tw-overflow-hidden`}
                        >
                          {tabTitle}
                          {tab.id !== "inbox" && <X size={16} onClick={() => closeTab(tab.id)} />}
                        </TabsTrigger>
                      </div>
                    );
                  })}
                </TabsList>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
              <Separator />
              {tabs.map((tab) => (
                <TabsContent key={tab.id} value={tab.id}>
                  <ResizablePanelGroup
                    direction="horizontal"
                    className="tw-h-full  tw-items-stretch tw-min-h-full "
                    style={{ maxHeight: "calc(100vh - 85px)", overflowY: "scroll" }}
                  >
                    
                    {renderTabContent(tab)}
                  </ResizablePanelGroup>
                </TabsContent>
              ))}
            </Tabs>
          </div>
        </div>
      </TooltipProvider>
    </div>
  );
}

export default Mailbox;
