import React from "react";
import { Select, Text, TextArea } from "../../reusableComponents/Inputs";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import Fields from "./Fields";
import { Controller } from "react-hook-form";
import ProductsSection from "./ProductsSection";
import { t } from "i18next";

const MainContent = ({
  leadFieldsData,
  isFetching,
  control,
  watch,
  dirtyFields,
  errors,
  leadData,
  setValue,
  fields,
  append,
  remove,
  retrieveContacts,
  handleContactSelect,
  selectedOption,
  rerender,
  getValues,
}) => {
  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <div style={{ display: "flex", width: "100%", gap: "10px" }}>
      <div
        className="flex"
        style={{ gap: "10px", display: "flex", flexDirection: "column", width: "65%" }}
        key={rerender}
      >
        <Controller
          control={control}
          key={rerender}
          name="name"
          render={({ field: { name, onChange, value } }) => (
            <Text
              label="Name"
              name={name}
              key={rerender}
              type="text"
              value={value}
              marginBottom={true}
              required={true}
              onChange={onChange}
              errors={errors && errors[name]}
            />
          )}
        />
        <Controller
          control={control}
          name="note"
          render={({ field: { name, onChange, value } }) => (
            <TextArea
              name={"note"}
              errors={errors && errors[name]}
              label={"Note"}
              data={value ?? ""}
              onChange={onChange}
            />
          )}
        />

        <ProductsSection
          control={control}
          watch={watch}
          fields={fields}
          append={append}
          remove={remove}
          setValue={setValue}
          getValues={getValues}
        />
      </div>

      <div style={{ width: "35%" }}>
        <div className="tw-px-2.5">
          <Select
            name="contacts"
            label={t("caymland.brochures.contact.action.search")}
            options={retrieveContacts}
            isClearable={false}
            margin
            value={selectedOption}
            onChange={handleContactSelect}
          />
        </div>
        <Fields
          leadFieldsData={leadFieldsData}
          control={control}
          watch={watch}
          dirtyFields={dirtyFields}
          errors={errors}
          leadData={leadData}
          setValue={setValue}
        />
      </div>
    </div>
  );
};

export default MainContent;
