import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import "react-dropzone-uploader/dist/styles.css";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { t } from "i18next";
import { Text } from "../../reusableComponents/Inputs";

const FolderModal = ({
  showModal,
  setShowModal,
  handleClose,
  handleSave,

  handleAddFolder,
  modalOptions,
  handleEditFolder,
  handleAddSubFolder,
  setFolderName,
  folderName,
}) => {
  const buttons = [
    {
      name: "close",
      title: `${t("caymland.core.close")}`,
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => setShowModal(false),
    },
    {
      name: "save",
      title: t("caymland.core.form.add"),
      //   title: modalOptions?.type === "edit" ? t("caymland.core.form.update") : t("caymland.core.form.add"),

      icon: <AiOutlineSave size={15} />,
      onClick: handleSave,
    },
  ];

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {/* {modalOptions?.type === "edit" ? t("caymland.core.form.edit") : t("caymland.core.form.add")}{" "} */}
          {/* {modalOptions?.type === "addsub" ? "Subfolder" : t("caymland.mailbox.rules.folder")} */}
          {t("caymland.core.form.add")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <Text
          name={t("caymland.core.name")}
          label={`${t("caymland.mailbox.rules.folder")} ${t("caymland.lottery.table.row.name")}`}
          onChange={(e) => {
            setFolderName(e.target.value);
          }}
          value={folderName}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default FolderModal;
