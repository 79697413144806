import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { arrayMoveImmutable } from "array-move";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { useCreateFieldMutation, useGetFieldByIdQuery, useUpdateFieldsMutation } from "../../../redux/api/fieldsApi";
import { SlideAlert, handleCancel } from "../../reusableComponents/Alerts";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import MainSection from "./helperComponents/MainSetion";
import SidebarCustomField from "./helperComponents/SidebarCustomField";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import TitleHeader from "../../reusableComponents/TitleHeader";
import { t } from "i18next";

function AddCustomField(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const isEdit = location.pathname.includes("edit");

  const [errors, setErrors] = useState({});

  const [noValue, setNoValue] = useState("No");
  const [yesValue, setYesValue] = useState("Yes");
  const [boolCheck, setBoolCheck] = useState(null);
  const [optionsList, setOptionsList] = useState([]);
  const [lookUpList, setLookUpList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectKey, setSelectKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState({
    label: "",
    alias: "",
    object: "contact",
    group: "core",
    type: "text",
    order: "32",
    defaultValue: null,
    isPublished: true,
    isRequired: false,
    isVisible: true,
    isShortVisible: true,
    isListable: true,
    isPubliclyUpdatable: false,
    isUniqueIdentifier: false,
    properties: {},
  });

  const toggleButtons = [
    { label: "x", value: null },
    { label: noValue, value: 0 },
    { label: yesValue, value: 1 },
  ];
  const { data, isFetching, isLoading } = useGetFieldByIdQuery(
    { object: modifiedData.object, objectId: id },
    { skip: !id }
  );
  const [createField, { isLoading: isAddLoading }] = useCreateFieldMutation();
  const [updateField, { isLoading: isEditLoading }] = useUpdateFieldsMutation();
  const isSaving = isEditLoading || isAddLoading;

  useEffect(() => {
    if (id && data?.data) {
      setModifiedData(data.data);

      const list = data.data.properties?.list;

      // Ensure list is an array before mapping
      if (Array.isArray(list) && list.length > 1) {
        setOptionsList(
          list.map((item, index) => ({
            label: item.label,
            value: item.value,
            id: index + 1,
            disabled: true,
          }))
        );

        setLookUpList(
          list.map((item, index) => ({
            value: item,
            id: index + 1,
          }))
        );
      } else {
        setOptionsList([]); // Reset if list is not a valid array or has 1 or fewer elements
        setLookUpList([]);
      }

      if (data.data.type === "boolean") {
        setBoolCheck(data.data.defaultValue === "1" ? 1 : data.data.defaultValue === "0" ? 0 : null);

        setNoValue(data.data.properties?.no || "No");
        setYesValue(data.data.properties?.yes || "Yes");

        setModifiedData((prevData) => ({
          ...prevData,
          properties: {
            ...prevData.properties,
            no: prevData.properties?.no || "No",
            yes: prevData.properties?.yes || "Yes",
          },
        }));
      }
    }
  }, [data]);

  useEffect(() => {
    let updatedProperties = {};
    let shouldUpdateProperties = false;

    if (modifiedData.type === "lookup") {
      updatedProperties = { list: lookUpList.map((item) => item.value) };
      shouldUpdateProperties = true;
    } else if (modifiedData.type === "select" || modifiedData.type === "multiselect") {
      updatedProperties = {
        list: optionsList.map((item) => ({
          label: item.label,
          value: item.value,
        })),
      };
      shouldUpdateProperties = true;
    } else if (modifiedData.type === "text") {
      setModifiedData({ ...modifiedData, defaultValue: "" });
    } else {
      setModifiedData({ ...modifiedData, defaultValue: null });
    }

    if (shouldUpdateProperties) {
      setModifiedData((prevData) => ({
        ...prevData,
        properties: updatedProperties,
      }));
    }
  }, [lookUpList, optionsList, modifiedData.type]);

  const handleOptionsRemove = (index) => {
    const list =
      modifiedData.type === "multiselect" || modifiedData.type === "select" ? [...optionsList] : [...lookUpList];
    list.splice(index, 1);
    if (modifiedData.type === "multiselect" || modifiedData.type === "select") {
      setOptionsList(list);
    } else {
      setLookUpList(list);
    }
  };

  const handleOptionsAdd = () => {
    if (modifiedData.type === "multiselect" || modifiedData.type === "select") {
      let id = optionsList.length + 1;
      setOptionsList([...optionsList, { id: id, label: "", value: "", position: id }]);
    } else {
      let id = lookUpList.length + 1;
      setLookUpList([...lookUpList, { id: id, label: "", value: "", position: id }]);
    }
  };

  const handleOptionsChange = (e, index, type) => {
    const { name, value } = e.target;
    const list =
      modifiedData.type === "multiselect" || modifiedData.type === "select" ? [...optionsList] : [...lookUpList];
    if (modifiedData.type === "multiselect" || modifiedData.type === "select") {
      list[index][type] = value;
      setOptionsList(list);
    } else {
      list[index]["value"] = value;
      setLookUpList(list);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let arr =
      modifiedData.type === "multiselect" || modifiedData.type === "select"
        ? arrayMoveImmutable(optionsList, oldIndex, newIndex)
        : arrayMoveImmutable(lookUpList, oldIndex, newIndex);
    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }
    if (modifiedData.type === "multiselect" || modifiedData.type === "select") {
      setOptionsList(arr);
    } else {
      setLookUpList(arr);
    }
    setSelectKey((prevKey) => prevKey + 1);
  };
  const validateFields = () => {
    const newErrors = {};

    if (!modifiedData.label) newErrors.label = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };
  const handleSubmit = async (params) => {
    if (!validateFields()) return;
    setLoading(true);
    try {
      let response;

      const payload = { ...modifiedData };
      // delete payload.order;

      if (isClone === true) {
        payload.alias = null;
      }

      let currentId;
      if (id && !isClone) {
        response = await updateField({ object: payload.object, objectId: id, field: payload });
        currentId = response?.data?.field?.id;
      } else {
        response = await createField({ objectType: payload.object, field: payload }).unwrap();
        currentId = response?.field?.id;
      }

      if (response && !response.error) {
        setLoading(false);
        SweetAlert.fire({
          title: t("caymland.core.success"),
          text: t("caymland.field.save.success"),
          icon: "success",
          confirmButtonColor: "#135f95",
          confirmButtonText: t("caymland.core.close"),
          customClass: {
            confirmButton: "result-component swal-custom",
          },
        }).then((result) => {
          if (result.isConfirmed && params === "back") {
            navigate("/contacts/fields");
          } else {
            navigate(`/contacts/fields/edit/${currentId}`);
          }
        });
      }
    } catch (error) {
      if (error) {
        setShowAlert(true);
        setAlertMessage(error?.data?.errors?.[0]?.message);
        setLoading(false);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 7000);
        return;
      }
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/contacts/fields", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleSubmit("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleSubmit(),
    },
  ];

  return (
    <Fragment>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      {isLoading || loading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isSaving}
          title={
            isEdit
              ? t("caymland.lead.field.header.edit", { name: modifiedData?.label })
              : t("caymland.lead.field.header.new")
          }
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          loading || isLoading ? (
            <DetailsSkeleton />
          ) : (
            <>
              <MainSection
                errors={errors}
                modifiedData={modifiedData}
                setModifiedData={setModifiedData}
                handleOptionsAdd={handleOptionsAdd}
                optionsList={optionsList}
                setOptionsList={setOptionsList}
                onSortEnd={onSortEnd}
                handleOptionsChange={handleOptionsChange}
                handleOptionsRemove={handleOptionsRemove}
                lookUpList={lookUpList}
                noValue={noValue}
                setNoValue={setNoValue}
                yesValue={yesValue}
                setYesValue={setYesValue}
                id={id}
                isClone={isClone}
                isEdit={isEdit}
                toggleButtons={toggleButtons}
                boolCheck={boolCheck}
                setBoolCheck={setBoolCheck}
                selectKey={selectKey}
              />
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          loading || isLoading ? (
            <SidebarSkeleton />
          ) : (
            <>
              <SidebarCustomField modifiedData={modifiedData} setModifiedData={setModifiedData} />
            </>
          )
        }
      />
    </Fragment>
  );
}

export default AddCustomField;
