import React, { Fragment, useState, useEffect } from "react";
import { objectData, dataTypeData } from "../../../reusableComponents/data";

import { useGetContactFieldsQuery, useGetCompanyFieldsQuery } from "../../../../redux/api/fieldsApi";

import { Select, Text, Label } from "../../../reusableComponents/Inputs";
import HelperComponent from "./RenderByType";
import DefaultValue from "./DefaultValue";
import { t } from "i18next";

const MainSection = ({
  modifiedData,
  setModifiedData,
  handleOptionsAdd,
  optionsList,
  onSortEnd,
  handleOptionsChange,
  handleOptionsRemove,
  lookUpList,
  noValue,
  setNoValue,
  yesValue,
  setYesValue,
  id,
  isClone,
  isEdit,
  toggleButtons,
  boolCheck,
  setBoolCheck,
  selectKey,
  setOptionsList,
  errors,
}) => {
  const [uniqueFieldGroups, setUniqueFieldGroups] = useState([]);
  const { data: contactData } = useGetContactFieldsQuery();
  const { data: companyData } = useGetCompanyFieldsQuery();
  useEffect(() => {
    if (companyData?.data && contactData?.data) {
      retrieveFieldGroups(contactData.data, "contact");
      retrieveFieldGroups(companyData.data, "company");
    }
  }, [companyData, contactData]);

  const retrieveFieldGroups = (data, fieldType) => {
    let customFields = [...data];
    let groups = customFields.map((x) => {
      if (x.isPublished === true || x.isRequired === true) {
        return x.group;
      }
    });

    let uniqueGroups = groups.filter((group, index, self) => {
      return self.indexOf(group) === index && group !== undefined;
    });

    setUniqueFieldGroups((prevUniqueFieldGroups) => {
      return [...prevUniqueFieldGroups, ...uniqueGroups.filter((group) => !prevUniqueFieldGroups.includes(group))];
    });
  };

  return (
    <>
      <div className="flex gap-10 w-100">
        <Text
          name="label"
          errors={errors.label}
          label={t("caymland.form.field.form.label")}
          wFull="w-50"
          value={modifiedData.label}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              label: e.target.value,
            })
          }
          required={true}
        />
        <Text
          name="alias"
          label={t("caymland.core.alias")}
          disabled={!!id && (isClone === true || isEdit === true)}
          tooltip={t("caymland.lead.field.help.alias")}
          value={modifiedData.alias}
          wFull="w-50"
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              alias: e.target.value,
            })
          }
        />
      </div>

      <div className="flex gap-10 w-100">
        <div style={{ alignSelf: "end", width: "50%" }}>
          <Select
            name="validationDefault01"
            options={objectData.map((option) => ({
              label: t(option.label),
              value: option.value,
            }))}
            isClearable={false}
            isDisabled={!!id && (isClone === true || isEdit === true)}
            value={modifiedData.object} // Set value to the selected object directly
            label={t("caymland.lead.field.object")}
            onChange={(e) =>
              setModifiedData({
                ...modifiedData,
                object: e.value,
              })
            }
          />
        </div>
        <div style={{ width: "50%" }}>
          <Select
            name="validationDefault02"
            isClearable={false}
            tooltip={t("caymland.lead.field.form.group.help")}
            options={uniqueFieldGroups.map((group) => ({
              value: group,
              label: group
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(" "),
            }))}
            value={{
              value: modifiedData.group,
              label: modifiedData.group
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(" "),
            }}
            label={t("caymland.lead.field.group")}
            onChange={(selectedOption) => setModifiedData({ ...modifiedData, group: selectedOption.value })}
          />
        </div>
      </div>

      <div className="flex gap-10 w-100">
        <div
          className="w-50"
          style={{
            alignSelf: modifiedData.type === "textarea" ? "start" : "end",
            marginTop: modifiedData.type === "textarea" ? "2.5px" : undefined,
          }}
        >
          <Select
            name="validationDefault03"
            options={dataTypeData}
            isClearable={false}
            isDisabled={!!id && (isClone === true || isEdit === true)}
            label={t("caymland.lead.field.type")}
            placeholder={modifiedData.type === "tel" ? "Phone" : modifiedData.type}
            value={dataTypeData.find((d) => d.value === modifiedData.type)}
            onChange={(e) => {
              let newType = e.value === "phone" ? "tel" : e.value;
              setModifiedData((prevData) => ({
                ...prevData,
                type: newType,
                properties: newType === "boolean" ? { no: "No", yes: "Yes" } : prevData.properties,
              }));
              if (newType === "select" || newType === "multiselect") {
                setOptionsList([]);
              }
            }}
          />
        </div>

        <div className="w-50">
          <Label
            name="Default Value"
            label={t("caymland.core.defaultvalue")}
            tooltip={t("caymland.lead.field.help.defaultvalue")}
          />
          <DefaultValue
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            toggleButtons={toggleButtons}
            boolCheck={boolCheck}
            setBoolCheck={setBoolCheck}
            noValue={noValue}
            yesValue={yesValue}
            selectKey={selectKey}
            lookUpList={lookUpList}
            optionsList={optionsList}
          />
        </div>
      </div>

      <div style={{ paddingRight: "10px" }}>
        <HelperComponent
          modifiedData={modifiedData}
          setModifiedData={setModifiedData}
          handleOptionsAdd={handleOptionsAdd}
          optionsList={optionsList}
          onSortEnd={onSortEnd}
          handleOptionsChange={handleOptionsChange}
          handleOptionsRemove={handleOptionsRemove}
          lookUpList={lookUpList}
          noValue={noValue}
          setNoValue={setNoValue}
          yesValue={yesValue}
          setYesValue={setYesValue}
        />
      </div>
    </>
  );
};

export default MainSection;
