import React, { useEffect, useRef, useState } from "react";
import { Select } from "../../../reusableComponents/Inputs";
import { tagsApi } from "../../../../redux/api";
import { retrieveTags } from "../../../reusableComponents/retrieveTags/retrieveTagsFunc";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";
import { AiOutlineSave } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CustomCreatableSelectInput from "../../../reusableComponents/Inputs/InputSelect";
import { useAddTagMutation } from "../../../../redux/api/tagsApi";
import { FaRegTimesCircle } from "react-icons/fa";

const Tags = ({ tagsFields, replaceTags, setShow, setMessage, setBackgroundToast }) => {
  const { t } = useTranslation();
  const [tagsEdit, setTagsEdit] = useState(false);
  const [tags, setTags] = useState();
  const [initialTags, setInitialTags] = useState([]);
  const [tagsShown, setTagsShown] = useState([]);
  const [hover, setHover] = useState(false);
  const editRef = useRef(null);
  const { id } = useParams();
  const [addTag] = useAddTagMutation();

  useEffect(() => {
    if (tagsFields) {
      const formattedData = tagsFields.map((tag) => ({
        label: tag?.tag || tag.label,
        value: tag?.tag || tag.label,
      }));
      setTags(formattedData);
      setTagsShown(formattedData);
      setInitialTags(formattedData.map((tag) => tag.label));
    }
  }, [tagsFields]);

  const [triggerTags] = tagsApi.useLazyGetTagsQuery();
  const [updateContact] = useUpdateContactsMutation();

  const updateTags = async () => {
    const tagsIds = tags.map((tag) => tag.value);
    try {
      const response = await updateContact({ id, tags: tagsIds });
      if (!response.errors) {
        replaceTags(response.data.contact.tags);
        setTagsEdit(false);
        setShow(true);
        setMessage(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      console.error("Error updating tag:", error);
    }
  };
  const handleChange = (selectedOption) => {
    // Convert selected options to an array of values
    const selectedTags = selectedOption ? selectedOption.map((option) => option.value) : [];
    // Determine which tags have been removed
    const removedTags = initialTags.filter((tag) => !selectedTags.includes(tag));
    // Create updated tags with the same structure as selectedOption
    // For removed tags, add '-' before the value
    const updatedTags = selectedOption.concat(removedTags.map((tag) => ({ label: tag, value: "-" + tag })));

    setTags(updatedTags);
    setTagsShown(selectedOption);
  };

  const handleCreateOption = async (inputValue) => {
    const newTag = { label: inputValue, value: inputValue };
    setTags((prevTags) => [...prevTags, newTag]);
    setTagsShown((prevTags) => [...prevTags, newTag]);
    await addTag({ tag: inputValue });
  };
  const handleCancel = (e) => {
    e.stopPropagation();
    setTagsEdit(false);
    // Optionally, revert any unsaved changes
    const formattedData = tagsFields?.map((tag) => ({
      label: tag?.tag || tag.label,
      value: tag?.tag || tag.label,
    }));
    setTagsShown(formattedData);
  };
  return (
    <div
      ref={editRef}
      className={`cursor-pointer flex items-center pl-10 mt-10 gap-5px bl-1 w-50 `}
      onClick={() => setTagsEdit(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div>
        <span style={{ marginBottom: "0" }}>{t("caymland.mailbox.message.tags")} </span>
      </div>
      {tagsEdit ? (
        <div className="flex items-center gap-5px w-full">
          <CustomCreatableSelectInput
            name={"Tags"}
            isClearable
            isMulti={true}
            margin
            className="w-full"
            options={retrieveTags}
            trigger={triggerTags}
            value={tagsShown}
            onChange={handleChange}
            onCreateOption={handleCreateOption}
          />
          <group className="flex gap-5px items-center ">
            {" "}
            <button className="normal-btn" onClick={(e) => handleCancel(e)}>
              <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
            </button>
            <button className="normal-btn" onClick={updateTags}>
              <AiOutlineSave color="#135F95" size={20} title="Save" />
            </button>
          </group>
        </div>
      ) : (
        <>
          <div className="flex items-center  w-full" style={{ display: "flex", flexWrap: "wrap" }}>
            {tagsFields?.map((tag, i) =>
              tag?.tag?.startsWith("-") || (tag?.value && tag?.value.startsWith("-")) ? null : (
                <p
                  className="badge badge-dark tag-pills-sm-mb gap-5px"
                  style={{
                    margin: "2px",
                    marginLeft: "5px",
                    fontSize: "0.75",
                    fontWeight: "400",
                    background: "#d33d3d",
                  }}
                >
                  {tag.tag || tag.label}
                </p>
              )
            )}
            {hover && !tagsEdit && <Edit fontSize="small" style={{ marginLeft: "auto", marginRight: "10px" }} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Tags;
