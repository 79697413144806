import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const tempApi = createApi({
  reducerPath: "tempApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Template"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getTemps: build.query({
      query: (q) =>
        `/api/mailbox/template?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Template"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addTemp: build.mutation({
      query: (body) => ({
        url: `api/mailbox/template/new`,
        method: "POST",
        body,
      }),
    }),
    renderTemp: build.mutation({
      query: (body) => ({
        url: `api/mailbox/templates/render`,
        method: "POST",
        body,
      }),
    }),
    updateTemp: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/mailbox/template/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Template"],
    }),
    getTemp: build.query({
      query: (id) => `/api/mailbox/template/${id}`,
      providesTags: (id) => [{ type: "Template", id }],
      lazy: true,
    }),
    deleteTemp: build.mutation({
      query: (id) => ({
        url: `/api/mailbox/template/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Template"],
    }),
    getTemplateSelect: build.query({
      query: (q) => `/api/mailbox/template/select`,
      keepUnusedDataFor: false,
      providesTags: ["Template"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetTempsQuery,
  useAddTempMutation,
  useUpdateTempMutation,
  useGetTempQuery,
  useDeleteTempMutation,
  useRenderTempMutation,
  useGetTemplateSelectQuery,
} = tempApi;
