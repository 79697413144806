import React, { useEffect, useCallback, useState } from "react";
import Loading from "../../../reusableComponents/loading";
import Dropzone from "react-dropzone-uploader";
import { Text } from "../../../reusableComponents/Inputs";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";

const MainContent = ({
  getUploadParams,
  handleChangeStatus,
  errorsMsg,
  uploadImg,
  pdfUploaded,
  modifiedData,
  setModifiedData,
  handleInputChange,
  isFetching,
  errors,
}) => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (modifiedData.file) {
  //     const newExtension = modifiedData.file.split(".").pop();
  //     setModifiedData((prev) => ({
  //       ...prev,
  //       extension: newExtension,
  //     }));
  //   }
  // }, [modifiedData.file, setModifiedData]);

  // Function to render content based on file type
  const renderFileContent = () => {
    const fileUrl =
      modifiedData.downloadUrl && Object.keys(modifiedData.downloadUrl).length > 0
        ? `${modifiedData.downloadUrl}?stream=1&api=1`
        : `${modifiedData.file || modifiedData.remotePath}?stream=1&api=1`;

    if (!fileUrl || fileUrl === "undefined?stream=1&api=1") {
      return null;
    } else if (pdfUploaded || modifiedData.mime === "application/pdf") {
      // Render PDF
      return (
        <iframe
          src={pdfUploaded || fileUrl}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{
            display: "block",
            margin: "20px 0",
            backgroundColor: "#fff",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            border: "none",
          }}
        >
          <p style={{ color: "#4a4a4a", fontSize: "15px", textAlign: "center" }}>
            {t("caymland.asset.display.pdf")}
            <a
              href={`${modifiedData.file ? modifiedData.file : modifiedData?.downloadUrl}?stream=1&api=1`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#0056b3",
                textDecoration: "none",
                fontWeight: "bold",
                backgroundColor: "#e7f1ff",
                borderRadius: "3px",
                padding: "5px 10px",
                marginLeft: "5px",
              }}
            >
              {t("caymland.core.download")}
            </a>
            {t("caymland.asset.display.instead")}
          </p>
        </iframe>
      );
    } else if (uploadImg || /^image\/(png|jpg|jpeg|gif|bmp|svg\+xml)$/.test(modifiedData.mime)) {
      // Render image
      return (
        <img
          src={uploadImg || fileUrl}
          alt="Uploaded"
          style={{
            display: "block",
            width: "100%",
            margin: "20px 0",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            borderRadius: "8px",
          }}
        />
      );
    } else {
      return <i style={{ fontSize: "100px" }} className={modifiedData?.iconClass}></i>;
    }
  };

  // Button to download the file
  const renderDownloadButton = () => {
    if (modifiedData.downloadUrl && Object.keys(modifiedData.downloadUrl).length > 0) {
      return (
        <a
          href={`${modifiedData.downloadUrl}?api=1`}
          download
          style={{
            display: "inline-block",
            padding: "10px 20px",
            backgroundColor: "#135f95",
            color: "#fff",
            textDecoration: "none",
            borderRadius: "5px",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          {t("caymland.core.download")} {t("caymland.form.field.type.file")}
        </a>
      );
    }
    return null;
  };

  return isFetching ? (
    <DetailsSkeleton applyPadding />
  ) : (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "hidden", padding: "10px" }}>
      {modifiedData.storageLocation === "remote" ? (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Text
              wFull={modifiedData.downloadUrl && Object.keys(modifiedData.downloadUrl).length > 0 ? "w-90" : "w-full"}
              marginBottom
              type="url"
              name="file"
              value={modifiedData.file || modifiedData.remotePath}
              required
              onChange={handleInputChange}
              tooltip="If the file is not showing, press any key at the end of the URL."
              label={t("caymland.asset.asset.form.remotePath")}
            />
            {renderDownloadButton()}
          </div>
        </>
      ) : (
        <div style={{ width: "100%", marginBottom: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p className="font-13">{t("caymland.asset.asset.form.file.upload", { max: "2MB" })}</p>
            {renderDownloadButton()}
          </div>
          <form className="dropzone digits mt-10px" id="singleFileUpload">
            <div style={{ border: "2px dashed green", borderRadius: "8px", width: "100%", padding: "20px" }}>
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent={t("caymland.asset.drop.file.here")}
                classNames={{
                  preview: "dz-preview",
                  remove: "dz-remove-custom",
                }}
              />
            </div>
          </form>
        </div>
      )}

      {errorsMsg ? (
        <p style={{ color: "red", fontSize: "10px", textAlign: "center", marginBottom: "20px" }}>
          {modifiedData.storageLocation === "remote"
            ? t("caymland.asset.asset.error.missing.remote.path")
            : t("caymland.asset.asset.error.missing.local.path")}
        </p>
      ) : (
        <div style={{ flexGrow: 1 }}>{renderFileContent()}</div>
      )}
    </div>
  );
};

export default MainContent;
