import React, { Fragment, useMemo } from "react";
import { useState, useEffect } from "react";
import { Text, Select } from "../../reusableComponents/Inputs";
import { useGetUsersOptionsQuery } from "../../../redux/api/usersApi";
import TimezoneSelect from "../../reusableComponents/ReusableSelects/Timezone";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons for show/hide

const AccountDetails = ({ formData, setFormData, newEditedUser, setNewEditedUser, errors, setErrors }) => {
  const { t } = useTranslation();
  const [timezones, setTimezones] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [defaultFolder, setDefaultFolder] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [template, setTemplate] = useState([]);
  const [password, setPassword] = useState({
    password: "",
    confirm: "",
  });

  const { data } = useGetUsersOptionsQuery({});
  useEffect(() => {
    if (data) {
      setTimezones(data.timezone);
      setLanguages(data.locale);
      setDefaultFolder(data.defaultFolder);
      setDefaultAddress(data.defaultAddress);
      setTemplate(data.template);
    }
  }, [data]);

  useEffect(() => {
    setFormData({
      firstName: newEditedUser.firstName || "",
      lastName: newEditedUser.lastName || "",
      email: newEditedUser.email || "",
      username: newEditedUser.username || "",
      signature: newEditedUser.signature || "",
      position: newEditedUser.position || "",
      phone1: newEditedUser.phone1 || "",
      phone2: newEditedUser.phone2 || "",
      department: newEditedUser.department || "",
      city: newEditedUser.city || "",
      street: newEditedUser.street || "",
      zip: newEditedUser.zip || "",
      fax: newEditedUser.fax || "",
      timezone: newEditedUser.timezone || null,
      locale: newEditedUser.locale || null,
      standardTemplate: newEditedUser.standardTemplate || null,
      defaultAddress: newEditedUser.defaultAddress || null,
      defaultFolder: newEditedUser.defaultFolder || null,
      plainPassword: {
        confirm: formData?.password || null,
        password: formData?.confirm || null,
      },
    });
  }, [newEditedUser]);

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  useEffect(() => {
    // Update the newEditedUser state with the "plainPassword" field
    setNewEditedUser((prevData) => ({
      ...prevData,
      plainPassword: {
        password: password.password,
        confirm: password.confirm,
      },
    }));
  }, [password, setNewEditedUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEditedUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSelectChange = (name, selectedOption) => {
    setNewEditedUser((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSelectChangeValue = (name, selectedOption) => {
    if (selectedOption) {
      // Extract the value from the selected option
      let value = selectedOption.value;
      if (name === "defaultFolder") {
        // Convert to number if the field is "defaultFolder"
        value = parseInt(value, 10);
      }
      setNewEditedUser((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Handle the case when no option is selected (e.g., clearing the selection)
      setNewEditedUser((prevData) => ({
        ...prevData,
        [name]: null, // or any default value you want to use
      }));
    }
  };

  const formattedDefaultAddressOptions = Object.keys(defaultAddress).map((email) => ({
    value: email,
    label: defaultAddress[email],
  }));

  const formattedDefaultFolderOptions = Object.keys(defaultFolder).map((folderId) => ({
    value: parseInt(folderId, 10),
    label: defaultFolder[folderId],
  }));

  let formattedLanguages = Object.entries(languages).map(([languageValue, languageName]) => ({
    value: languageValue,
    label: languageName,
  }));

  formattedLanguages = [{ label: t("caymland.user.user.form.defaultlocale"), value: "" }, ...formattedLanguages];

  const formattedTimezones = Object.entries(timezones).reduce((acc, [region, regionTimezones]) => {
    const regionOptions = Object.entries(regionTimezones).map(([timezoneValue, timezoneName]) => ({
      label: timezoneValue,
      value: timezoneValue,
    }));
    return [...acc, ...regionOptions];
  }, []);

  const selectedTimezone = formattedTimezones.find((option) => option.value === formData.timezone);
  const selectedLanguage =
    formattedLanguages.find((option) => option.value === formData.locale) || formattedLanguages[0];
  const selectedDefaultAddress = formattedDefaultAddressOptions.find(
    (option) => option.value === formData.defaultAddress
  );
  const selectedDefaultFolder = formattedDefaultFolderOptions.find((option) => option.value === formData.defaultFolder);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Fragment>
      <div className="accResponsivness" style={{ gap: "16px", padding: "10px" }}>
        <div className="w-full borderSection ">
          <div className="group-fields-style w-full " style={{ padding: "30px" }}>
            <h2 style={{ fontWeight: "600", marginBottom: "20px" }}>{t("caymland.user.users")}</h2>
            <div className="d-flex w-full gap-10">
              <Text
                id="firstName"
                className="jump-input"
                type="text"
                wFull="w-full"
                label={t("caymland.core.firstname")}
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
                errors={errors.firstName}
              />

              <Text
                id="lastName"
                className="jump-input"
                type="text"
                label={t("caymland.core.lastname")}
                wFull="w-full"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
                errors={errors.lastName}
              />
            </div>

            <Text
              id="email"
              className="jump-input"
              type="text"
              label={t("caymland.user.account.permissions.editemail")}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              errors={errors.email}
            />

            <Text
              id="username"
              className="jump-input"
              type="text"
              label={t("caymland.user.account.permissions.editusername")}
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
              errors={errors.username}
            />

            <div className="d-flex w-full gap-10">
              {/* Password Field */}
              <div style={{ position: "relative", width: "100%" }}>
                <Text
                  required
                  id="password"
                  className="jump-input"
                  type={showPassword ? "text" : "password"}
                  label={t("caymland.user.user.passwordreset.password.placeholder")}
                  wFull="w-full"
                  name="password"
                  value={password.password}
                  onChange={handlePasswordInputChange}
                  errors={errors.password}
                  autoComplete={false}
                />
                {/* Show/Hide password icon for password */}
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "40%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    fontSize: "1.5em",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              {/* Confirm Password Field */}
              <div style={{ position: "relative", width: "100%" }}>
                <Text
                  required
                  id="confirmPassword"
                  className="jump-input"
                  type={showConfirmPassword ? "text" : "password"} // Toggle between text/password type for confirm password field
                  wFull="w-full"
                  label={t("caymland.user.user.passwordreset.confirm.placeholder")}
                  name="confirm"
                  value={password.confirm}
                  onChange={handlePasswordInputChange}
                  errors={errors.confirm}
                  autoComplete={false}
                />
                {/* Show/Hide password icon for confirm password */}
                <span
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "40%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    fontSize: "1.5em",
                  }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>

            <div className="d-flex w-full gap-10">
              <Text
                id="phone1"
                className="jump-input"
                type="text"
                label={t("caymland.user.field.phone1")}
                wFull="w-full"
                name="phone1"
                value={formData.phone1}
                onChange={handleInputChange}
              />

              <Text
                id="phone2"
                className="jump-input"
                type="text"
                label={t("caymland.user.field.phone2")}
                wFull="w-full"
                name="phone2"
                value={formData.phone2}
                onChange={handleInputChange}
              />
            </div>

            <Select
              label={t("caymland.core.form.defaultaddress")}
              options={formattedDefaultAddressOptions}
              value={selectedDefaultAddress}
              onChange={(selectedOption) => handleSelectChangeValue("defaultAddress", selectedOption)}
            />
          </div>
          <div className="group-fields-style w-full " style={{ padding: "30px" }}>
            <h2 style={{ fontWeight: "600", marginBottom: "20px" }}>{t("caymland.lead.field.address")}</h2>

            <Text
              id="city"
              className="jump-input"
              type="text"
              label={t("caymland.user.field.city")}
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />

            <div className="d-flex w-full gap-10">
              <Text
                id="street"
                className="jump-input"
                type="text"
                label={t("caymland.user.field.street")}
                wFull="w-full"
                name="street"
                value={formData.street}
                onChange={handleInputChange}
              />

              <div style={{ width: "40%" }}>
                <Text
                  id="zip"
                  className="jump-input"
                  type="text"
                  label={t("caymland.user.field.zip")}
                  name="zip"
                  value={formData.zip}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full borderSection">
          <div className="group-fields-style w-full " style={{ padding: "30px" }}>
            <h2 style={{ fontWeight: "600", marginBottom: "20px" }}>{t("caymland.core.zone")}</h2>
            <div className="d-flex w-full gap-10" style={{ marginTop: "-16px" }}>
              <div className="w-full mb-20px">
                <TimezoneSelect
                  label={t("caymland.lead.field.type.timezone")}
                  value={selectedTimezone || ""}
                  defaultTimeZone={true}
                  onChange={(selectedOption) => handleSelectChangeValue("timezone", selectedOption)}
                />
              </div>
              <Select
                required
                errors={errors.locale}
                options={formattedLanguages}
                value={selectedLanguage || ""}
                onChange={(selectedOption) => handleSelectChangeValue("locale", selectedOption)}
                label={t("caymland.mailbox.message.language")}
                formatOptionLabel={(option) => option.label} // Display the label in the dropdown
              />
            </div>
          </div>
          <div className="group-fields-style w-full " style={{ padding: "30px" }}>
            <h2 style={{ fontWeight: "600", marginBottom: "20px" }}>{t("caymland.core.other")}</h2>

            <Text
              id="positon"
              className="jump-input"
              type="text"
              label={t("caymland.user.account.permissions.editposition")}
              name="position"
              value={formData.position}
              onChange={handleInputChange}
            />

            <Text
              id="department"
              className="jump-input"
              type="text"
              label={t("caymland.user.field.department")}
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            />
            <Text
              id="fax"
              className="jump-input"
              type="text"
              label={t("caymland.user.field.fax")}
              name="fax"
              value={formData.fax}
              onChange={handleInputChange}
            />

            <Text
              id="signature"
              className="jump-input"
              type="text"
              label={t("caymland.email.token.signature")}
              name="signature"
              value={formData.signature}
              onChange={handleInputChange}
            />

            <Select
              options={formattedDefaultFolderOptions}
              value={selectedDefaultFolder}
              onChange={(selectedOption) => handleSelectChangeValue("defaultFolder", selectedOption)}
              label={t("caymland.core.form.deafultfolder")}
            />

            <Select
              options={template}
              value={formData.standardTemplate}
              onChange={(selectedOption) => handleSelectChange("standardTemplate", selectedOption)}
              label={t("caymland.user.field.template")}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AccountDetails;
