import React, { useMemo, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Select } from "../../../reusableComponents/Inputs"; // Assuming you're using the Select component from your Inputs module
import CustomTable from "../CustomTable/CustomTable";
import { t } from "i18next";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { useRemovePurposeMutation } from "../../../../redux/api/purposesApi";

const Purposes = ({ control, purposes, id, setValue, refetch, isFetching }) => {
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
  });

  // Track the data for the table (initialize with purposes.data)
  const [tableData, setTableData] = useState(purposes?.data ?? []);

  // Initialize selectedPurposes based on the initial tableData
  const [selectedPurposes, setSelectedPurposes] = useState(() => {
    const existingIds = purposes?.data.map((item) => item.id);
    return purposes?.values.filter((option) => existingIds.includes(option.value)) ?? [];
  });

  // Memoize the columns configuration
  const columns = useMemo(
    () => [
      {
        accessorKey: "Purpose",
        accessorFn: (row) => row.purpose,
        header: t("caymland.contact.export.purpose"),
        enableColumnActions: false,
      },
      {
        accessorKey: "ValidFrom",
        accessorFn: (row) => row.valid_from,
        header: t("caymland.contact.export.valid.from"),
        enableColumnActions: false,
      },
      {
        accessorKey: "ValidUntil",
        accessorFn: (row) => row.valid_until,
        header: t("caymland.contact.export.valid.until"),
        enableColumnActions: false,
      },
    ],
    []
  );

  // Effect to update the form values when selectedPurposes change
  useEffect(() => {
    setValue("selectedPurpose", selectedPurposes); // Set form value when selected purposes change
  }, [selectedPurposes, setValue]);

  // Update table data whenever selected purposes change
  useEffect(() => {
    const updatedTableData = selectedPurposes.map((selected) => {
      const matchingPurpose = purposes.values.find((option) => option.value === selected.value);
      const existingTableItem = tableData.find((item) => item.id === matchingPurpose.value);

      return (
        existingTableItem || {
          id: matchingPurpose.value,
          purpose: matchingPurpose.label,
          valid_from: "",
          valid_until: "",
        }
      );
    });

    setTableData(updatedTableData); // Update table data based on current selected purposes
  }, [selectedPurposes, purposes.values]);

  // Filter out options that are already in the table data
  const availableOptions = useMemo(() => {
    const existingIds = tableData.map((item) => item.id);
    return purposes.values.filter((option) => !existingIds.includes(option.value));
  }, [tableData, purposes.values]);

  const [removePurpose] = useRemovePurposeMutation();

  const actionButtons = ActionButtons({
    link: "purposes",
    removePurpose: removePurpose,
    showRemovePurpose: true,
    folderId: id,
    refetch: refetch,
    edit: false,
    _delete: false,
    clone: false,
  });
  console.log(tableData);

  return (
    <div style={{ padding: "1em 0" }}>
      <Controller
        name="selectedPurpose"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            options={availableOptions} // Use the filtered options
            isMulti
            value={selectedPurposes} // Set initial value for Select based on purposes in the table
            onChange={(selected) => {
              field.onChange(selected); // Update the form state
              setSelectedPurposes(selected); // Update the local selected purposes state
            }}
          />
        )}
      />
      <CustomTable
        data={tableData} // Updated table data to include new selected purposes
        columns={columns}
        query={query}
        setQuery={setQuery}
        smallHeight
        isFetching={isFetching}
        linkNavigate={null}
        actions={false}
        // actionButtons={actionButtons}
      />
    </div>
  );
};

export default Purposes;
