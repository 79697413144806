import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { ListItemIcon, MenuItem, Tooltip, IconButton, tooltipClasses } from "@mui/material";
import {} from "./MRT_ToggleSelect";
import { MRT_CustomHeaderRowsAction } from "./plugins/MRT_CustomHeaderRowsAction";
import { MRT_CustomRowsAction } from "./plugins/MRT_CustomRowsAction";
import { useNavigate } from "react-router-dom";
import DetailPanel from "./DetailPanel";
import { debounce } from "lodash";
import { MRT_GlobalSearch } from "./MRT_GlobalSearch";
import { useTranslation } from "react-i18next";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useSelector } from "react-redux";
import DetailPanelView from "./DetailPanelView";
import { useGetContactFieldsQuery } from "../../../redux/api/fieldsApi";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchModal from "../HelperTexts/SearchModal";
import ClearIcon from "@mui/icons-material/Clear";

const CustomTable = ({
  data,
  columns,
  query,
  setQuery,
  actionButtons,
  actionBatchButtons,
  fieldsType,
  linkNavigate = "view",
  showDetailPanel = false,
  toolbarButtons,
  isFetching,
  viewTable,
  dynamicColumns = false,
  columnsClicks,
  setIsOpen,
  modal,
  sort = true,
  handleReorder,
  rowDragging = false,
  setGetRowData,
  order,
  setOrder,
  highlightedLink,
  hideActionButtons = false,
  handleClick,
  pagination = true,
  actions = true,
  searchFilter = true,
  enableColumnActions = false,
  enableColumnFilters = false,
  enableSorting = true,
  columnFilters = [],
  setColumnFilters,
  filterTable = false,
  segments = false,
  disableMultiRowSelection = false,
  link,
  searchHelperBody,
  searchHelper = true,
  showGlobalFilter = true,
}) => {
  const { t } = useTranslation();
  const [overRowId, setOverRowId] = useState(null);
  const [draggedRowId, setDraggedRowId] = useState(null);
  const locale = useSelector((state) => state?.auth?.user?.locale);
  const tableLocalization = locale === "de" ? MRT_Localization_DE : MRT_Localization_EN;
  const totalDBRowCount = data?.total || data?.length || data?.count;
  const countries = useSelector((state) => state.settings.data.countries);
  const locales = useSelector((state) => state.settings.data.locales);
  const regions = useSelector((state) => state.settings.data.regions);
  const [isOpen, setIsOpenSearchModal] = useState(false);

  useEffect(() => {
    if (filterTable) {
      // Check if columnFilters is empty
      if (columnFilters.length === 0) {
        // Handle the case where no filters are present
        setQuery({ ...query, column: "", expr: "", value: "" });
      } else {
        // Get the last filter from columnFilters
        const lastFilter = columnFilters[columnFilters.length - 1];

        if (lastFilter) {
          // Determine the column name based on the last filter's id
          let columnName;
          switch (lastFilter.id) {
            case "id":
              columnName = "s.id";
              break;
            case "leadId":
              columnName = "s.lead_id";
              break;
            case "dateSubmitted":
              columnName = "s.date_submitted";
              break;
            case "ipAddress":
              columnName = "i.ip_address";
              break;
            default:
              columnName = lastFilter.id; // Fallback if no match is found
          }

          const newQuery = {
            ...query,
            column: columnName, // Use only the last column name
            expr: "like",
            value: lastFilter.value, // Use only the last filter's value
          };

          // Update the state or perform further actions with newQuery
          setQuery(newQuery);
        }
      }
    }
  }, [columnFilters, filterTable, setQuery]);

  const { data: fields } = useGetContactFieldsQuery();
  const navigate = useNavigate();

  const debouncedSetOrder = debounce((newOrder) => {
    setOrder(newOrder);
  }, 50);

  useEffect(() => {
    if (query.pageSize) {
      sessionStorage.setItem("pageSize", query?.pageSize?.toString());
    }
  }, [query.pageSize]);

  const updateOrderDuringDrag = (draggedOverRowId) => {
    if (!draggedRowId || draggedRowId === draggedOverRowId || overRowId === draggedOverRowId) return;

    setOverRowId(draggedOverRowId);

    let newOrder = [...order];
    let draggedIndex = newOrder.indexOf(draggedRowId);
    let draggedOverIndex = newOrder.indexOf(draggedOverRowId);

    if (draggedIndex < 0 || draggedOverIndex < 0) return;

    newOrder.splice(draggedIndex, 1);
    newOrder.splice(draggedOverIndex, 0, draggedRowId);

    setOrder(newOrder);
  };

  const onDragOver = (e, overId) => {
    e.preventDefault();
    updateOrderDuringDrag(overId);
  };

  const onDrop = (dropId) => {
    if (draggedRowId && dropId) {
      handleReorder(order);
    }
    setDraggedRowId(null);
  };

  const onDragStart = (rowId) => {
    setDraggedRowId(rowId);
  };
  const initialOrderBy = sessionStorage.getItem(`orderBy${query.name}`) || query.orderBy;
  const initialOrderByDir = sessionStorage.getItem(`orderByDir${query.name}`) || query.orderByDir || "DESC";

  const [sorting, setSorting] = useState([{ id: initialOrderBy, desc: initialOrderByDir === "DESC" }]);

  useEffect(() => {
    if (query.orderBy || query.orderByDir) {
      // Construct keys with component name
      const orderByKey = `orderBy${query.name}`;
      const orderByDirKey = `orderByDir${query.name}`;
      const searchByKey = [`search${query.name}`];
      // Save to local storage
      sessionStorage.setItem(orderByKey, query?.orderBy?.toString());
      sessionStorage.setItem(orderByDirKey, query?.orderByDir?.toString());
      sessionStorage.setItem(searchByKey, query?.search?.toString() || "");
    }
  }, [query.orderBy, query.orderByDir, query.search, query.name]);

  useEffect(() => {
    if (rowDragging) {
      return;
    }
    if (sorting.length > 0) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        orderByDir: sorting[0].desc ? "DESC" : "ASC",
        orderBy: sorting[0].id,
        pageIndex: 0,
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        orderByDir: "ASC",
        orderBy: query.orderBy,
        pageIndex: 0,
      }));
      setSorting([{ id: query.orderBy, desc: query.orderByDir === "ASC" ? true : false }]);
    }
  }, [sorting, setQuery]);

  const htmlToText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const columnsViewTable = useMemo(() => {
    if (dynamicColumns) {
      let keyCount = {};
      data?.data?.forEach((row) => {
        Object.keys(row).forEach((key) => {
          keyCount[key] = (keyCount[key] || 0) + 1;
        });
      });

      let columnKeys = Object.keys(keyCount).filter((key) => keyCount[key]);

      const numberColumn = {
        accessorKey: "rowNumber",
        header: "",
        accessorFn: (row, rowIndex) => {
          return query?.pageIndex * query?.pageSize + rowIndex + 1;
        },
        size: 80,
        enableColumnActions: false,
        enableSorting: false, // Disable sorting for the row number colu
      };
      return [
        numberColumn,
        ...columnKeys.map((key) => ({
          accessorKey: key,
          header: key,
          accessorFn: (row) => {
            return key === "Description" || "Beschreibung" ? htmlToText(row[key]) : row[key];
          },
          enableColumnActions: false,
          enableSorting: false, // Disable sorting for the row number colu
        })),
      ];
    } else {
      return [
        {
          accessorKey: "name",
          accessorFn: (row) => {
            if (row?.firstname) {
              if (row?.lastname) {
                return row.firstname + " " + row.lastname;
              } else {
                return row.firstname;
              }
            } else if (Array.isArray(row?.ipAddresses) && row.ipAddresses.length > 0) {
              return row.ipAddresses[0].ip ?? "";
            } else {
              return "";
            }
          },
          header: t("caymland.lottery.column.name.name"),
          enableColumnActions: false,
          enableSorting: false,
        },
        {
          accessorKey: "email",
          accessorFn: (row) => row?.email,
          header: t("caymland.core.type.email"),
          enableColumnActions: false,
          enableSorting: false,
        },
        {
          accessorKey: "Location",
          accessorFn: (row) => row.location,
          header: t("caymland.lead.lead.thead.location"),
          enableColumnActions: false,
          enableSorting: false,
          Cell: ({ cell }) => {
            const { state, city, country } = cell?.row?.original;
            let flagLetters = cell.row?.original?.fields?.country
              ? cell.row.original?.country === "Switzerland"
                ? "ch"
                : cell?.row?.original?.country.toLowerCase()
              : "";
            return state || city || country ? (
              <div>
                {flagLetters && <i className={`flag-icon flag-icon-${flagLetters.toLowerCase()}`}></i>} {city}
                {city && state && ", "} {state}
                {!city && !state && country}
              </div>
            ) : null;
          },
        },
      ];
    }
  }, [data]);

  const orderedData = useMemo(() => {
    if (!order?.length || !data?.fields) {
      return data?.fields || [];
    }

    const dataMap = new Map(data?.fields?.map((item) => [item.id, item]));

    return order.map((id) => dataMap.get(id)).filter((item) => item);
  }, [order, data?.fields]);

  const [searchValue, setSearchValue] = useState(query.search || "");

  // Update searchValue state when initialSearch or query.search changes
  useEffect(() => {
    setSearchValue(query.search || "");
  }, [query.search]);

  const debouncedSearch = useMemo(() => debounce((search) => MRT_GlobalSearch(search, setQuery), 500), [setQuery]);
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value); // Update local state
    debouncedSearch(value); // Call debounced search function
  };
  return (
    <MaterialReactTable
      data={
        rowDragging
          ? orderedData
          : Array.isArray(data)
          ? data
          : data && typeof data === "object" && data.results
          ? Object.values(data.results)
          : data?.data?.results || data?.data || data?.results || data?.fields || data || []
      }
      localization={tableLocalization}
      layout="grid"
      enableRowOrdering={rowDragging}
      columns={
        viewTable && dynamicColumns
          ? columnsViewTable
          : viewTable
          ? [
              ...columnsViewTable,
              {
                accessorKey: "id",
                accessorFn: (row) => row.id,
                header: t("caymland.points.table.id"),
                size: 80,
                enableColumnActions: false,
                muiTableHeadCellProps: {
                  align: "left",
                  sx: {
                    paddingRight: 2,
                    verticalAlign: "bottom",
                  },
                },
                muiTableBodyCellProps: {
                  align: "left",
                  sx: {
                    paddingRight: 2,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  },
                },
              },
            ]
          : columns && filterTable
          ? [
              {
                accessorKey: "id",
                accessorFn: (row) => row.id,
                header: t("caymland.form.report.submission.id"),
                size: 80,
                enableColumnActions: enableColumnActions,
                enableSorting: enableSorting,
                muiTableHeadCellProps: {
                  align: "left",
                },
                muiTableBodyCellProps: {
                  align: "left",
                },
              },
              ...columns,
            ]
          : columns
          ? [
              ...columns,
              {
                accessorKey: "id",
                accessorFn: (row) => row.id,
                header: t("caymland.points.table.id"),
                size: 80,
                enableColumnActions: enableColumnActions,
                enableSorting: enableSorting,
                muiTableHeadCellProps: {
                  align: "right",
                },
                muiTableBodyCellProps: {
                  align: "right",
                },
              },
            ]
          : columnsClicks
      }
      enablePagination={pagination}
      manualPagination
      manualSorting
      onPaginationChange={setQuery}
      rowCount={totalDBRowCount}
      onSortingChange={setSorting}
      positionToolbarAlertBanner="bottom"
      muiSearchTextFieldProps={{
        sx: { minWidth: "300px", height: "35px", marginLeft: searchHelper ? "35px" : 0 },
        variant: "outlined",
        value: searchValue, // Set the value of the input to searchValue
        onChange: handleSearchChange,
        InputProps: {
          endAdornment: (
            <IconButton
              disabled={searchValue.length == "0"}
              onClick={() => {
                setSearchValue(""); // Clear the input
                MRT_GlobalSearch("", setQuery); // Clear the query state
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        },
      }}
      state={{
        columnFilters,
        pagination: query,
        showProgressBars: isFetching,
        sorting: [{ id: query.orderBy, desc: query?.orderByDir === "DESC" }],
        showGlobalFilter: showGlobalFilter,
      }}
      muiLinearProgressProps={({ isTopToolbar }) => ({
        color: "secondary",
        sx: {
          display: isTopToolbar ? "block" : "none",
        },
      })}
      enableColumnFilters={enableColumnFilters}
      enableDensityToggle={false}
      enableTableFooter={linkNavigate === "advancedReport" && true}
      enableBottomToolbar={pagination}
      enableStickyFooter={linkNavigate === "advancedReport" && true}
      enableSorting={sort}
      onColumnFiltersChange={setColumnFilters}
      enableGlobalFilter={viewTable ? false : true}
      onGlobalFilterChange={(search) => MRT_GlobalSearch(search, setQuery)}
      enableFilters={false}
      positionGlobalFilter="left"
      enableColumnActions={enableColumnActions}
      enableRowDragging={rowDragging}
      enableStickyHeader={true}
      enableRowSelection={hideActionButtons ? false : viewTable && !segments ? false : actions}
      enableMultiRowSelection={viewTable && !segments ? false : disableMultiRowSelection ? false : actions}
      muiToolbarProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
      displayColumnDefOptions={{
        "mrt-row-drag": {
          size: 20,
          Header: () => <></>,
        },
        ...(actionButtons && actionButtons.length > 0
          ? {
              "mrt-row-select": {
                accessorKey: "mrt-row-action",
                Header: ({ table }) => (
                  <MRT_CustomHeaderRowsAction dontShowSelect={disableMultiRowSelection} selectAll table={table} />
                ),
                Cell: ({ cell, row, table }) => (
                  <MRT_CustomRowsAction dontShowSelect={disableMultiRowSelection} cell={cell} row={row} table={table} />
                ),
                size: 80,
                muiTableHeadCellProps: {
                  align: "left",
                },
                muiTableBodyCellProps: {
                  align: "left",
                },
                enableSorting: false,
              },
            }
          : {}),
      }}
      renderRowActionMenuItems={({ row, closeMenu }) => {
        return actionButtons?.map((actionButton, index) => {
          const isDeleteButton = actionButton.name === t("caymland.mailbox.list.action.deleteMessage");
          const isSendEmailButton = actionButton.name === t("caymland.mailbox.message.send");
          const isDoNotContact = row.original.doNotContact?.length > 0;
          let shouldRenderButton = true;

          if (actionButton.name === t("caymland.lead.batch.dnc") && isDoNotContact) {
            shouldRenderButton = false;
          } else if (actionButton.name === t("plugin.dncevent.campaign.removeDnc.label") && !isDoNotContact) {
            shouldRenderButton = false;
          }
          if (shouldRenderButton) {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  closeMenu();
                  if (
                    actionButton.name === t("caymland.lead.batch.dnc") ||
                    actionButton.name === t("plugin.dncevent.campaign.removeDnc.label")
                  ) {
                    actionButton.onClick(row.original.id, isDoNotContact);
                  } else if (isDeleteButton || isSendEmailButton) {
                    actionButton.onClick(row.original);
                  } else {
                    actionButton.onClick(row.original.id);
                  }
                }}
                sx={{ m: 0, color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}
              >
                <ListItemIcon sx={{ color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}>
                  {actionButton.icon}
                </ListItemIcon>
                {actionButton.name}
              </MenuItem>
            );
          }
          return null;
        });
      }}
      renderCustomBatchActions={({ table, closeMenu }) => {
        let selectedRows = table.getSelectedRowModel().rows.map((e) => e.original.id);
        return actionBatchButtons?.map((e, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              closeMenu();
              e.onClick(selectedRows, table);
            }}
            sx={{
              m: 0,
              color:
                e.name === t("caymland.core.form.delete_selected")
                  ? "rgba(255, 0, 0, 0.7)"
                  : e.name === "Set Do Not Contact"
                  ? "rgba(255, 0, 0, 0.7)"
                  : undefined,
            }}
          >
            <ListItemIcon
              sx={{ color: e.name === t("caymland.core.form.delete_selected") ? "rgba(255, 0, 0, 0.7)" : undefined }}
            >
              {e.icon}
            </ListItemIcon>
            {e.name}
          </MenuItem>
        ));
      }}
      renderToolbarInternalActions={({ table }) => (
        <>
          {searchHelper && !viewTable && (
            <>
              <SearchModal isOpen={isOpen} setIsOpen={setIsOpenSearchModal} body={searchHelperBody} />
              <Tooltip
                arrow
                title={"Search Helper"}
                style={{ position: "absolute", left: 0, top: link == "contacts" ? "4px" : "5px" }}
              >
                <IconButton>
                  <HelpOutlineIcon onClick={() => setIsOpenSearchModal(true)} />
                </IconButton>
              </Tooltip>
            </>
          )}

          {/* {viewTable ? <></> : searchFilter === false ? <></> : <MRT_ToggleGlobalFilterButton table={table} />} */}
          {/* <MRT_ShowHideColumnsButton table={table} /> */}
          {/* <MRT_FullScreenToggleButton table={table} /> */}
          {toolbarButtons?.map((item) => React.createElement(item.component, { table, ...item.props }))}
        </>
      )}
      getRowId={(originalRow, rowIndex) => `${originalRow.id || rowIndex}`}
      muiTablePaperProps={{
        sx: {
          boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.1) !important",
          borderRadius: "8px",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          verticalAlign: "bottom",
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        },
      }}
      muiTableContainerProps={{
        sx: {
          height: !pagination ? "auto" : viewTable ? "50px" : "calc(100vh - 195px)",
          overflowY: "auto",
          minHeight: "487px",
        },
      }}
      initialState={{
        density: "compact",
        showColumnFilters: showGlobalFilter,
      }}
      muiTableBodyRowProps={({ row }) => {
        return {
          draggable: handleReorder ? true : false,
          onDragStart: () => onDragStart(row?.original?.id),
          onDragOver: (e) => {
            onDragOver(e, row?.original?.id);
          },
          onDrop: () => onDrop(row?.original?.id),
          onClick: (e) => {
            if (linkNavigate === "advancedReport") {
              handleClick(e, row?.original?.redirect_id);
              return;
            }
            if (linkNavigate === "categories") {
              setIsOpen(true);
              modal("Edit", row?.original?.id);
            } else if (linkNavigate === "tags") {
              modal({ type: "edit", id: row.original.id, isOpen: true });
            } else if (linkNavigate === null) {
              return;
            } else {
              navigate(
                viewTable ? `/contacts/${linkNavigate}/${row?.original?.id}` : `${linkNavigate}/${row?.original?.id}`,
                {
                  state: { id: row?.original?.id },
                }
              );
            }
          },
          sx: {
            cursor: "pointer",
            backgroundColor:
              linkNavigate === "advancedReport" &&
              row?.original?.redirect_id === highlightedLink &&
              "rgb(224, 224, 224)",
          },
        };
      }}
      {...(showDetailPanel && {
        renderDetailPanel: ({ row }) => {
          const data = viewTable ? row.original : row?.original?.fields?.core;
          const sortedFields = Object.values(data)
            .filter((field) => field?.label && field?.value !== null && field?.value !== undefined)
            .sort((a, b) => parseInt(a.order) - parseInt(b.order));

          const nonEmptyFields = {};

          sortedFields.forEach((field) => {
            if (field.type === "boolean") {
              nonEmptyFields[field.label] =
                field.value === 1 ? t("caymland.core.form.yes") : t("caymland.core.form.no");
            } else if (field.type === "select") {
              const matchedField = fields && fields?.data?.find((f) => f.alias === field.alias);
              if (matchedField && matchedField.properties?.list) {
                const matchedOption = matchedField.properties.list.find((option) => option.value === field.value);
                nonEmptyFields[field.label] = matchedOption ? matchedOption.label : field.value;
              } else {
                nonEmptyFields[field.label] = field.value;
              }
            } else if (field.type === "country") {
              const matchedCountry = countries.find((country) => country.value === field.value);
              nonEmptyFields[field.label] = matchedCountry ? matchedCountry.label : field.value;
            } else if (field.type === "region") {
              const matchedRegion = regions.find((region) => region.value === field.value);
              nonEmptyFields[field.label] = matchedRegion ? matchedRegion.label : field.value;
            } else if (field.type === "locale") {
              const matchedLocale = locales.find((locale) => locale.value === field.value);
              nonEmptyFields[field.label] = matchedLocale ? matchedLocale.label : field.value;
            } else {
              nonEmptyFields[field.label] = field.value;
            }
          });

          const nonEmptyFieldsView = Object.entries(data).filter(
            ([key, value]) =>
              value !== null &&
              value !== undefined &&
              typeof value === "string" &&
              value.trim() !== "" &&
              key !== "dateModified" &&
              key !== "dateIdentified" &&
              key !== "preferredProfileImage" &&
              key !== "dateAdded" &&
              key !== "leadlist_id"
          );
          const thirdPoint = Math.ceil(Object.keys(nonEmptyFields).length / 3);
          const twoThirdPoint = 2 * thirdPoint;

          if (Object.keys(nonEmptyFields).length > 0) {
            return (
              <DetailPanel thirdPoint={thirdPoint} twoThirdPoint={twoThirdPoint} nonEmptyFields={nonEmptyFields} />
            );
          } else {
            return (
              <DetailPanelView
                thirdPoint={thirdPoint}
                twoThirdPoint={twoThirdPoint}
                nonEmptyFields={nonEmptyFieldsView}
              />
            );
          }
        },
      })}
    />
  );
};

export default CustomTable;
